import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function dailyLevelEnergy(callback, params, building) {
    console.log(building)
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/energy/dailyLevelEnergy",
    newPostObj
  );
  const json = await response.json();
  callback(json, building);
}

export async function dailySegmentEnergy(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/energy/dailySegmentEnergy",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function dailyEnergy(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/energy/dailyEnergy",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function todayConsumptionInfo(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/energy/todayConsumptionInfo",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyLastHour(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/energy/energyLastHour",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyLastLastHour(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/energy/energyLastLastHour",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyDailyKwh(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyDailyKwh",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyCurrentKwh(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyCurrentKwh",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyDailyRth(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyDailyRth",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyCurrentRth(callback, params, retParam) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyCurrentRth",
    newPostObj
  );
  const json = await response.json();
  callback(json, retParam);
}

export async function energyMonthlyActualKWH(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyMonthlyActualKWH",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyMonthlyActualKWRH(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyMonthlyActualKWRH",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyMonthlyActualCum(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyMonthlyActualCum",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyDailyKWHTarget(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyDailyKWHTarget",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyDailyRTHTarget(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyDailyRTHTarget",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyAllCurrentKwh(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyAllCurrentKwh",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyTotalCurrentKwh(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyTotalCurrentKwh",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function energyTotalCurrentRth(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "http://dashboard.itperfections.com:1880/api/energy/energyTotalCurrentRth",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}
