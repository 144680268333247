import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function currentTempHumidity(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/currentTempHumidity",
    newPostObj
  ).catch(function(error) {
    callback({ return: 1000, msg: "Network error" });
  });
  try {
    const json = await response.json();
    callback(json);
  } catch (e) {
    callback({ return: 1000, msg: "Network error" });
  }
}

export async function dailyEnergyConsumption(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/dailyEnergyConsumption",
    newPostObj
  ).catch(function(error) {
    callback({ return: 1000, msg: "Network error" });
  });
  try {
    const json = await response.json();
    callback(json);
  } catch (e) {
    callback({ return: 1000, msg: "Network error" });
  }
}

export async function currentHeaderData(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/currentHeaderData",
    newPostObj
  ).catch(function(error) {
    callback({ return: 1000, msg: "Network error" });
  });
  try {
    const json = await response.json();
    callback(json);
  } catch (e) {
    callback({ return: 1000, msg: "Network error" });
  }
}

export async function efficiency(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/efficiency",
    newPostObj
  ).catch(function(error) {
    callback({ return: 1000, msg: "Network error" });
  });
  try {
    const json = await response.json();
    callback(json);
  } catch (e) {
    callback({ return: 1000, msg: "Network error" });
  }
}

export async function efficiencyHourly(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/efficiencyHourly",
    newPostObj
  ).catch(function(error) {
    callback({ return: 1000, msg: "Network error" });
  });
  try {
    const json = await response.json();
    callback(json);
  } catch (e) {
    callback({ return: 1000, msg: "Network error" });
  }
}

export async function coolingLoad(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/coolingLoad",
    newPostObj
  ).catch(function(error) {
    callback({ return: 1000, msg: "Network error" });
  });
  try {
    const json = await response.json();
    callback(json);
  } catch (e) {
    callback({ return: 1000, msg: "Network error" });
  }
}

export async function heatBalance(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/heatBalance",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCoolingLoad(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCoolingLoad",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCoolingLoadAll(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCoolingLoadAll",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportChillerEff(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportChillerEff",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCHWPEff(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCHWPEff",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}
export async function reportCDWPEff(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCDWPEff",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCTEff(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCTEff",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}
export async function reportChillerPlantEff(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportChillerPlantEff",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCHWTemp(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCHWTemp",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCDWTemp(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCDWTemp",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportEffVsLoad(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportEffVsLoad",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportChwpVsLoad(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportChwpVsLoad",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCdwpVsLoad(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCdwpVsLoad",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportCTVsLoad(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportCTVsLoad",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportChillerSummary(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportChillerSummary",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function reportHeatBalance(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/chiller/reportHeatBalance",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}
