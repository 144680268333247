import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { dailySegmentWater } from "../../api/water";

const days = ["S", "M", "T", "W", "T", "F", "S"];
const refreshInterval = 60000 * 5;

function WaterGraphSegment(props) {
  var echarts = require("echarts");
  const dataAxis = ["4 M", "5 T", "6 W", "7 T", "8 F", "9 S", "10 S"];
  const data = [0, 0, 0, 0, 0, 0, 0];

  function getBarColor(type) {
    if (type === "kwh") {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#92d70d" },
        { offset: 1, color: "#347b09" },
      ]);
    } else {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#e90b5f" },
        { offset: 1, color: "#830839" },
      ]);
    }
  }

  const [option, setOption] = useState({
    grid: {
      left: "1%",
      right: "10%",
      bottom: "0%",
      containLabel: true,
    },
    legend: {
      show: false,
      top: "92%",
      left: "70%",
      icon: "roundRect",
      textStyle: {
        color: "#999",
        fontSize: 9,
      },
      data: [
        {
          name: "15 DAYS REFERENCE",
        },
      ],
    },
    xAxis: {
      nameLocation: "middle",
      nameGap: 50,
      name: "",
      nameTextStyle: {
        color: "#b283ec",
        align: "center",
        position: "bottom",
        fontSize: 13,
      },
      data: dataAxis,
      axisLabel: {
        interval: 0,
        formatter: function (value) {
          var res = value.split(" ");
          if (res[1] === "S")
            return "{date|" + res[0] + "}\n" + "{weekend|" + res[1] + "}";
          else return "{date|" + res[0] + "}\n" + "{weekday|" + res[1] + "}";
        },
        rich: {
          date: {
            align: "center",
            color: "#ffffff",
          },
          weekday: {
            lineHeight: 15,
            align: "center",
            color: "#999999",
          },
          weekend: {
            lineHeight: 15,
            align: "center",
            color: "#00FF00",
          },
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      z: 10,
    },
    yAxis: {
      position: "left",
      nameLocation: "end",
      name: "VOLUME(m3)",
      nameTextStyle: {
        color: "#ffffff",
        align: "right",
        padding: [0, -60, 0, 0],
      },

      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: { lineStyle: { color: "#323436", width: 1 } },
      axisLabel: {
        textStyle: {
          color: "#ffffff",
        },
      },
    },
    series: [
      {
        type: "line",
        name: "COLD",
        lineStyle: {
          color: "#a300a3",
          width: 2,
          type: "solid",
        },
        symbol: "none",
        smooth: true,
        data: data,
      },
      {
        type: "line",
        name: "RAIN",
        lineStyle: {
          color: "#a35400",
          width: 2,
          type: "solid",
        },
        symbol: "none",
        smooth: true,
        data: data,
      },
      {
        type: "line",
        name: "FLUSH",
        lineStyle: {
          color: "#00a31b",
          width: 2,
          type: "solid",
        },
        symbol: "none",
        smooth: true,
        data: data,
      },
      {
        type: "line",
        name: "CT",
        lineStyle: {
          color: "#0047a3",
          width: 2,
          type: "solid",
        },
        symbol: "none",
        smooth: true,
        data: data,
      },
    ],
  });

  function dailyWaterArrayCB(ret) {
    console.log(ret);
    if (ret.return === 100) {
      var dataAxis_ = [];
      var coldwaterData = [0, 0, 0, 0, 0, 0, 0];
      var raintowerData = [0, 0, 0, 0, 0, 0, 0];
      var flushingData = [0, 0, 0, 0, 0, 0, 0];
      var coolingtowerData = [0, 0, 0, 0, 0, 0, 0];
      var dayTsArr = [];

      for (var i = 6; i >= 0; i--) {
        var d = new Date();
        d.setDate(d.getDate() - i);
        var curr_date = d.getDate();
        var curr_day = d.getDay();
        var curr_day = days[curr_day];
        var curr_month = d.getMonth() + 1;
        var curr_year = d.getFullYear();
        var dateString =
          curr_month +
          "/" +
          curr_date +
          "/" +
          curr_year +
          ", 00:00:00 AM UTC+7";
        var ts = Math.floor(new Date(dateString).getTime() / 1000);
        dayTsArr.push(ts);
        dataAxis_.push(curr_date + " " + curr_day);
      }

      for (var i = 0; i < ret.info.length; i++) {
        var dayData = ret.info[i];
        var index = dayTsArr.indexOf(dayData.ts);
        if (index != -1) {
          for (var k = 0; k < dayData.data.length; k++) {
            if (dayData.data[k].type === "coldwater") {
              coldwaterData[index] = dayData.data[k].total;
            } else if (dayData.data[k].type === "raintower") {
              raintowerData[index] = dayData.data[k].total;
            } else if (dayData.data[k].type === "flushing") {
              flushingData[index] = dayData.data[k].total;
            } else if (dayData.data[k].type === "coolingtower") {
              coolingtowerData[index] = dayData.data[k].total;
            }
          }
        }
      }

      const option_ = cloneDeep(option);
      option_.xAxis.data = dataAxis_;
      //option_.xAxis.name = startStr + "a - b" + endStr;
      option_.series[0].data = coldwaterData;
      option_.series[1].data = raintowerData;
      option_.series[2].data = flushingData;
      option_.series[3].data = coolingtowerData;
 
      setOption(option_);
    }
  }

  var d = new Date();
  d.setDate(d.getDate());
  var curr_date = d.getDate();
  var curr_month = d.getMonth() + 1;
  var curr_year = d.getFullYear();
  var dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var timeStamp = Math.floor(new Date(dateString).getTime() / 1000);

  d.setDate(d.getDate() - 7);
  curr_date = d.getDate();
  curr_month = d.getMonth() + 1;
  curr_year = d.getFullYear();
  dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var last7DaysTs = Math.floor(new Date(dateString).getTime() / 1000);

  useEffect(() => {
    dailySegmentWater(dailyWaterArrayCB, {
      ts: { $gte: last7DaysTs },
    });
  }, []);

  useInterval(() => {
    dailySegmentWater(dailyWaterArrayCB, {
      ts: { $gte: last7DaysTs },
    });
  }, refreshInterval);

  return (
    <ReactEcharts
      option={option}
      style={{
        top: "100",
        width: 550,
        height: 400,
      }}
    />
  );
}

export default WaterGraphSegment;
