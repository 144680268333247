import React, { useState, useEffect } from "react";
import MarqueeText from "react-marquee-text-component";
import { useInterval } from "../../Utils";

function CurrentAlertTypeItems(props) {
  const [items, setItems] = useState([]);

  useEffect(
    () => {
      let items_ = [];
      for (var i = 0; i < 3; i++) {
        if (props.data[i]) {
          let time = new Date(props.data[i].startAlarmTimeStamp * 1000);
          var timeStr =
            String(time.getHours()).padStart(2, "0") +
            ":" +
            String(time.getMinutes()).padStart(2, "0");
          items_.push({ name: props.data[i].name, time: timeStr });
        } else {
          items_.push({ name: " ", time: " " });
        }
      }
      setItems(items_);
    },
    [props]
  );

  function getDisplay(item_, index) {
    return (
      <div
        key={index + props.index + props.buildingIndex + item_.name}
        className="row no-gutters error-row d-flex justify-content-center"
      >
        <div className="col-2 icon-gp">
          {item_.name !== " " ? (
            <img src="/images/icon-alert-s.png" className="icon" />
          ) : (
            <div />
          )}
        </div>
        <div className="col-auto">
          <div className="error-item">
            <div style={{ width: 100, fontSize: 16 }}>
              <MarqueeText
                text={item_.name + "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
              />
            </div>
          </div>
          <div className="time" style={{ fontSize: 14 }}>
            {item_.time}
          </div>
        </div>
      </div>
    );
  }

  if (items.length) {
    return (
      <div className="col-12">
        {items.map((item, index) => getDisplay(item, index))}
      </div>
    );
  } else {
    return <div className="col-12 no-error">-</div>;
  }
}

export default CurrentAlertTypeItems;
