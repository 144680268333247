import React, { useState, useEffect } from "react";
import { useInterval } from "../../Utils";
import {
  operationGetEquipmentStatus,
  operationGetEquipmentFault
} from "../../api/operation";

function EquipmentStatus(props) {
  const [fault, setFault] = useState(0);
  const [on, setOn] = useState(0);
  const [off, setOff] = useState(0);

  function operationGetEquipmentStatusCB(ret) {
    if (ret.return === 100) {
      setOn(ret.info.on);
      setOff(ret.info.off);
    }
  }

  function operationGetEquipmentFaultCB(ret) {
      console.log(ret)
    if (ret.return === 100) {
      setFault(ret.info.faults);
    }
  }

  useEffect(() => {
    operationGetEquipmentStatus(operationGetEquipmentStatusCB, {
      building: props.buildingName
    });
    operationGetEquipmentFault(operationGetEquipmentFaultCB, {
      building: props.buildingName
    });
  }, []);

  return (
    <div className="col-4">
      <div className="row">
        <div className="col-12">
          <div className="widget-header row no-gutters">
            <div className={"widget-title col-12 building-" + props.index}>
              {props.buildingName}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="gp">
            <div className="side-row-l row no-gutters">
              <div className="col-6 value-label-xl">
                Equipment <br />On
              </div>
              <div className="col-4 value-xxl">{on}</div>
              <div className="col-2">
                <img src="images/icon-on.png" className="icon" />
              </div>
            </div>
            <div className="side-row-l row no-gutters">
              <div className="col-6 value-label-xl">
                Equipment <br />Off
              </div>
              <div className="col-4 value-xxl">{off}</div>
              <div className="col-2">
                <img src="images/icon-off.png" className="icon" />
              </div>
            </div>
            <div className="side-row-l row no-gutters">
              <div className="col-6 value-label-xl">
                Equipment <br />Fault
              </div>
              <div className="col-4 value-xxl">{fault}</div>
              <div className="col-2">
                <img src="images/icon-faulty.png" className="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipmentStatus;
