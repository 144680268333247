import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";

function Eff(props) {
  const radialOptions = {
    chart: {
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: "50%"
        },
        track: {
          background: "#222",
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 20,
            show: false,
            color: "#ffffff",
            fontSize: "10px"
          },
          value: {
            formatter: function(val) {
              return ((val / 100) * 60).toLocaleString();
            },
            offsetY: -10,
            color: "#FFF",
            fontSize: "18px",
            show: true
          }
        }
      }
    },

    fill: {
      colors: ["#ffffff"],
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100]
      }
    }
  };

  const [option, setOption] = useState(null);
  const [val, setVal] = useState(0);

  useEffect(() => {
    var val_ = (props.value * 100) / props.target;
    val_ = val_.toFixed(2);
    setVal(val_);

    let option_ = cloneDeep(radialOptions);
    option_.fill.colors = [props.color];
    option_.plotOptions.radialBar.dataLabels.value.formatter = function(d) {
      return ((d * props.target) / 100).toFixed(2);
    };
    setOption(option_);
    /*var data = [];
    data.push({
      value: 20,
      name: "Test"
    });

    let option_ = cloneDeep(radialOptions);
    option_.series[0].data = data;
    setOption(option_);*/
    /*let types = [];
      var data = [];
      let valid = false;

      for (var i = 0; i < props.types.length; i++) {
        if (props.types[i].count > 0) {
          valid = true;
          break;
        }
      }

      if (valid) {
        for (var i = 0; i < props.types.length; i++) {
          var color;
          if (props.types[i].name === "HX") {
            color = "#d60956";
          } else if (props.types[i].name === "PUMPS") {
            color = "#f99c0b";
          } else if (props.types[i].name === "FCU") {
            color = "#6dd518";
          } else if (props.types[i].name === "AHU") {
            color = "#168603";
          } else if (props.types[i].name === "VAV") {
            color = "#0fc3ac";
          } else if (props.types[i].name === "P & S") {
            color = "#0aaecb";
          }
          //console.log(props.types[i].name);
          types.push(props.types[i].name);
          data.push({
            value: props.types[i].count,
            name: props.types[i].name,
            itemStyle: { color: color, shadowColor: color, shadowBlur: 20 }
          });
        }
      }

      let option_ = cloneDeep(option);
      option_.series[0].data = data;
      setOption(option_);*/
  }, [props]);

  if (option !== null) {
    return (
      <Chart options={option} series={[val]} type="radialBar" width="280" />
    );
  } else {
    return <div />;
  }
}

export default Eff;
