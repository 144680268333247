import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { reportChillerSummary } from "../../api/chiller";

function ChillerReport(props) {
  const [val, setVal] = useState(0);
  const [data, setData] = useState(null);

  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  function chillerSummaryCB(ret) {
    if (ret.return === 100) {
      console.log(ret.info);
      setData(ret.info);
    }
  }

  useEffect(() => {
    reportChillerSummary(chillerSummaryCB, {
      start: props.start,
      end: props.end
    });
  }, []);

  if (data !== null) {
    return (
      <div>
        <div className="row" style={{}}>
          <div
            className="col-12"
            style={{
              paddingTop: 50,
              color: "black"
            }}
          >
            <h2 style={{ color: "black" }}>
              Summary of Chilled Water Plant Operating Performance
            </h2>
          </div>
        </div>
        <table
          className="table table-bordered"
          style={{
            color: "black",
            fontSize: 20,
            width: "100%"
          }}
        >
          <thead>
            <tr>
              <th rowSpan="2">Daily Average Reading</th>
              <th rowSpan="1" colSpan="2">
                Period
              </th>
              <th rowSpan="2">Unit</th>
            </tr>
            <tr>
              <th>Day</th>
              <th>Night</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Cooling Load</th>
              <td>{data.coolingLoad.day.toFixed(2)}</td>
              <td>{data.coolingLoad.night.toFixed(2)}</td>
              <td>RT</td>
            </tr>
            <tr>
              <th>Chilled water supply temperature</th>
              <td>{(((data.chws.day - 32) * 5) / 9).toFixed(2)}</td>
              <td>{(((data.chws.night - 32) * 5) / 9).toFixed(2)}</td>
              <td>°C</td>
            </tr>
            <tr>
              <th>Chilled water return temperature</th>
              <td>{(((data.chwr.day - 32) * 5) / 9).toFixed(2)}</td>
              <td>{(((data.chwr.night - 32) * 5) / 9).toFixed(2)}</td>
              <td>°C</td>
            </tr>
            <tr>
              <th>Chilled water delta T</th>
              <td>
                {(
                  ((data.chwr.day - 32) * 5) / 9 -
                  ((data.chws.day - 32) * 5) / 9
                ).toFixed(2)}
              </td>
              <td>
                {(
                  ((data.chwr.night - 32) * 5) / 9 -
                  ((data.chws.night - 32) * 5) / 9
                ).toFixed(2)}
              </td>
              <td>°C</td>
            </tr>
            <tr>
              <th>Chilled water flow rate</th>
              <td>{/*data.chwFlowRate.day.toFixed(2)*/}</td>
              <td>{/*data.chwFlowRate.night.toFixed(2)*/}</td>
              <td>l/s</td>
            </tr>

            <tr>
              <th>Condensor water return temperature</th>
              <td>{data.cdws.day.toFixed(2)}</td>
              <td>{data.cdws.night.toFixed(2)}</td>
              <td>°C</td>
            </tr>
            <tr>
              <th>Condensor water supply temperature</th>
              <td>{data.cdwr.day.toFixed(2)}</td>
              <td>{data.cdwr.night.toFixed(2)}</td>
              <td>°C</td>
            </tr>
            <tr>
              <th>Condensor water delta T</th>
              <td>{data.cdwT.day.toFixed(2)}</td>
              <td>{data.cdwT.night.toFixed(2)}</td>
              <td>°C</td>
            </tr>
            <tr>
              <th>Condensor water flow rate</th>
              <td>{/*data.cdwFlowRate.day.toFixed(2)*/}</td>
              <td>{/*data.cdwFlowRate.night.toFixed(2)*/}</td>
              <td>l/s</td>
            </tr>

            <tr>
              <th>Chiller(s) efficiency</th>
              <td>{data.chillerEff.day.toFixed(2)}</td>
              <td>{data.chillerEff.night.toFixed(2)}</td>
              <td>kW/RT</td>
            </tr>
            <tr>
              <th>Chilled water pump(s) efficiency</th>
              <td>{data.chwEff.day.toFixed(2)}</td>
              <td>{data.chwEff.night.toFixed(2)}</td>
              <td>kW/RT</td>
            </tr>
            <tr>
              <th>Condensor water pump(s) efficiency</th>
              <td>{data.cdwEff.day.toFixed(2)}</td>
              <td>{data.cdwEff.night.toFixed(2)}</td>
              <td>kW/RT</td>
            </tr>
            <tr>
              <th>Cooling tower(s) efficiency</th>
              <td>{data.ctEff.day.toFixed(2)}</td>
              <td>{data.ctEff.night.toFixed(2)}</td>
              <td>kW/RT</td>
            </tr>
            <tr>
              <th>Overall chiller plant efficiency</th>
              <td>{data.plantEff.day.toFixed(2)}</td>
              <td>{data.plantEff.night.toFixed(2)}</td>
              <td>kW/RT</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>aaaaaaaaaa</div>;
  }
}

export default ChillerReport;
