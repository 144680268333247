import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";

function SlaFlipGraph(props) {
    const imagePath = "image://../../images/hx-header-temp-slim.png";

    function getXAxis() {
        var time = [];
        for (var i = 0; i < 8; i++) {
            var d = new Date();
            d = new Date(d.getTime() - i * 1000 * 60 * 60);
            var currentHour = d.getHours();
            if (currentHour >= 12) {
                currentHour = currentHour - 12;
                currentHour = currentHour + "pm";
            } else if (currentHour === 0) {
                currentHour = "12am";
            } else {
                currentHour = currentHour + "am";
            }
            time.push(currentHour);
        }

        time.reverse();
        return time;
    }

    function getSeriesData() {
        var seriesData = [];

        for (var i = 0; i < props.labels.length; i++) {
            var seriesData_ = {
                type: "pictorialBar",
                name: "pictorial element",
                symbol: imagePath,
                z: 10
            };

            if (i !== 0) {
                if (props.labels.length === 2) {
                    seriesData_.barGap = "-65%";
                } else {
                    seriesData_.barGap = "-45%";
                }
            }

            var data = [];
            for (var j = 0; j < 8; j++) {
                /*var topVal = Math.floor(Math.random() * (16 - 10 + 1)) + 10;
        var range = Math.floor(Math.random() * (8 - 3 + 1)) + 8;
        var bottomVal = topVal - range;
        if (bottomVal < 6) bottomVal = 6;
        range = topVal - bottomVal;*/
                var topVal = 0;
                if (props.value && props.value[i] && props.value[i][j])
                    topVal = props.value[i][j][0];
                var bottomVal = 0;
                if (props.value && props.value[i] && props.value[i][j])
                    bottomVal = props.value[i][j][1];
                var range = topVal - bottomVal;
                var rangePercentage = (range * 100) / topVal;
                rangePercentage = rangePercentage + "%";

                var data_ = {
                    name: props.labels[i],
                    value: topVal,
                    symbolPosition: "end",
                    symbolSize: ["50%", rangePercentage],
                    label: {
                        show: true,
                        rotate: -90,
                        formatter: "{b}",
                        position: ["65%", "1%"],
                        color: "#181818",
                        fontSize: 10,
                        z: 101
                    }
                };

                if (props.labels.length === 2) {
                    data_.symbolSize[0] = "30%";
                    data_.label.position[0] = "57%";
                }

                data.push(data_);
            }
            seriesData_.data = data;

            seriesData.push(seriesData_);
        }
        //console.log(props)
        //console.log(seriesData)
        return seriesData;
    }

    const [option, setOption] = useState({
        color: ["#bb0004", "#FFD48A"],
        legend: {
            data: ["pictorial element", "reference bar"]
        },
        xAxis: {
            data: getXAxis(),
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                color: "#9d9e9f",
                padding: [3, 0, 0, 0]
            }
        },
        yAxis: {
            max: 20,
            splitLine: {
                show: true,
                interval: 0,
                lineStyle: { color: "#323436" }
            },
            axisLine: { show: false },
            axisLabel: {
                show: true,
                interval: 0,
                color: "#969799"
            },
            min: 0,
            splitNumber: 10
        },
        grid: {
            top: 10,
            left: 22,
            bottom: 25,
            right: 22
        },
        legend: {
            show: false
        },
        series: getSeriesData()
    });

    function getChart() {
        const option_ = cloneDeep(option);
        option_.series = getSeriesData();
        return option_;
    }

    return (
        <div className="row">
            <div className="col-12">
                <div>
                    <ReactEcharts
                        option={getChart()}
                        style={{
                            height: "212px"
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default SlaFlipGraph;
