import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function topFault(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/fault/topFault", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function currentFault(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/fault/currentFault", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function statisticFault(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/fault/statisticFault", newPostObj);
  const json = await response.json();
  callback(json);
}


export async function faultDaily(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/fault/faultDaily", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function faultGetBuildingDailyTotalFaults(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/fault/faultGetBuildingDailyTotalFaults", newPostObj);
  const json = await response.json();
  callback(json);
}
