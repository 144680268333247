import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { groupBy, useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import SlaGraph from "./slaGraph";
import SlaFlipGraph from "./slaFlipGraph";
import {
    slaORQHeaderDailyAverage,
    slaORQHeaderInterval,
    slaBFCHeaderDailyAverage,
    slaBFCHeaderInterval,
    slaCBDHeaderDailyAverage,
    slaCBDHeaderInterval,
    slaORQco2,
    slaBFCco2,
    slaCBDco2
} from "../../api/sla";

function Sla(props) {
    const durationMs = 15000;
    const { getAuthTokens } = useAuth();
    const [orqInterval, setOrqInterval] = useState([
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ]
    ]);
    const [bfcInterval, setBfcInterval] = useState([
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ]
    ]);
    const [cbdInterval, setCbdInterval] = useState([
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ],
        [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0]
        ]
    ]);
    const slaLabels = [
        {
            buildingName: "ORQ",
            labels: ["NT B1", "NT L26", "ST B1", "P LB1"],
            data: orqInterval
        },
        {
            buildingName: "BFC",
            labels: ["UPN", "T1 B2", "T2 B2"],
            data: bfcInterval
        },
        { buildingName: "CBD", labels: ["T3 B1", "T3 L39"], data: cbdInterval }
    ];
    const [graphType, setGraphType] = useState(true);
    const [orqAvg, setOrqAvg] = useState(["0.0%", "0.0%", "0.0%", "0.0%"]);
    const [orqMiniGraphSupply, setOrqMiniGraphSupply] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ]);
    const [orqMiniGraphReturn, setOrqMiniGraphReturn] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ]);

    const [bfcAvg, setBfcAvg] = useState(["0.0%", "0.0%", "0.0%", "0.0%"]);
    const [bfcMiniGraphSupply, setBfcMiniGraphSupply] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ]);
    const [bfcMiniGraphReturn, setBfcMiniGraphReturn] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ]);

    const [cbdAvg, setCbdAvg] = useState(["0.0%", "0.0%", "0.0%", "0.0%"]);
    const [cbdMiniGraphSupply, setCbdMiniGraphSupply] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ]);
    const [cbdMiniGraphReturn, setCbdMiniGraphReturn] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ]);

    const [orqCo2, setOrqCo2] = useState([0, 0, 0, 0]);
    const [bfcCo2, setBfcCo2] = useState([0, 0, 0, 0]);
    const [cbdCo2, setCbdCo2] = useState([0, 0, 0, 0]);

    const [orqSupply, setOrqSupply] = useState([]);
    const [orqReturn, setOrqReturn] = useState([]);
    const [bfcSupply, setBfcSupply] = useState([]);
    const [bfcReturn, setBfcReturn] = useState([]);
    const [cbdSupply, setCbdSupply] = useState([]);
    const [cbdReturn, setCbdReturn] = useState([]);

    function getGraph(type) {
        if (type === false) {
            return (
                <div className="pane-holder">
                    <div className="widget row no-gutters ">
                        <div className="col-12">
                            <div className="widget-header row no-gutters">
                                <div className="widget-subtitle col-12">
                                    HX Header Temp
                                </div>
                            </div>
                        </div>

                        <div className="col-12 itm-gp">
                            <div className="widget-header row no-gutters">
                                <div className="widget-title col-12 building-orq">
                                    ORQ
                                </div>
                            </div>
                            <div className="row chart-gp no-gutters">
                                <div className="col-3">
                                    <SlaGraph
                                        buildingIndex={props.buildings[0].index}
                                        buildingName={
                                            props.buildings[0].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[0].indexCounter
                                            ].labels[0]
                                        }
                                        value={orqAvg[0]}
                                        percentageSupply={orqSupply[0]}
                                        percentageReturn={orqReturn[0]}
                                        miniGraphSupplyValue={
                                            orqMiniGraphSupply[0]
                                        }
                                        miniGraphReturnValue={
                                            orqMiniGraphReturn[0]
                                        }
                                    />
                                </div>
                                <div className="col-3 line">
                                    <SlaGraph
                                        buildingIndex={props.buildings[0].index}
                                        buildingName={
                                            props.buildings[0].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[0].indexCounter
                                            ].labels[1]
                                        }
                                        value={orqAvg[1]}
                                        percentageSupply={orqSupply[1]}
                                        percentageReturn={orqReturn[1]}
                                        miniGraphSupplyValue={
                                            orqMiniGraphSupply[1]
                                        }
                                        miniGraphReturnValue={
                                            orqMiniGraphReturn[1]
                                        }
                                    />
                                </div>
                                <div className="col-3 line">
                                    <SlaGraph
                                        buildingIndex={props.buildings[0].index}
                                        buildingName={
                                            props.buildings[0].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[0].indexCounter
                                            ].labels[2]
                                        }
                                        value={orqAvg[2]}
                                        percentageSupply={orqSupply[2]}
                                        percentageReturn={orqReturn[2]}
                                        miniGraphSupplyValue={
                                            orqMiniGraphSupply[2]
                                        }
                                        miniGraphReturnValue={
                                            orqMiniGraphReturn[2]
                                        }
                                    />
                                </div>
                                <div className="col-3 line">
                                    <SlaGraph
                                        buildingIndex={props.buildings[0].index}
                                        buildingName={
                                            props.buildings[0].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[0].indexCounter
                                            ].labels[3]
                                        }
                                        value={orqAvg[3]}
                                        percentageSupply={orqSupply[3]}
                                        percentageReturn={orqReturn[3]}
                                        miniGraphSupplyValue={
                                            orqMiniGraphSupply[3]
                                        }
                                        miniGraphReturnValue={
                                            orqMiniGraphReturn[3]
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 itm-gp">
                            <div className="widget-header row no-gutters">
                                <div className="widget-title col-12 building-bfc">
                                    BFC
                                </div>
                            </div>
                            <div className="row chart-gp no-gutters">
                                <div className="col-3">
                                    <SlaGraph
                                        buildingIndex={props.buildings[1].index}
                                        buildingName={
                                            props.buildings[1].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[1].indexCounter
                                            ].labels[0]
                                        }
                                        value={bfcAvg[0]}
                                        percentageSupply={bfcSupply[0]}
                                        percentageReturn={bfcReturn[0]}
                                        miniGraphSupplyValue={
                                            bfcMiniGraphSupply[0]
                                        }
                                        miniGraphReturnValue={
                                            bfcMiniGraphReturn[0]
                                        }
                                    />
                                </div>
                                <div className="col-3 line">
                                    <SlaGraph
                                        buildingIndex={props.buildings[1].index}
                                        buildingName={
                                            props.buildings[1].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[1].indexCounter
                                            ].labels[1]
                                        }
                                        value={bfcAvg[1]}
                                        percentageSupply={bfcSupply[1]}
                                        percentageReturn={bfcReturn[1]}
                                        miniGraphSupplyValue={
                                            bfcMiniGraphSupply[1]
                                        }
                                        miniGraphReturnValue={
                                            bfcMiniGraphReturn[1]
                                        }
                                    />
                                </div>
                                <div className="col-3 line">
                                    <SlaGraph
                                        buildingIndex={props.buildings[1].index}
                                        buildingName={
                                            props.buildings[1].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[1].indexCounter
                                            ].labels[2]
                                        }
                                        value={bfcAvg[2]}
                                        percentageSupply={bfcSupply[2]}
                                        percentageReturn={bfcReturn[2]}
                                        miniGraphSupplyValue={
                                            bfcMiniGraphSupply[2]
                                        }
                                        miniGraphReturnValue={
                                            bfcMiniGraphReturn[2]
                                        }
                                    />
                                </div>
                                <div className="col-3 line">
                                    <SlaGraph
                                        buildingIndex={props.buildings[1].index}
                                        buildingName={
                                            props.buildings[1].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[1].indexCounter
                                            ].labels[3]
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 itm-gp">
                            <div className="widget-header row no-gutters">
                                <div className="widget-title col-12 building-cbd">
                                    CBD
                                </div>
                            </div>
                            <div className="row chart-gp no-gutters">
                                <div className="col-2" />
                                <div className="col-3">
                                    <SlaGraph
                                        buildingIndex={props.buildings[2].index}
                                        buildingName={
                                            props.buildings[2].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[2].indexCounter
                                            ].labels[0]
                                        }
                                        value={cbdAvg[0]}
                                        percentageSupply={cbdSupply[0]}
                                        percentageReturn={cbdReturn[0]}
                                        miniGraphSupplyValue={
                                            cbdMiniGraphSupply[0]
                                        }
                                        miniGraphReturnValue={
                                            cbdMiniGraphReturn[0]
                                        }
                                    />
                                </div>
                                <div className="col-1" />
                                <div className="col-1 line" />
                                <div className="col-3">
                                    <SlaGraph
                                        buildingIndex={props.buildings[2].index}
                                        buildingName={
                                            props.buildings[2].buildingName
                                        }
                                        label={
                                            slaLabels[
                                                props.buildings[2].indexCounter
                                            ].labels[1]
                                        }
                                        value={cbdAvg[1]}
                                        percentageSupply={cbdSupply[1]}
                                        percentageReturn={cbdReturn[1]}
                                        miniGraphSupplyValue={
                                            cbdMiniGraphSupply[1]
                                        }
                                        miniGraphReturnValue={
                                            cbdMiniGraphReturn[1]
                                        }
                                    />
                                </div>
                                <div className="col-2" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="pane-holder">
                    <div className="widget row no-gutters ">
                        <div className="col-12">
                            <div className="widget-header row no-gutters">
                                <div className="widget-subtitle col-12">
                                    HX Header Temp
                                </div>
                            </div>
                        </div>
                        {props.buildings.map((building, index) => (
                            <div key={index} className="col-12 itm-gp">
                                <div className="widget-header row no-gutters">
                                    <div
                                        className={
                                            "widget-title col-12 building-" +
                                            building.index
                                        }
                                    >
                                        {building.buildingName}
                                    </div>
                                </div>
                                <div className="row chart-gp no-gutters">
                                    <div className="col-12">
                                        <SlaFlipGraph
                                            buildingIndex={building.index}
                                            labels={
                                                slaLabels[building.indexCounter]
                                                    .labels
                                            }
                                            value={
                                                slaLabels[building.indexCounter]
                                                    .data
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    }

    const mainDiv = (
        <div className="sla has-subtitle ">
            <div className="main-container">
                <div className="subtitle-gp">
                    <span className="line" />
                    <span>
                        <div>
                            <img src="images/subtitle-sla-icon.png" />
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className="subtitle">SLA</div>
                        </div>
                    </span>
                    <span className="line" />
                </div>
                <div className="pane1">{getGraph(graphType)}</div>

                <div className="widget-gap-w" />

                <div className="pane2">
                    <div className="pane-holder">
                        <div className="widget row no-gutters ">
                            <div className="col-12">
                                <div className="widget-header row no-gutters">
                                    <div className="widget-subtitle col-12">
                                        CO<sub>2</sub> MEASUREMENTS
                                    </div>
                                </div>
                            </div>
                            {props.buildings.map((building, index) => (
                                <div key={index} className="col-12 itm-gp">
                                    <div className="widget-header row no-gutters">
                                        <div
                                            className={
                                                "widget-title col-12 building-" +
                                                building.index
                                            }
                                        >
                                            {building.buildingName}
                                        </div>
                                    </div>
                                    <div className="row no-gutters value">
                                        <div className="ui-col glow-grey">
                                            {getCo2(building.buildingName, 0)}
                                        </div>
                                        <div className="ui-col glow-green">
                                            {getCo2(building.buildingName, 1)}
                                        </div>
                                        <div className="ui-col glow-orange">
                                            {getCo2(building.buildingName, 2)}
                                        </div>
                                        <div className="ui-col glow-red">
                                            {getCo2(building.buildingName, 3)}
                                        </div>
                                    </div>
                                    <div className="row no-gutters sla-range-bar">
                                        <div className="col-12 text-center">
                                            <img src="images/sla-range-bar.png" />
                                        </div>
                                    </div>
                                    <div className="row no-gutters value-indicator">
                                        <div className="ui-col"></div>
                                        <div className="ui-col">
                                            <span className="value-s">0</span>
                                        </div>
                                        <div className="ui-col">
                                            <span className="value-s">
                                                1000
                                            </span>
                                            <span className="value-unit-m">
                                                ppm
                                            </span>
                                        </div>
                                        <div className="ui-col">
                                            <span className="value-s">
                                                1200
                                            </span>
                                            <span className="value-unit-m">
                                                ppm
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function getCo2(bName, level) {
        if (bName === "ORQ") {
            return orqCo2[level];
        } else if (bName === "BFC") {
            return bfcCo2[level];
        } else if (bName === "CBD") {
            return cbdCo2[level];
        } else {
            return 0;
        }
    }

    function onSlaORQHeaderDailyAverage(ret) {
        if (ret.return === 100) {
            /*console.log(
        "==============onSlaORQHeaderDailyAverage======================="
      );
      console.log(ret);*/
            var NT_L26_RETURN = 0;
            var NT_L26_SUPPLY = 0;
            var NT_B1_RETURN = 0;
            var NT_B1_SUPPLY = 0;
            var ST_B1_RETURN = 0;
            var ST_B1_SUPPLY = 0;
            var NT_PODIUM_RETURN = 0;
            var NT_PODIUM_SUPPLY = 0;
            for (var i = 0; i < ret.info.length; i++) {
                if (
                    ret.info[i] &&
                    "data" in ret.info[i] &&
                    "value" in ret.info[i].data
                ) {
                    if (ret.info[i].name === "NT L26 RETURN") {
                        NT_L26_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "NT L26 SUPPLY") {
                        NT_L26_SUPPLY = ret.info[i].data.value;
                    } else if (ret.info[i].name === "NT B1 RETURN") {
                        NT_B1_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "NT B1 SUPPLY") {
                        NT_B1_SUPPLY = ret.info[i].data.value;
                    } else if (ret.info[i].name === "ST B1 RETURN") {
                        ST_B1_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "ST B1 SUPPLY") {
                        ST_B1_SUPPLY = ret.info[i].data.value;
                    } else if (ret.info[i].name === "NT PODIUM RETURN") {
                        NT_PODIUM_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "NT PODIUM SUPPLY") {
                        NT_PODIUM_SUPPLY = ret.info[i].data.value;
                    }
                }
            }
            //console.log("NT_L26_RETURN :" + NT_L26_RETURN);
            //console.log("NT_L26_SUPPLY :" + NT_L26_SUPPLY);
            //console.log("NT_B1_RETURN :" + NT_B1_RETURN);
            //console.log("NT_B1_SUPPLY :" + NT_B1_SUPPLY);
            //console.log("ST_B1_RETURN :" + ST_B1_RETURN);
            //console.log("ST_B1_SUPPLY :" + ST_B1_SUPPLY);
            //console.log("NT_PODIUM_RETURN :" + NT_PODIUM_RETURN);
            //console.log("NT_PODIUM_SUPPLY :" + NT_PODIUM_SUPPLY);

            /*var v1 = 0,
        v2 = 0,
        v3 = 0,
        v4 = 0;
      if (NT_L26_RETURN <= 20 && NT_L26_SUPPLY >= 0) {
        v1 = ((NT_L26_RETURN - NT_L26_SUPPLY) * 100) / 20.0;
        v1 = v1 + "%";
      }
      if (NT_B1_RETURN <= 20 && NT_B1_SUPPLY >= 0) {
        v2 = ((NT_B1_RETURN - NT_B1_SUPPLY) * 100) / 20.0;
        v2 = v2 + "%";
      }
      if (ST_B1_RETURN <= 20 && ST_B1_SUPPLY >= 0) {
        v3 = ((ST_B1_RETURN - ST_B1_SUPPLY) * 100) / 20.0;
        v3 = v3 + "%";
      }
      if (NT_PODIUM_RETURN <= 20 && NT_PODIUM_SUPPLY >= 0) {
        v4 = ((NT_PODIUM_RETURN - NT_PODIUM_SUPPLY) * 100) / 20.0;
        v4 = v4 + "%";
      }
      console.log(v1);console.log(v2);console.log(v3);console.log(v4);*/
            setOrqAvg([
                [NT_L26_SUPPLY, NT_L26_RETURN],
                [NT_B1_SUPPLY, NT_B1_RETURN],
                [ST_B1_SUPPLY, ST_B1_RETURN],
                [NT_PODIUM_SUPPLY, NT_PODIUM_RETURN]
            ]);
            //setOrqAvg(["62.5%", "52.5%", "42.5%", "32.5%"]);
        }
    }

    function onSlaORQHeaderInterval(ret) {
        if (ret.return === 100) {
            /*console.log(
                "==============onSlaORQHeaderInterval======================="
            );
            console.log(ret);*/

            var s_ntb1 = [];
            var s_ntl26 = [];
            var s_stb1 = [];
            var s_plb1 = [];

            var r_ntb1 = [];
            var r_ntl26 = [];
            var r_stb1 = [];
            var r_plb1 = [];

            var largest = 0;
            var smallest = -1;
            var diff = 0;

            //NT L26 SUPPLY
            if (ret.info[1].data.length > 2) {
                for (var i = 0; i < ret.info[1].data.length; i++) {
                    s_ntl26[i] = ret.info[1].data[i].value;
                }
                orqSupply[1] =
                    ((ret.info[1].data[ret.info[1].data.length - 1].value -
                        ret.info[1].data[ret.info[1].data.length - 2].value) *
                        100) /
                    ret.info[1].data[ret.info[1].data.length - 2].value;
                orqSupply[1] = orqSupply[1].toFixed(1);
                if (orqSupply[1] > 0) orqSupply[1] = "+" + orqSupply[1];
            }

            //NT B1 SUPPLY
            if (ret.info[3].data.length > 2) {
                for (var i = 0; i < ret.info[3].data.length; i++) {
                    s_ntb1[i] = ret.info[3].data[i].value;
                }
                orqSupply[0] =
                    ((ret.info[3].data[ret.info[3].data.length - 1].value -
                        ret.info[3].data[ret.info[3].data.length - 2].value) *
                        100) /
                    ret.info[3].data[ret.info[3].data.length - 2].value;
                orqSupply[0] = orqSupply[0].toFixed(1);
                if (orqSupply[0] > 0) orqSupply[0] = "+" + orqSupply[0];
            }

            //ST B1 SUPPLY
            if (ret.info[5].data.length > 2) {
                for (var i = 0; i < ret.info[5].data.length; i++) {
                    s_stb1[i] = ret.info[5].data[i].value;
                }
                if (
                    ret.info[5].data[ret.info[5].data.length - 1] &&
                    ret.info[5].data[ret.info[5].data.length - 2]
                ) {
                    orqSupply[2] =
                        ((ret.info[5].data[ret.info[5].data.length - 1].value -
                            ret.info[5].data[ret.info[5].data.length - 2]
                                .value) *
                            100) /
                        ret.info[5].data[ret.info[5].data.length - 2].value;
                    orqSupply[2] = orqSupply[2].toFixed(1);
                } else orqSupply[2] = 0;
                if (orqSupply[2] > 0) orqSupply[2] = "+" + orqSupply[2];
            }

            //NT PODIUM SUPPLY
            if (ret.info[7].data.length > 2) {
                for (var i = 0; i < ret.info[7].data.length; i++) {
                    s_plb1[i] = ret.info[7].data[i].value;
                }
                orqSupply[3] =
                    ((ret.info[7].data[ret.info[7].data.length - 1].value -
                        ret.info[7].data[ret.info[7].data.length - 2].value) *
                        100) /
                    ret.info[7].data[ret.info[7].data.length - 2].value;
                orqSupply[3] = orqSupply[3].toFixed(1);
                if (orqSupply[3] > 0) orqSupply[3] = "+" + orqSupply[3];
                setOrqMiniGraphSupply([s_ntb1, s_ntl26, s_stb1, s_plb1]);
            }

            //NT L26 RETURN
            if (ret.info[0].data.length > 2) {
                for (var i = 0; i < ret.info[0].data.length; i++) {
                    r_ntl26[i] = ret.info[0].data[i].value;
                }
                orqReturn[1] =
                    ((ret.info[0].data[ret.info[0].data.length - 1].value -
                        ret.info[0].data[ret.info[0].data.length - 2].value) *
                        100) /
                    ret.info[0].data[ret.info[0].data.length - 2].value;
                orqReturn[1] = orqReturn[1].toFixed(1);
                if (orqReturn[1] > 0) orqReturn[1] = "+" + orqReturn[1];
            }

            //NT B1 RETURN
            if (ret.info[2].data.length > 2) {
                for (var i = 0; i < ret.info[2].data.length; i++) {
                    r_ntb1[i] = ret.info[2].data[i].value;
                }
                orqReturn[0] =
                    ((ret.info[2].data[ret.info[2].data.length - 1].value -
                        ret.info[2].data[ret.info[2].data.length - 2].value) *
                        100) /
                    ret.info[2].data[ret.info[2].data.length - 2].value;
                orqReturn[0] = orqReturn[0].toFixed(1);
                if (orqReturn[0] > 0) orqReturn[0] = "+" + orqReturn[0];
            }

            //ST B1 RETURN
            if (ret.info[4].data.length > 2) {
                for (var i = 0; i < ret.info[4].data.length; i++) {
                    r_stb1[i] = ret.info[4].data[i].value;
                }

                if (
                    ret.info[4].data[ret.info[4].data.length - 1] &&
                    ret.info[4].data[ret.info[4].data.length - 2]
                ) {
                    orqReturn[2] =
                        ((ret.info[4].data[ret.info[4].data.length - 1].value -
                            ret.info[4].data[ret.info[4].data.length - 2]
                                .value) *
                            100) /
                        ret.info[4].data[ret.info[4].data.length - 2].value;
                    orqReturn[2] = orqReturn[2].toFixed(1);
                } else orqReturn[2] = 0;
                if (orqReturn[2] > 0) orqReturn[2] = "+" + orqReturn[2];
            }

            //NT PODIUM RETURN
            if (ret.info[6].data.length > 2) {
                for (var i = 0; i < ret.info[6].data.length; i++) {
                    r_plb1[i] = ret.info[6].data[i].value;
                }
                orqReturn[3] =
                    ((ret.info[6].data[ret.info[6].data.length - 1].value -
                        ret.info[6].data[ret.info[6].data.length - 2].value) *
                        100) /
                    ret.info[6].data[ret.info[6].data.length - 2].value;
                orqReturn[3] = orqReturn[3].toFixed(1);
                if (orqReturn[3] > 0) orqReturn[3] = "+" + orqReturn[3];
            }

            //console.log("<----->")
            //console.log([r_ntb1, r_ntl26, r_stb1, r_plb1]);
            setOrqMiniGraphReturn([r_ntb1, r_ntl26, r_stb1, r_plb1]);

            var ntb1 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];
            var ntl26 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];
            var stb1 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];
            var plb1 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];

            for (var i = 0; i < r_ntb1.length; i++) {
                if (r_ntb1[i] <= 20 && s_ntb1[i] >= 0) {
                    ntb1[7 - i] = [r_ntb1[i], s_ntb1[i]];
                }
            }
            for (var i = 0; i < r_ntl26.length; i++) {
                if (r_ntl26[i] <= 20 && s_ntl26[i] >= 0) {
                    ntl26[7 - i] = [r_ntl26[i], s_ntl26[i]];
                }
            }
            for (var i = 0; i < r_stb1.length; i++) {
                if (r_stb1[i] <= 20 && s_stb1[i] >= 0) {
                    stb1[7 - i] = [r_stb1[i], s_stb1[i]];
                }
            }
            for (var i = 0; i < r_plb1.length; i++) {
                if (r_plb1[i] <= 20 && s_plb1[i] >= 0) {
                    plb1[7 - i] = [r_plb1[i], s_plb1[i]];
                }
            }

            setOrqInterval([ntb1, ntl26, stb1, plb1]);
        }
    }

    function onSlaBFCHeaderDailyAverage(ret) {
        if (ret.return === 100) {
            /*console.log(
                "==============onSlaBFCHeaderDailyAverage======================="
            );
            console.log(ret);*/
            var UPN_RETURN = 0;
            var UPN_SUPPLY = 0;
            var T1B2_RETURN = 0;
            var T1B2_SUPPLY = 0;
            var T2B2_RETURN = 0;
            var T2B2_SUPPLY = 0;
            for (var i = 0; i < ret.info.length; i++) {
                if (
                    ret.info[i] &&
                    "data" in ret.info[i] &&
                    "value" in ret.info[i].data
                ) {
                    if (ret.info[i].name === "UPN RETURN") {
                        UPN_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "UPN SUPPLY") {
                        UPN_SUPPLY = ret.info[i].data.value;
                    } else if (ret.info[i].name === "T1 B2 RETURN") {
                        T1B2_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "T1 B2 SUPPLY") {
                        T1B2_SUPPLY = ret.info[i].data.value;
                    } else if (ret.info[i].name === "T2 B2 RETURN") {
                        T2B2_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "T2 B2 SUPPLY") {
                        T2B2_SUPPLY = ret.info[i].data.value;
                    }
                }
            }
            //console.log("NT_L26_RETURN :" + NT_L26_RETURN);
            //console.log("NT_L26_SUPPLY :" + NT_L26_SUPPLY);
            //console.log("NT_B1_RETURN :" + NT_B1_RETURN);
            //console.log("NT_B1_SUPPLY :" + NT_B1_SUPPLY);
            //console.log("ST_B1_RETURN :" + ST_B1_RETURN);
            //console.log("ST_B1_SUPPLY :" + ST_B1_SUPPLY);
            //console.log("NT_PODIUM_RETURN :" + NT_PODIUM_RETURN);
            //console.log("NT_PODIUM_SUPPLY :" + NT_PODIUM_SUPPLY);

            /*var v1 = 0,
        v2 = 0,
        v3 = 0,
        v4 = 0;
      if (NT_L26_RETURN <= 20 && NT_L26_SUPPLY >= 0) {
        v1 = ((NT_L26_RETURN - NT_L26_SUPPLY) * 100) / 20.0;
        v1 = v1 + "%";
      }
      if (NT_B1_RETURN <= 20 && NT_B1_SUPPLY >= 0) {
        v2 = ((NT_B1_RETURN - NT_B1_SUPPLY) * 100) / 20.0;
        v2 = v2 + "%";
      }
      if (ST_B1_RETURN <= 20 && ST_B1_SUPPLY >= 0) {
        v3 = ((ST_B1_RETURN - ST_B1_SUPPLY) * 100) / 20.0;
        v3 = v3 + "%";
      }
      if (NT_PODIUM_RETURN <= 20 && NT_PODIUM_SUPPLY >= 0) {
        v4 = ((NT_PODIUM_RETURN - NT_PODIUM_SUPPLY) * 100) / 20.0;
        v4 = v4 + "%";
      }
      console.log(v1);console.log(v2);console.log(v3);console.log(v4);*/
            setBfcAvg([
                [UPN_SUPPLY, UPN_RETURN],
                [T1B2_SUPPLY, T1B2_RETURN],
                [T2B2_SUPPLY, T2B2_RETURN]
            ]);
            //setOrqAvg(["62.5%", "52.5%", "42.5%", "32.5%"]);
        }
    }

    function onSlaBFCHeaderInterval(ret) {
        if (ret.return === 100) {
            /*console.log(
                "==============onSlaBFCHeaderInterval======================="
            );
            console.log(ret);*/

            var s_upn = [];
            var s_t1b2 = [];
            var s_t2b2 = [];

            var r_upn = [];
            var r_t1b2 = [];
            var r_t2b2 = [];

            var largest = 0;
            var smallest = -1;
            var diff = 0;

            //UPN SUPPLY
            if (ret.info[1].data.length > 2) {
                for (var i = 0; i < ret.info[1].data.length; i++) {
                    s_upn[i] = ret.info[1].data[i].value;
                }
                bfcSupply[0] =
                    ((ret.info[1].data[ret.info[1].data.length - 1].value -
                        ret.info[1].data[ret.info[1].data.length - 2].value) *
                        100) /
                    ret.info[1].data[ret.info[1].data.length - 2].value;
                bfcSupply[0] = bfcSupply[0].toFixed(1);
                if (bfcSupply[0] > 0) bfcSupply[0] = "+" + bfcSupply[0];
            }

            //T1 B2 SUPPLY
            if (ret.info[3].data.length > 2) {
                for (var i = 0; i < ret.info[3].data.length; i++) {
                    s_t1b2[i] = ret.info[3].data[i].value;
                }
                bfcSupply[1] =
                    ((ret.info[3].data[ret.info[3].data.length - 1].value -
                        ret.info[3].data[ret.info[3].data.length - 2].value) *
                        100) /
                    ret.info[3].data[ret.info[3].data.length - 2].value;
                bfcSupply[1] = bfcSupply[1].toFixed(1);
                if (bfcSupply[1] > 0) bfcSupply[1] = "+" + bfcSupply[1];
            }

            //T2 B2 SUPPLY
            if (ret.info[5].data.length > 2) {
                for (var i = 0; i < ret.info[5].data.length; i++) {
                    s_t2b2[i] = ret.info[5].data[i].value;
                }
                if (
                    ret.info[5].data[ret.info[5].data.length - 1] &&
                    ret.info[5].data[ret.info[5].data.length - 2]
                ) {
                    bfcSupply[2] =
                        ((ret.info[5].data[ret.info[5].data.length - 1].value -
                            ret.info[5].data[ret.info[5].data.length - 2]
                                .value) *
                            100) /
                        ret.info[5].data[ret.info[5].data.length - 2].value;
                    bfcSupply[2] = bfcSupply[2].toFixed(1);
                } else bfcSupply[2] = 0;
                if (bfcSupply[2] > 0) bfcSupply[2] = "+" + bfcSupply[2];
            }

            setBfcMiniGraphSupply([s_upn, s_t1b2, s_t2b2]);

            //NT PODIUM SUPPLY
            /*if (ret.info[7].data.length > 2) {
        for (var i = 0; i < ret.info[7].data.length; i++) {
          s_plb1[i] = ret.info[7].data[i].value;
        }
        orqSupply[3] =
          ((ret.info[7].data[ret.info[7].data.length - 1].value -
            ret.info[7].data[ret.info[7].data.length - 2].value) *
            100) /
          ret.info[7].data[ret.info[7].data.length - 2].value;
        orqSupply[3] = orqSupply[3].toFixed(1);
        if (orqSupply[3] > 0) orqSupply[3] = "+" + orqSupply[3];
        setOrqMiniGraphSupply([s_ntb1, s_ntl26, s_stb1, s_plb1]);
    }*/

            //UPN RETURN
            if (ret.info[0].data.length > 2) {
                for (var i = 0; i < ret.info[0].data.length; i++) {
                    r_upn[i] = ret.info[0].data[i].value;
                }
                bfcReturn[0] =
                    ((ret.info[0].data[ret.info[0].data.length - 1].value -
                        ret.info[0].data[ret.info[0].data.length - 2].value) *
                        100) /
                    ret.info[0].data[ret.info[0].data.length - 2].value;
                bfcReturn[0] = bfcReturn[0].toFixed(1);
                if (bfcReturn[0] > 0) bfcReturn[0] = "+" + bfcReturn[0];
            }

            //T1 B2 RETURN
            if (ret.info[2].data.length > 2) {
                for (var i = 0; i < ret.info[2].data.length; i++) {
                    r_t1b2[i] = ret.info[2].data[i].value;
                }
                bfcReturn[1] =
                    ((ret.info[2].data[ret.info[2].data.length - 1].value -
                        ret.info[2].data[ret.info[2].data.length - 2].value) *
                        100) /
                    ret.info[2].data[ret.info[2].data.length - 2].value;
                bfcReturn[1] = bfcReturn[1].toFixed(1);
                if (bfcReturn[1] > 0) bfcReturn[1] = "+" + bfcReturn[1];
            }

            //T2 B2 RETURN
            if (ret.info[4].data.length > 2) {
                for (var i = 0; i < ret.info[4].data.length; i++) {
                    r_t2b2[i] = ret.info[4].data[i].value;
                }

                if (
                    ret.info[4].data[ret.info[4].data.length - 1] &&
                    ret.info[4].data[ret.info[4].data.length - 2]
                ) {
                    bfcReturn[2] =
                        ((ret.info[4].data[ret.info[4].data.length - 1].value -
                            ret.info[4].data[ret.info[4].data.length - 2]
                                .value) *
                            100) /
                        ret.info[4].data[ret.info[4].data.length - 2].value;
                    bfcReturn[2] = bfcReturn[2].toFixed(1);
                } else bfcReturn[2] = 0;
                if (bfcReturn[2] > 0) bfcReturn[2] = "+" + bfcReturn[2];
            }

            //NT PODIUM RETURN
            /*if (ret.info[6].data.length > 2) {
        for (var i = 0; i < ret.info[6].data.length; i++) {
          r_plb1[i] = ret.info[6].data[i].value;
        }
        orqReturn[3] =
          ((ret.info[6].data[ret.info[6].data.length - 1].value -
            ret.info[6].data[ret.info[6].data.length - 2].value) *
            100) /
          ret.info[6].data[ret.info[6].data.length - 2].value;
        orqReturn[3] = orqReturn[3].toFixed(1);
        if (orqReturn[3] > 0) orqReturn[3] = "+" + orqReturn[3];
    }*/

            //console.log("<----->")
            //console.log([r_ntb1, r_ntl26, r_stb1, r_plb1]);
            setBfcMiniGraphReturn([r_upn, r_t1b2, r_t2b2]);

            var upn = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];
            var t1b2 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];
            var t2b2 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];
            /*var plb1 = [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0]
    ];*/

            for (var i = 0; i < r_upn.length; i++) {
                if (r_upn[i] <= 20 && s_upn[i] >= 0) {
                    upn[7 - i] = [r_upn[i], s_upn[i]];
                }
            }
            for (var i = 0; i < r_t1b2.length; i++) {
                if (r_t1b2[i] <= 20 && s_t1b2[i] >= 0) {
                    t1b2[7 - i] = [r_t1b2[i], s_t1b2[i]];
                }
            }
            for (var i = 0; i < r_t2b2.length; i++) {
                if (r_t2b2[i] <= 20 && s_t2b2[i] >= 0) {
                    t2b2[7 - i] = [r_t2b2[i], s_t2b2[i]];
                }
            }
            /*for (var i = 0; i < r_plb1.length; i++) {
        if (r_plb1[i] <= 20 && s_plb1[i] >= 0) {
          plb1[7 - i] = [r_plb1[i], s_plb1[i]];
        }
    }*/
            //console.log(upn);
            setBfcInterval([upn, t1b2, t2b2]);
        }
    }

    function onSlaCBDHeaderDailyAverage(ret) {
        if (ret.return === 100) {
            /*console.log(
                "==============onSlaCBDHeaderDailyAverage======================="
            );
            console.log(ret);*/
            var T3B1_RETURN = 0;
            var T3B1_SUPPLY = 0;
            var T3L39_RETURN = 0;
            var T3L39_SUPPLY = 0;
            for (var i = 0; i < ret.info.length; i++) {
                if (
                    ret.info[i] &&
                    "data" in ret.info[i] &&
                    "value" in ret.info[i].data
                ) {
                    if (ret.info[i].name === "T3 B1 RETURN") {
                        T3B1_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "T3 B1 SUPPLY") {
                        T3B1_SUPPLY = ret.info[i].data.value;
                    } else if (ret.info[i].name === "T3 L39 RETURN") {
                        T3L39_RETURN = ret.info[i].data.value;
                    } else if (ret.info[i].name === "T3 L39 SUPPLY") {
                        T3L39_SUPPLY = ret.info[i].data.value;
                    }
                }
            }
            //console.log("NT_L26_RETURN :" + NT_L26_RETURN);
            //console.log("NT_L26_SUPPLY :" + NT_L26_SUPPLY);
            //console.log("NT_B1_RETURN :" + NT_B1_RETURN);
            //console.log("NT_B1_SUPPLY :" + NT_B1_SUPPLY);
            //console.log("ST_B1_RETURN :" + ST_B1_RETURN);
            //console.log("ST_B1_SUPPLY :" + ST_B1_SUPPLY);
            //console.log("NT_PODIUM_RETURN :" + NT_PODIUM_RETURN);
            //console.log("NT_PODIUM_SUPPLY :" + NT_PODIUM_SUPPLY);

            /*var v1 = 0,
        v2 = 0,
        v3 = 0,
        v4 = 0;
      if (NT_L26_RETURN <= 20 && NT_L26_SUPPLY >= 0) {
        v1 = ((NT_L26_RETURN - NT_L26_SUPPLY) * 100) / 20.0;
        v1 = v1 + "%";
      }
      if (NT_B1_RETURN <= 20 && NT_B1_SUPPLY >= 0) {
        v2 = ((NT_B1_RETURN - NT_B1_SUPPLY) * 100) / 20.0;
        v2 = v2 + "%";
      }
      if (ST_B1_RETURN <= 20 && ST_B1_SUPPLY >= 0) {
        v3 = ((ST_B1_RETURN - ST_B1_SUPPLY) * 100) / 20.0;
        v3 = v3 + "%";
      }
      if (NT_PODIUM_RETURN <= 20 && NT_PODIUM_SUPPLY >= 0) {
        v4 = ((NT_PODIUM_RETURN - NT_PODIUM_SUPPLY) * 100) / 20.0;
        v4 = v4 + "%";
      }
      console.log(v1);console.log(v2);console.log(v3);console.log(v4);*/
            setCbdAvg([
                [T3B1_SUPPLY, T3B1_RETURN],
                [T3L39_SUPPLY, T3L39_RETURN]
            ]);
            //setOrqAvg(["62.5%", "52.5%", "42.5%", "32.5%"]);
        }
    }

    function onSlaCBDHeaderInterval(ret) {
        if (ret.return === 100) {
            console.log(
                "==============onSlaCBDHeaderInterval======================="
            );
            console.log(ret);

            var s_t3b1 = [];
            var s_t3l39 = [];

            var r_t3b1 = [];
            var r_t3l39 = [];

            var largest = 0;
            var smallest = -1;
            var diff = 0;

            //T3 B1 SUPPLY
            if (ret.info[1].data.length > 2) {
                for (var i = 0; i < ret.info[1].data.length; i++) {
                    s_t3b1[i] = ret.info[1].data[i].value;
                }
                cbdSupply[0] =
                    ((ret.info[1].data[ret.info[1].data.length - 1].value -
                        ret.info[1].data[ret.info[1].data.length - 2].value) *
                        100) /
                    ret.info[1].data[ret.info[1].data.length - 2].value;
                cbdSupply[0] = cbdSupply[0].toFixed(1);
                if (cbdSupply[0] > 0) cbdSupply[0] = "+" + cbdSupply[0];
            }

            //T3 L39 SUPPLY
            if (ret.info[3].data.length > 2) {
                for (var i = 0; i < ret.info[3].data.length; i++) {
                    s_t3l39[i] = ret.info[3].data[i].value;
                }
                cbdSupply[1] =
                    ((ret.info[3].data[ret.info[3].data.length - 1].value -
                        ret.info[3].data[ret.info[3].data.length - 2].value) *
                        100) /
                    ret.info[3].data[ret.info[3].data.length - 2].value;
                cbdSupply[1] = cbdSupply[1].toFixed(1);
                if (cbdSupply[1] > 0) cbdSupply[1] = "+" + cbdSupply[1];
            }

            setCbdMiniGraphSupply([s_t3b1, s_t3l39]);


            //T3 B1 RETURN
            if (ret.info[0].data.length > 2) {
                for (var i = 0; i < ret.info[0].data.length; i++) {
                    r_t3b1[i] = ret.info[0].data[i].value;
                }
                cbdReturn[0] =
                    ((ret.info[0].data[ret.info[0].data.length - 1].value -
                        ret.info[0].data[ret.info[0].data.length - 2].value) *
                        100) /
                    ret.info[0].data[ret.info[0].data.length - 2].value;
                cbdReturn[0] = cbdReturn[0].toFixed(1);
                if (cbdReturn[0] > 0) cbdReturn[0] = "+" + cbdReturn[0];
            }

            //T3 L39 Return
            if (ret.info[2].data.length > 2) {
                for (var i = 0; i < ret.info[2].data.length; i++) {
                    r_t3l39[i] = ret.info[2].data[i].value;
                }
                cbdReturn[1] =
                    ((ret.info[2].data[ret.info[2].data.length - 1].value -
                        ret.info[2].data[ret.info[2].data.length - 2].value) *
                        100) /
                    ret.info[2].data[ret.info[2].data.length - 2].value;
                cbdReturn[1] = cbdReturn[1].toFixed(1);
                if (cbdReturn[1] > 0) cbdReturn[1] = "+" + cbdReturn[1];
            }

            setCbdMiniGraphReturn([r_t3b1, r_t3l39]);

            var t3b1 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];
            var t3l39 = [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
            ];

            for (var i = 0; i < r_t3b1.length; i++) {
                if (r_t3b1[i] <= 20 && s_t3b1[i] >= 0) {
                    //console.log("dddd")
                    t3b1[7 - i] = [r_t3b1[i], s_t3b1[i]];
                }
            }
            for (var i = 0; i < r_t3l39.length; i++) {
                if (r_t3l39[i] <= 20 && s_t3l39[i] >= 0) {
                    t3l39[7 - i] = [r_t3l39[i], s_t3l39[i]];
                }
            }
            //console.log(t3b1)
            setCbdInterval([t3b1, t3l39]);
        }
    }

    function onSlaORQco2(ret) {
        //console.log(ret);
        var counter1 = 0;
        var counter2 = 0;
        var counter3 = 0;
        if (ret.return === 100) {
            ret.info.array.forEach((item, i) => {
                if (item.value < 1000) counter1++;
                else if (item.value < 1200) counter2++;
                else counter3++;
            });

            setOrqCo2([
                ret.info.total - counter1 - counter2 - counter3,
                counter1,
                counter2,
                counter3
            ]);
        }
    }

    function onSlaBFCco2(ret) {
        var counter1 = 0;
        var counter2 = 0;
        var counter3 = 0;
        if (ret.return === 100) {
            ret.info.array.forEach((item, i) => {
                if (item.value < 1000) counter1++;
                else if (item.value < 1200) counter2++;
                else counter3++;
            });

            setBfcCo2([
                ret.info.total - counter1 - counter2 - counter3,
                counter1,
                counter2,
                counter3
            ]);
        }
    }

    function onSlaCBDco2(ret) {
        var counter1 = 0;
        var counter2 = 0;
        var counter3 = 0;
        if (ret.return === 100) {
            ret.info.array.forEach((item, i) => {
                if (item.value < 1000) counter1++;
                else if (item.value < 1200) counter2++;
                else counter3++;
            });

            /*console.log(ret.info);
            console.log(counter1);
            console.log(counter2);
            console.log(counter3);*/
            setCbdCo2([
                ret.info.total - counter1 - counter2 - counter3,
                counter1,
                counter2,
                counter3
            ]);
        }
    }

    useInterval(() => {
        var graphType_ = !graphType;
        setGraphType(graphType_);
        if (graphType_ === false) {
            slaORQHeaderDailyAverage(onSlaORQHeaderDailyAverage, {});
            slaBFCHeaderDailyAverage(onSlaBFCHeaderDailyAverage, {});
            slaCBDHeaderDailyAverage(onSlaCBDHeaderDailyAverage, {});
        }
        slaORQHeaderInterval(onSlaORQHeaderInterval, {});
        slaBFCHeaderInterval(onSlaBFCHeaderInterval, {});
        slaCBDHeaderInterval(onSlaCBDHeaderInterval, {});

        slaORQco2(onSlaORQco2, {});
        slaBFCco2(onSlaBFCco2, {});
        slaCBDco2(onSlaCBDco2, {});
    }, durationMs);

    useEffect(() => {
        if (graphType === false) {
            slaORQHeaderDailyAverage(onSlaORQHeaderDailyAverage, {});
            slaBFCHeaderDailyAverage(onSlaBFCHeaderDailyAverage, {});
            slaCBDHeaderDailyAverage(onSlaCBDHeaderDailyAverage, {});
        }
        slaORQHeaderInterval(onSlaORQHeaderInterval, {});
        slaBFCHeaderInterval(onSlaBFCHeaderInterval, {});
        slaCBDHeaderInterval(onSlaCBDHeaderInterval, {});

        slaORQco2(onSlaORQco2, {});
        slaBFCco2(onSlaBFCco2, {});
        slaCBDco2(onSlaCBDco2, {});
    }, []);

    if (getAuthTokens() == null) {
        const referer = "/404";
        return <Redirect to={referer} />;
    } else return <div>{mainDiv}</div>;
}

export default Sla;
