import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { energyDailyKwh } from "../../api/energy";
import { energyDailyRth } from "../../api/energy";
import { getSemiMonth } from "../../Utils";
import { dailyLevelEnergy } from "../../api/energy";

function EnergyGraphLevel(props) {
  var echarts = require("echarts");

  const dataAxis1 = [
    "L1M",
    "L2",
    "L3",
    "L4",
    "L5",
    "L6",
    "L7",
    "L8",
    "L9",
    "L10",
    "L11",
    "L12",
    "L14",
    "L15"
  ];
  const dataAxis2 = [
    "L16",
    "L17",
    "L18",
    "L19",
    "L20",
    "L21",
    "L22",
    "L23",
    "L24",
    "L25",
    "L26",
    "L27",
    "L28",
    "L29",
    "L30",
    "L31"
  ];

  const dataAxis3 = ["L1M", "L3", "L4", "L5", "L6"];
  const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const refreshInterval = 6000 * 1; // 600 * 5; //60000*5;
  const [currentAxis, setCurrentAxis] = useState(dataAxis1);
  const [currBuilding, setCurrBuilding] = useState("TOWER1");

  function getBarColor(type) {
    if (type === "kwh") {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#92d70d" },
        { offset: 1, color: "#347b09" }
      ]);
    } else {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#e90b5f" },
        { offset: 1, color: "#830839" }
      ]);
    }
  }

  const [option, setOption] = useState({
    grid: {
      left: "1%",
      right: "10%",
      bottom: "0%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      position: "left",
      nameLocation: "end",
      name: "kWh",
      boundaryGap: [0, 0.0],
      nameTextStyle: {
        color: "#ffffff",
        align: "center",
        position: "bottom",
        fontSize: 13,
        padding: [0, -20, 0, 0]
      },
      data: data,
      splitLine: { lineStyle: { color: "#323436", width: 1 } },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true
      },
      axisLabel: {
        textStyle: {
          color: "#ffffff"
        }
      },
      z: 10
    },
    yAxis: {
      data: currentAxis,
      position: "left",
      nameLocation: "end",
      name: "FLOOR",
      nameTextStyle: {
        color: "#ffffff",
        align: "right",
        padding: [0, -10, 0, 0]
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        textStyle: {
          color: "#ffffff"
        }
      },
      type: "category"
    },
    series: [
      {
        type: "bar",
        barMaxWidth: 16,
        itemStyle: {
          color: getBarColor(props.type)
        },
        data: data
      },
      {
        type: "line",
        name: "AVG",
        lineStyle: {
          color: "#FFFF00",
          width: 2,
          type: "solid"
        },
        symbol: "none",
        smooth: true,
        data: data
      }
    ]
  });

  function dailyEnergyArrayCB(ret, building_) {
    if (ret.return === 100) {
      var dataAxis_ = [];
      var data_ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var avgdata_ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const option_ = cloneDeep(option);

      if (building_ === "TOWER1") {
        option_.yAxis.data = dataAxis1;
        setCurrentAxis(dataAxis1);
        dataAxis_ = dataAxis1;
      } else if (building_ === "TOWER2") {
        option_.yAxis.data = dataAxis2;
        setCurrentAxis(dataAxis2);
        dataAxis_ = dataAxis2;
      } else {
        option_.yAxis.data = dataAxis3;
        setCurrentAxis(dataAxis3);
        dataAxis_ = dataAxis3;
      }

      var b = "TOWER";
      if (building_ === "PAVILLION") b = "PAVILLION";
      for (var i = 0; i < ret.info.data.length; i++) {
        if (ret.info.data[i].building === b) {
          var index = dataAxis_.indexOf(ret.info.data[i].level);
          if (index != -1) {
            data_[index] = ret.info.data[i].total;
          }
        }
      }

      for (var i = 0; i < ret.info.avg.length; i++) {
        if (ret.info.data[i].building === b) {
          var index = dataAxis_.indexOf(ret.info.avg[i]._id.level);
          if (index != -1) {
            avgdata_[index] = ret.info.avg[i].total;
          }
        }
      }

      option_.series[0].data = data_;
      option_.series[1].data = avgdata_;
      setOption(option_);
    }
  }

  var d = new Date();
  d.setDate(d.getDate());
  var curr_date = d.getDate();
  var curr_month = d.getMonth() + 1;
  var curr_year = d.getFullYear();
  var dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var timeStamp = Math.floor(new Date(dateString).getTime() / 1000);

  d.setDate(d.getDate() - 14);
  curr_date = d.getDate();
  curr_month = d.getMonth() + 1;
  curr_year = d.getFullYear();
  dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var last14DaysTs = Math.floor(new Date(dateString).getTime() / 1000);

  useEffect(() => {
    var building_ = "TOWER1";
    dailyLevelEnergy(
      dailyEnergyArrayCB,
      {
        ts: { $gte: last14DaysTs }
      },
      building_
    );
  }, []);

  useInterval(() => {
    var building_ = "";
    if (currBuilding === "TOWER1") {
      setCurrBuilding("TOWER2");
      building_ = "TOWER2";
    } else if (currBuilding === "TOWER2") {
      setCurrBuilding("PAVILLION");
      building_ = "PAVILLION";
    } else if (currBuilding === "PAVILLION") {
      setCurrBuilding("TOWER1");
      building_ = "TOWER1";
    }
    dailyLevelEnergy(
      dailyEnergyArrayCB,
      {
        ts: { $gte: last14DaysTs }
      },
      building_
    );
  }, refreshInterval);

  return (
    <>
      <div className="col-12 box-title">
        {"Power consumption by floors " +
          (currBuilding === "TOWER1" || currBuilding === "TOWER2"
            ? "(TOWER BLOCK)"
            : "(PAVILLION BLOCK)")}
      </div>
      <div className="col-12">
        <div style={{ height: 600, paddingTop: 0 }}>
          <ReactEcharts
            style={{ height: "580px", width: "100%" }}
            option={option}
          />
        </div>
      </div>
    </>
  );
}

export default EnergyGraphLevel;
