import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function eventGetActivities(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/event/eventGetActivitiesNoDB", newPostObj);
  //const response = await fetch("https://uobtsk.gpsolutions.sg/api/event/eventGetActivities", newPostObj);
  const json = await response.json();
  //console.log(json);
  callback(json);
}
