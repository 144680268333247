import React, { useState, useEffect } from "react";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import CurrentAlertTypeItems from "./currentAlertTypeItems";
import CurrentDistributionAlert from "./currentDistributionAlert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { currentAlarm } from "../../api/alarm";

function CurrentAlertTypes(props) {
  const queryTimeMs = 5000;
  const [ready, setReady] = useState(false);
  const [types, setTypes] = useState([
    {
      name: "HX",
      iconPath: "/images/icon-cat-hx.png",
      count: 10,
      status: false,
      data: []
    },
    {
      name: "FAN",
      iconPath: "/images/icon-cat-pumps.png",
      count: 0,
      status: false,
      data: []
    },
    {
      name: "FCU",
      iconPath: "/images/icon-cat-fcu.png",
      count: 0,
      status: false,
      data: []
    },
    {
      name: "AHU",
      iconPath: "/images/icon-cat-ahu.png",
      count: 0,
      status: false,
      data: []
    },
    {
      name: "VAV",
      iconPath: "/images/icon-cat-vav.png",
      count: 0,
      status: false,
      data: []
    },
    {
      name: "P & S",
      iconPath: "/images/icon-cat-pns.png",
      count: 0,
      status: false,
      data: []
    }
  ]);

  function getAlertClass(building_, typeName) {
    let data_ = {};
    let currentEpoch = new Date();
    currentEpoch = Math.round(currentEpoch.getTime() / 1000);
    data_ = types;

    let i = 0;
    let j = 0;
    let signal = false;
    for (i = 0; i < data_.length; i++) {
      if (data_[i].name === typeName && data_[i].count > 0) {
        for (j = 0; j < data_[i].data.length; j++) {
          //console.log(data_[i].data[j].startAlarmTimeStamp);
          if (data_[i].data[j].startAlarmTimeStamp + 600 > currentEpoch) {
            signal = true;
            break;
          }
        }
      }
    }

    if (signal) return "box row no-gutters box-glow-red hl";
    else {
      return "box row no-gutters";
    }
  }

  function getCurrentTypesDisplay() {
    return types.map((type, index) => (
      <div
        key={type.name + index + props.buildingName}
        className={getAlertClass(props.buildingName, type.name)}
      >
        <div className="col-12">
          <div className="row no-gutters">
            <div className="col-8 label-gp">
              <img src={type.iconPath} className="icon" />
              {type.name}
            </div>
            <div className="col-4 ">
              <div className="value-gp">
                <div className="value-xl count"> {type.count} </div>
                <div className="note-s"> alerts </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters error-gp" style={{marginTop:0}}>
            <CurrentAlertTypeItems
              index={type.name}
              data={type.data}
              buildingIndex={props.buildingIndex}
            />
          </div>
        </div>
      </div>
    ));
  }

  function processData(ret, types_) {
    var i = 0;
    var j = 0;
    for (i = 0; i < ret.info.data.length; i++) {
      for (j = 0; j < types_.length; j++) {
        if (ret.info.data[i].type === types_[j].name) {
          types_[j].count = ret.info.data[i].total;
          types_[j].data = ret.info.data[i].data;
          break;
        }
      }
    }
  }

  function onCurrentAlarm(ret) {
    if (ret.return === 100) {
      const fullTypes_ = cloneDeep(types);
      processData(ret, fullTypes_);
      setTypes(fullTypes_);
      setReady(true);
    }
  }

  useInterval(() => {
    currentAlarm(onCurrentAlarm, {
      buildingName: props.buildingName
    });
  }, queryTimeMs);

  useEffect(() => {
    currentAlarm(onCurrentAlarm, {
      buildingName: props.buildingName
    });
  }, []);

  function getDistributionTitle(buildingIndex_) {
    if (buildingIndex_ === "orq") {
      return (
        <div className="row no-gutters">
          <div className="col-7" />
          <div className="col-5 widget-subtitle">Distribution</div>
        </div>
      );
    } else {
      return;
    }
  }

  function getAlertTitle(buildingIndex_) {
    if (buildingIndex_ === "orq") {
      return (
        <div className="row no-gutters">
          <div className="widget-subtitle col-12">Current Alerts</div>
        </div>
      );
    } else {
      return;
    }
  }

  return (() => {
    if (!ready) {
      return <React.Fragment />;
    } else {
      return (
        <div key={props.index} className="row itm-gp no-gutters">
          <div className="col-6 ui-col chart-gp">
            {getDistributionTitle(props.index)}
            <React.Fragment>
              <div className="row no-gutters">
                <div className="col-7">
                  <CurrentDistributionAlert
                    key={props.index}
                    index={props.index}
                    types={types}
                    buildingName={props.buildingName}
                  />
                </div>
                <div className="col-5 chart-legend">
                  <div className="row no-gutters">
                    <FiberManualRecordIcon
                      style={{
                        color: "#d60956",
                        marginTop: 2,
                        fontSize: 18
                      }}
                    />
                    <span style={{ fontSize: 15 }}>&nbsp;HX</span>
                  </div>
                  <div className="row no-gutters">
                    <FiberManualRecordIcon
                      style={{
                        color: "#f99c0b",
                        marginTop: 2,
                        fontSize: 18
                      }}
                    />
                    <span style={{ fontSize: 15 }}>&nbsp;PUMPS</span>
                  </div>
                  <div className="row no-gutters">
                    <FiberManualRecordIcon
                      style={{
                        color: "#6dd518",
                        marginTop: 2,
                        fontSize: 18
                      }}
                    />
                    <span style={{ fontSize: 15 }}>&nbsp;FCU</span>
                  </div>
                  <div className="row no-gutters">
                    <FiberManualRecordIcon
                      style={{
                        color: "#168603",
                        marginTop: 2,
                        fontSize: 18
                      }}
                    />
                    <span style={{ fontSize: 15 }}>&nbsp;AHU</span>
                  </div>
                  <div className="row no-gutters">
                    <FiberManualRecordIcon
                      style={{
                        color: "#0fc3ac",
                        marginTop: 2,
                        fontSize: 18
                      }}
                    />
                    <span style={{ fontSize: 15 }}>&nbsp;VAV</span>
                  </div>
                  <div className="row no-gutters">
                    <FiberManualRecordIcon
                      style={{
                        color: "#0aaecb",
                        marginTop: 2,
                        fontSize: 18
                      }}
                    />
                    <span style={{ fontSize: 15 }}>&nbsp;P & S</span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>

          <div className="col rect-gp">
            {getAlertTitle(props.index)}
            <div className="row no-gutters">
              <div className="col-12">{getCurrentTypesDisplay()}</div>
            </div>
          </div>
        </div>
      );
    }
  })();
}

export default CurrentAlertTypes;
