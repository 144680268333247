import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import Vav from "../Vav";
import Sla from "../Sla";
import Energy from "../Energy";
import Alert from "../Alert";
import {
  useInterval,
  getCurrentFullYear,
  getCurrentSemiMonthName,
  numberWithCommas,
  getCurrentSemiMonthYearName
} from "../../Utils";
import { energyCurrentKwh, energyCurrentRth } from "../../api/energy";

function LeftWall(props) {
  const durationMs = 60000;
  const { getAuthTokens } = useAuth();

  const [currentKwh, setCurrentKwh] = useState("");
  const [currentKwhPerM2, setCurrentKwhPerM2] = useState("");

  const [currentRth, setCurrentRth] = useState("");
  const [currentRthPerM2, setCurrentRthPerM2] = useState("");

  const [buildingCounter, setBuildingCounter] = useState(0);
  const [currentName, setCurrentName] = useState("");

  function onEnergyCurrentKwh(ret) {
    let value = 0;

    if (ret.return === 100) {
      if (ret.info.building === "ORQ") {
        if ("current" in ret.info && ret.info.current !== 0) {
          value = ret.info.current / 148485;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "BFC") {
        if ("current" in ret.info && ret.info.current !== 0) {
          value = ret.info.current / 188798;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "CBD") {
        if ("current" in ret.info && ret.info.current !== 0) {
          value = ret.info.current / 151777;
          value = value.toFixed(2);
        }
      }
    }
    setCurrentKwhPerM2(value);
  }

  function onEnergyCurrentRth(ret) {
    let value = 0;
    if (ret.return === 100) {
      if (ret.info.building === "ORQ") {
        if ("current" in ret.info && ret.info.current !== 0) {
          value = ret.info.current / 148485;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "BFC") {
        if ("current" in ret.info && ret.info.current !== 0) {
          value = ret.info.current / 188798;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "CBD") {
        if ("current" in ret.info && ret.info.current !== 0) {
          value = ret.info.current / 151777;
          value = value.toFixed(2);
        }
      }
    }
    setCurrentRthPerM2(value);
  }

  useInterval(() => {
    let buildingCounter_ = buildingCounter + 1;
    if (buildingCounter_ > 2) {
      buildingCounter_ = 0;
    }
    setCurrentName(props.buildings[buildingCounter_].buildingName);
    setBuildingCounter(buildingCounter_);
    energyCurrentKwh(onEnergyCurrentKwh, {
      buildingName: props.buildings[buildingCounter_].buildingName
    });
    energyCurrentRth(onEnergyCurrentRth, {
      buildingName: props.buildings[buildingCounter_].buildingName
    });
  }, durationMs);

  useEffect(() => {
    setCurrentName(props.buildings[buildingCounter].buildingName);
    energyCurrentKwh(onEnergyCurrentKwh, {
      buildingName: props.buildings[buildingCounter].buildingName
    });
    energyCurrentRth(onEnergyCurrentRth, {
      buildingName: props.buildings[buildingCounter].buildingName
    });
  }, []);

  function getBuildingName() {
    if (currentName === "ORQ") {
      return (
        <div className="building-name building-orq">
          <div>One</div>
          <div>
            <span>Raffles Quay</span>
          </div>
        </div>
      );
    } else if (currentName === "BFC") {
      return (
        <div className="building-name building-bfc">
          <div>
            <span>BFC</span>
          </div>
        </div>
      );
    } else if (currentName === "CBD") {
      return (
        <div className="building-name building-cbd">
          <div>
            <span>CBD</span>
          </div>
        </div>
      );
    }
  }

  function getClassImage() {
    if (currentName === "ORQ") {
      return "building main-holder orq";
    } else if (currentName === "BFC") {
      return "building main-holder bfc";
    } else if (currentName === "CBD") {
      return "building main-holder cbd";
    }
  }

  const mainDiv = (
    <div className="wall-left">
      <div className="heat has-subtitle main-holder">
        <Vav {...props} />
      </div>
      <div className="sla has-subtitle main-holder">
        <Sla {...props} />
      </div>
      <div className={getClassImage()}>
        <div className="pane1">
          {getBuildingName()}
          <div className="row no-gutters">
            <div className="col-3 text-right icon">
              <img src="images/icon-building.png" />
            </div>
            <div className="col-9">
              <div className="today-usage-kwh">
                <div className="value-label-xxl">Today Usage</div>
                <div>
                  <span className="value-xxl glow-orange">
                    {currentKwhPerM2}
                  </span>
                  <span className="value-unit-l">
                    kWh/m<sup>2</sup>
                  </span>
                </div>
                <div className="note-l">
                  {/*  Usage was up 3%<img src="images/icon-arrow-up.png" />*/}
                </div>
              </div>
              <div className="today-usage-rth">
                <div>
                  <span className="value-xxl glow-orange">
                    {currentRthPerM2}
                  </span>
                  <span className="value-unit-l">
                    Rth/m<sup>2</sup>
                  </span>
                </div>
                <div className="note-l">
                  {/*  Usage was up 3%<img src="images/icon-arrow-up.png" />*/}
                </div>
              </div>
            </div>
          </div>
          <div className="seperator-h-80p" />
          <div className="row no-gutters">
            <div className="col-3 text-right icon">
              <img src="images/icon-eui.png" />
            </div>
            <div className="col-9">
              <div className="today-eui">
                <div className="value-label-xxl">Today EUI</div>
                <div>
                  <span className="value-xxl glow-orange">1,210</span>
                  <span className="value-unit-l">kWh</span>
                </div>
                <div className="note-l">
                  {/* EUI was up 12%<img src="images/icon-arrow-up.png" />*/}
                </div>
              </div>
              <div className="today-avg">
                <div className="value-label-xxl">AVG. PER HOUR</div>
                <div>
                  <span className="value-xxl glow-orange">0.3</span>
                  <span className="value-unit-l">
                    kWh/m<sup>2</sup>
                  </span>
                </div>
                {/* <div class="note-l">Usage was up 3%<img src="images/icon-arrow-up.png"></div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Energy {...props} />

      <div className="d-alert has-subtitle main-holder">
        <Alert {...props} wall="left" />
      </div>
    </div>
  );

  if (getAuthTokens() == null) {
    const referer = "/404";
    return <Redirect to={referer} />;
  } else return <div>{mainDiv}</div>;
}

export default LeftWall;
