import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import {
  groupBy,
  useInterval,
  numberWithCommas,
  getCurrentSemiMonthYearName
} from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import {
  currentTempHumidity,
  dailyEnergyConsumption,
  currentHeaderData,
  efficiency,
  coolingLoad,
  heatBalance
} from "../../api/chiller";
import Eff from "./eff";
import EffChart from "./effChart";

function Chiller(props) {
  const refreshInterval = 1000 * 60 * 5;
  const refreshInterval2 = 1000 * 30;

  const [dateStr, setDateStr] = useState("");
  const [timeStr, setTimeStr] = useState("");

  const [temperature, setTemperature] = useState(0);
  const [humidity, setHumidity] = useState(0);

  const [chillerEnergy, setChillerEnergy] = useState("");
  const [CHWPEnergy, setCHWPEnergy] = useState("");
  const [CDWPEnergy, setCDWPEnergy] = useState("");
  const [CTEnergy, setCTEnergy] = useState("");

  const [chillerEnergyPerc, setChillerEnergyPerc] = useState({
    width: "50%"
  });
  const [CHWPEnergyPerc, setCHWPEnergyPerc] = useState({ width: "50%" });
  const [CDWPEnergyPerc, setCDWPEnergyPerc] = useState({ width: "50%" });
  const [CTEnergyPerc, setCTEnergyPerc] = useState({ width: "50%" });

  const [CHWRTemp, setCHWRTemp] = useState(0);
  const [CHWSTemp, setCHWSTemp] = useState(0);
  const [CHWRTemp1, setCHWRTemp1] = useState(0);
  const [CHWSTemp1, setCHWSTemp1] = useState(0);
  const [CHWRTemp2, setCHWRTemp2] = useState(0);
  const [CHWSTemp2, setCHWSTemp2] = useState(0);
  const [currBuilding, setCurrBuilding] = useState("TOWER BLOCK");

  const [CHWRFlowRate, setCHWRFlowRate] = useState(0);
  const [CDWRTemp, setCDWRTemp] = useState(0);
  const [CDWSTemp, setCDWSTemp] = useState(0);
  const [CDWRFlowRate, setCDWRFlowRate] = useState(0);

  const [effTotal, setEffTotal] = useState(0);
  const [effCH, setEffCH] = useState(0);
  const [effCHWP, setEffCHWP] = useState(0);
  const [effCDWP, setEffCDWP] = useState(0);
  const [effCT, setEffCT] = useState(0);

  const [rt, setRt] = useState(0);
  const [clPerc, setClPerc] = useState(0);
  const [muw, setMuw] = useState(0);
  const [muwCost, setMuwCost] = useState(0);

  const [chillerLastHour, setChillerLastHour] = useState(0);

  const [heatLoad, setHeatLoad] = useState([0, 0, 0, 0, 0]);

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  function setDateTime() {
    var d = new Date();
    d.setDate(d.getDate());
    var options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "Asia/Bangkok",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    };
    var today = d.toLocaleTimeString("en-IN", options);
    today = today.substring(today.indexOf("at") + 3);
    console.log(today);
    setTimeStr(today);

    options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "Asia/Bangkok"
    };
    today = d.toLocaleDateString("en-IN", options);
    setDateStr(today);
    console.log(today);
  }

  function currentTempHumidityCB(ret) {
    if (ret.return === 100) {
      setHumidity(ret.info.humidity.toFixed(1));
      setTemperature((((ret.info.temperature - 32) * 5) / 9).toFixed(1));
    }
  }

  function dailyEnergyConsumptionCB(ret) {
    if (ret.return === 100) {
      setChillerEnergy(ret.info.chiller);
      setCHWPEnergy(ret.info.chwp);
      setCDWPEnergy(ret.info.cdwp);
      setCTEnergy(ret.info.ct);
    }
  }

  function currentHeaderDataCB(ret) {
    console.log(ret);
    if (ret.return === 100) {
      setCHWRTemp1((((ret.info.CHWRTemp1 - 32) * 5) / 9).toFixed(1));
      setCHWSTemp1((((ret.info.CHWSTemp1 - 32) * 5) / 9).toFixed(1));

      setCHWRTemp2((((ret.info.CHWRTemp2 - 32) * 5) / 9).toFixed(1));
      setCHWSTemp2((((ret.info.CHWSTemp2 - 32) * 5) / 9).toFixed(1));

      setCHWRFlowRate(ret.info.CHWRFlowRate.toFixed(1));
      //setCDWRTemp((((ret.info.CDWRTemp - 32) * 5) / 9).toFixed(1));
      //setCDWSTemp((((ret.info.CDWSTemp - 32) * 5) / 9).toFixed(1));
      setCDWSTemp(ret.info.CDWRTemp.toFixed(1));
      setCDWRTemp(ret.info.CDWSTemp.toFixed(1));
      setCDWRFlowRate(ret.info.CDWRFlowRate.toFixed(1));
    }
  }

  function efficiencyCB(ret) {
    if (ret.return === 100) {
      var total =
        ret.info[0].data.value +
        ret.info[1].data.value +
        ret.info[2].data.value +
        ret.info[3].data.value;
      total = total.toFixed(2);

      setEffTotal(total);

      if (ret.info[0] && "data" in ret.info[0])
        setEffCH(ret.info[0].data.value.toFixed(2));
      if (ret.info[1] && "data" in ret.info[1])
        setEffCHWP(ret.info[1].data.value.toFixed(2));
      if (ret.info[2] && "data" in ret.info[2])
        setEffCDWP(ret.info[2].data.value.toFixed(2));
      if (ret.info[3] && "data" in ret.info[3])
        setEffCT(ret.info[3].data.value.toFixed(2));
    }
  }

  function heatBalanceCB(ret) {
    if (ret.return === 100) {
      console.log(ret.info);
      var totalSamples = ret.info.length;
      var heatLoadArr_ = [0, 0, 0, 0, 0];
      for (var i = 0; i < totalSamples; i++) {
        if (parseFloat(ret.info[i].value) === 100.0) {
          heatLoadArr_[2] = heatLoadArr_[2] + 1;
        } else if (parseFloat(ret.info[i].value) < -10.0) {
          heatLoadArr_[0] = heatLoadArr_[0] + 1;
        } else if (
          parseFloat(ret.info[i].value) >= -10.0 &&
          parseFloat(ret.info[i].value) < -5.0
        ) {
          heatLoadArr_[1] = heatLoadArr_[1] + 1;
        } else if (
          parseFloat(ret.info[i].value) >= -5.0 &&
          parseFloat(ret.info[i].value) < 5.0
        ) {
          heatLoadArr_[2] = heatLoadArr_[2] + 1;
        } else if (
          parseFloat(ret.info[i].value) >= 5.0 &&
          parseFloat(ret.info[i].value) < 10.0
        ) {
          heatLoadArr_[3] = heatLoadArr_[3] + 1;
        } else if (parseFloat(ret.info[i].value) >= 10.0) {
          heatLoadArr_[4] = heatLoadArr_[4] + 1;
        }
      }

      for (var i = 0; i < 5; i++) {
        if (heatLoadArr_[i] !== 0) {
          heatLoadArr_[i] = (heatLoadArr_[i] * 100) / totalSamples;
          heatLoadArr_[i] = heatLoadArr_[i].toFixed(1);
        }
      }

      setHeatLoad(heatLoadArr_);
    }
  }

  function coolingLoadCB(ret) {
    if (ret.return === 100) {
      console.log(ret.info);
      var totalRT = 0;
      if (ret.info.chiller1Status === "active") {
        totalRT = totalRT + 600;
      }
      if (ret.info.chiller2Status === "active") {
        totalRT = totalRT + 800;
      }
      if (ret.info.chiller3Status === "active") {
        totalRT = totalRT + 1000;
      }

      //setRt(totalRT);
      setRt(ret.info.totalCoolingLoad.toFixed(1));
      setClPerc((ret.info.totalCoolingLoad * 100) / 950);

      setMuw(ret.info.makeUpWaterVol);

      if (ret.info.makeUpWaterVol <= 35) {
        setMuwCost(ret.info.makeUpWaterVol * 2.07);
      } else {
        var price = 0;
        price = 35 * 2.07;
        price = price + (ret.info.makeUpWaterVol - 35) * 2.28;
        setMuwCost(price);
      }
    }
  }

  useEffect(() => {
    setDateTime();
    currentTempHumidity(currentTempHumidityCB, {});
    dailyEnergyConsumption(dailyEnergyConsumptionCB, {});
    currentHeaderData(currentHeaderDataCB, {});
    efficiency(efficiencyCB, {});
    coolingLoad(coolingLoadCB, {});
    heatBalance(heatBalanceCB, {});
  }, []);

  useInterval(() => {
    currentTempHumidity(currentTempHumidityCB, {});
    dailyEnergyConsumption(dailyEnergyConsumptionCB, {});
    currentHeaderData(currentHeaderDataCB, {});
    efficiency(efficiencyCB, {});
    coolingLoad(coolingLoadCB, {});
    heatBalance(heatBalanceCB, {});
  }, refreshInterval);

  useInterval(() => {
    if (currBuilding === "TOWER BLOCK") {
      setCurrBuilding("PAVILLION BLOCK");
    } else {
      setCurrBuilding("TOWER BLOCK");
    }
  }, refreshInterval2);

  useInterval(() => {
    setDateTime();
  }, 5000);

  return (
    <div className="chiller thai">
      <div className="main-holder">
        <div className="row ">
          <div className="col-auto left-pane">
            <div className="row top-pane">
              <div className="col-12 col-sm-6 col-md-6 col-lg-auto logo">
                <img
                  src="images/logo-uob-thai.png"
                  className="thai"
                  style={{ display: "none" }}
                />
                <img
                  src="images/logo-uob-my.png"
                  className="my"
                  style={{ display: "none" }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-auto time-date-gp">
                <div className="time">{timeStr}</div>
                <div className="date">{dateStr}</div>
              </div>
              <div className="col-12 col-lg temp-hum-gp">
                <div className="row ">
                  <div className="col-auto col-lg-1" />
                  <div className="col temp-gp ">
                    <div>
                      <div className="value-gp">
                        <span
                          className="value value-xl"
                          style={{ fontSize: 55 }}
                        >
                          {temperature}
                        </span>{" "}
                        <span className="unit unit-l">°C</span>
                      </div>
                      <div className="txt-desc">
                        <span>Outdoor</span>
                        <span> Temperature</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-1" />
                  <div className="col hum-gp">
                    <div>
                      <div className="value-gp">
                        <span
                          className="value value-xl"
                          style={{ fontSize: 55 }}
                        >
                          {humidity}
                        </span>{" "}
                        <span className="unit unit-l">%</span>
                      </div>
                      <div className="txt-desc">
                        <span>Outdoor</span>
                        <span> Humidity</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row daily-plant-cons-gp">
              <div className="col-12">
                <div className="box">
                  <div className="row">
                    <div className="col-12 box-title">
                      Daily Plant Consumption
                    </div>
                    <div className="col-12 ">
                      <div className="row">
                        <div className="col-6 col-lg ch itm">
                          <div className="label">CH</div>
                          <div className="value-gp">
                            <span className="value value-m">
                              {numberWithCommas(chillerEnergy)}
                            </span>{" "}
                            <span className="unit unit-m">kWh</span>
                          </div>
                          <div className="progress-bar-gp">
                            <div className="fill" style={chillerEnergyPerc} />
                          </div>
                          <div
                            className="txt-desc thai"
                            style={{
                              display: "none"
                            }}
                          >
                            THB {(chillerEnergy * 0.365).toFixed(2)}
                          </div>
                          <div
                            className="txt-desc my"
                            style={{
                              display: "none"
                            }}
                          >
                            THB {(chillerEnergy * 0.365).toFixed(2)}
                          </div>
                        </div>
                        <div className="col-6 col-lg chwp itm">
                          <div className="label">CHWP</div>
                          <div className="value-gp">
                            <span className="value value-m">
                              {numberWithCommas(CHWPEnergy)}
                            </span>{" "}
                            <span className="unit unit-m">kWh</span>
                          </div>
                          <div className="progress-bar-gp">
                            <div className="fill" style={CHWPEnergyPerc} />
                          </div>
                          <div
                            className="txt-desc thai"
                            style={{
                              display: "none"
                            }}
                          >
                            THB {(CHWPEnergy * 0.365).toFixed(2)}
                          </div>
                          <div
                            className="txt-desc my"
                            style={{
                              display: "none"
                            }}
                          >
                            MYR {(CHWPEnergy * 0.365).toFixed(2)}
                          </div>
                        </div>
                        <div className="col-6 col-lg cdwp itm">
                          <div className="label">CDWP</div>
                          <div className="value-gp">
                            <span className="value value-m">
                              {numberWithCommas(CDWPEnergy)}
                            </span>{" "}
                            <span className="unit unit-m">kWh</span>
                          </div>
                          <div className="progress-bar-gp">
                            <div className="fill" style={CDWPEnergyPerc} />
                          </div>
                          <div
                            className="txt-desc thai"
                            style={{
                              display: "none"
                            }}
                          >
                            THB {(CDWPEnergy * 0.365).toFixed(2)}
                          </div>
                          <div
                            className="txt-desc my"
                            style={{
                              display: "none"
                            }}
                          >
                            MYR {(CDWPEnergy * 0.365).toFixed(2)}
                          </div>
                        </div>
                        <div className="col-6 col-lg ct itm">
                          <div className="label">CT</div>
                          <div className="value-gp">
                            <span className="value value-m">
                              {numberWithCommas(CTEnergy)}
                            </span>{" "}
                            <span className="unit unit-m">kWh</span>
                          </div>
                          <div className="progress-bar-gp">
                            <div className="fill" style={CTEnergyPerc} />
                          </div>
                          <div
                            className="txt-desc thai"
                            style={{
                              display: "none"
                            }}
                          >
                            THB {(CTEnergy * 0.365).toFixed(2)}
                          </div>
                          <div
                            className="txt-desc my"
                            style={{
                              display: "none"
                            }}
                          >
                            MYR {(CTEnergy * 0.365).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row total-sys-eff">
              <div className="col-12">
                <div className="box">
                  <div className="row">
                    <div className="col-12 box-title">
                      Total System Efficiency
                    </div>
                    <div className="col-12 box-sub-title">
                      Current {effTotal} IKW/RT (D = 0.55 IKW/RT)
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6 col-lg itm">
                          <div className="label">CH</div>
                          <div className="chart-gp" style={{ marginTop: -10 }}>
                            <Eff
                              value={effCH}
                              target={0.4485}
                              color={"#9517bf"}
                            />
                          </div>
                          <div
                            className="unit unit-m"
                            style={{ marginTop: -25 }}
                          >
                            ikW/RT
                          </div>
                          <div className="txt-desc">D = 0.4485 ikW/RT</div>
                        </div>
                        <div className="col-6 col-lg itm">
                          <div className="label" style={{ marginLeft: -60 }}>
                            CHWP
                          </div>
                          <div
                            className="chart-gp"
                            style={{ marginTop: -10, marginLeft: -60 }}
                          >
                            <Eff
                              value={effCHWP}
                              target={0.0465}
                              color={"#bf7317"}
                            />
                          </div>
                          <div
                            className="unit unit-m"
                            style={{ marginTop: -25, marginLeft: -60 }}
                          >
                            ikW/RT
                          </div>
                          <div className="txt-desc" style={{ marginLeft: -60 }}>
                            D = 0.0465 ikW/RT
                          </div>
                        </div>
                        <div className="col-6 col-lg itm">
                          <div className="label" style={{ marginLeft: -60 }}>
                            CDWP
                          </div>
                          <div
                            className="chart-gp"
                            style={{ marginTop: -10, marginLeft: -60 }}
                          >
                            <Eff
                              value={effCDWP}
                              target={0.048}
                              color={"#1dbf17"}
                            />
                          </div>
                          <div
                            className="unit unit-m"
                            style={{ marginTop: -25, marginLeft: -60 }}
                          >
                            ikW/RT
                          </div>
                          <div className="txt-desc" style={{ marginLeft: -60 }}>
                            D = 0.048 ikW/RT
                          </div>
                        </div>
                        <div className="col-6 col-lg itm">
                          <div className="label" style={{ marginLeft: -70 }}>
                            CT
                          </div>
                          <div
                            className="chart-gp"
                            style={{ marginTop: -10, marginLeft: -70 }}
                          >
                            <Eff
                              value={effCT}
                              target={0.007}
                              color={"#0e96f0"}
                            />
                          </div>
                          <div
                            className="unit unit-m"
                            style={{ marginTop: -25, marginLeft: -70 }}
                          >
                            ikW/RT
                          </div>
                          <div className="txt-desc" style={{ marginLeft: -70 }}>
                            D = 0.007 ikW/RT
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ marginLeft: 40, marginTop: -30 }}
                    >
                      <EffChart target={0.58} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto right-pane">
            <div className="row cooling-load-gp">
              <div className="col-12">
                <div className="box">
                  <div className="row">
                    <div className="col-12 box-title">
                      Cooling load{" "}
                      <span className="roboto">{clPerc.toFixed(2)}%</span>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md color-bar">
                          <div className="value-gp">
                            <span className="value value-xl">{rt}</span>{" "}
                            <span className="unit unit-l">RT</span>
                          </div>
                          <div
                            className="cooling-load-arrow"
                            style={{
                              marginLeft: clPerc + "%"
                            }}
                          >
                            <img src="images/cooling-load-arrow.png" />
                          </div>
                          <div className="cooling-load-bar" />
                          <div className="txt-desc">
                            <small>Operating Range - 80 - 1000</small>
                          </div>
                        </div>
                        <div className="col-auto col-md-1" />
                        <div className="col-12 col-md grey-bar">
                          <div>
                            <div className="grey-bar-gp-title">
                              Heat Balance Indicator (%) (9am - 6pm)
                            </div>
                            <div className="row no-gutters grey-bar-gp">
                              <div className="col-2">
                                <span>{heatLoad[0]}</span>
                              </div>
                              <div className="col-2">
                                <span>{heatLoad[1]}</span>
                              </div>
                              <div className="col-2">
                                <span>{heatLoad[2]}</span>
                              </div>
                              <div className="col-2">
                                <span>{heatLoad[3]}</span>
                              </div>
                              <div className="col-2">
                                <span>{heatLoad[4]}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row header-temp-gp">
              <div className="col-12">
                <div className="box">
                  <div className="row">
                    <div className="col-12 box-title">Header Temp</div>
                    <div
                      className="col-12 box-sub-title thai"
                      style={{ display: "none" }}
                    >
                      {currBuilding}
                    </div>
                    <div className="col-12 chiller-water itm-gp">
                      <div className="row">
                        <div className="col-12 col-md-5">
                          <div className="temp-round">
                            <div>
                              <span className="value-m">
                                {currBuilding == "TOWER BLOCK"
                                  ? (CHWRTemp1 - CHWSTemp1).toFixed(1)
                                  : (CHWRTemp2 - CHWSTemp2).toFixed(1)}
                              </span>
                              <span className="unit">°C</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md info-gp">
                          <div className="label">{"Chiller Water"}</div>
                          <div className="row ">
                            <div className="col-1 itm" />
                            <div className="col itm">
                              <div className="value-label">RETURN</div>
                              <div className="value-gp">
                                <span className="value-m">
                                  {currBuilding == "TOWER BLOCK"
                                    ? CHWRTemp1
                                    : CHWRTemp2}
                                </span>
                                <span className="unit">°C</span>
                              </div>
                              <div className="compare-value">
                                <span className="value-s">- 4</span>
                                <span className="unit unit-s">%</span>
                                <span>{/*PLACE LINE CHART*/}</span>
                              </div>
                              <div className="txt-desc">
                                <small>( 8 hrs changes )</small>
                              </div>
                            </div>
                            <div className="col-auto itm separator" />
                            <div className="col-1 itm" />
                            <div className="col itm">
                              <div className="value-label">SUPPLY</div>
                              <div className="value-gp">
                                <span className="value-m">
                                  {currBuilding == "TOWER BLOCK"
                                    ? CHWSTemp1
                                    : CHWSTemp2}
                                </span>
                                <span className="unit">°C</span>
                              </div>
                              <div className="compare-value">
                                <span className="value-s">+ 2</span>
                                <span className="unit unit-s">%</span>
                                <span>{/*PLACE LINE CHART*/}</span>
                              </div>
                              <div className="txt-desc">
                                <small>( 8 hrs changes )</small>
                              </div>
                            </div>
                            <div className="col-1 itm" />
                          </div>
                          <div className="value-label"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 condenser itm-gp">
                      <div className="row">
                        <div className="col-12 col-md-5">
                          <div className="temp-round">
                            <div>
                              <span className="value-m">
                                {(CDWRTemp - CDWSTemp).toFixed(1)}
                              </span>
                              <span className="unit">°C</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md info-gp">
                          <div className="label">Condenser Water</div>
                          <div className="row">
                            <div className="col-1 itm" />
                            <div className="col itm">
                              <div className="value-label">RETURN</div>
                              <div className="value-gp temp">
                                <span className="value-m">{CDWRTemp}</span>
                                <span className="unit">°C</span>
                              </div>
                              <div className="compare-value">
                                <span className="value-s">- 4</span>
                                <span className="unit unit-s">%</span>
                                <span>{/*PLACE LINE CHART*/}</span>
                              </div>
                              <div className="txt-desc">
                                <small>( 8 hrs changes )</small>
                              </div>
                            </div>
                            <div className="col-auto itm separator" />
                            <div className="col-1 itm" />
                            <div className="col itm">
                              <div className="value-label">SUPPLY</div>
                              <div className="value-gp">
                                <span className="value-m">{CDWSTemp}</span>
                                <span className="unit">°C</span>
                              </div>
                              <div className="compare-value">
                                <span className="value-s">+ 2</span>
                                <span className="unit unit-s">%</span>
                                <span>{/*PLACE LINE CHART*/}</span>
                              </div>
                              <div className="txt-desc">
                                <small>( 8 hrs changes )</small>
                              </div>
                            </div>
                            <div className="col-1 itm" />
                          </div>
                          <div className="value-label"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chiller;
