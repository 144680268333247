import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import SlaMiniGraph from "./slaMiniGraph";

function SlaGraph(props) {
  const imagePath = "image://../../images/hx-header-temp-fat.png";
  const [option, setOption] = useState({
    color: ["#bb0004", "#FFD48A"],
    legend: {
      data: ["pictorial element", "reference bar"]
    },
    xAxis: {
      data: [props.label],
      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        color: "#9d9e9f",
        padding: [3, 0, 0, 0]
      }
    },
    yAxis: {
      max: 20,
      splitLine: { show: true, interval: 0, lineStyle: { color: "#323436" } },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        interval: 0,
        color: "#969799"
      },
      min: 0,
      splitNumber: 10
    },
    grid: {
      top: 10,
      left: 22,
      bottom: 20
    },
    legend: {
      show: false
    },
    series: [
      {
        type: "pictorialBar",
        name: "pictorial element",
        symbol: imagePath,
        z: 10,
        data: [
          {
            name: "a1",
            value: 0,
            symbolPosition: "end",
            symbolSize: [
              "50%", // 50% of the width of reference bar.
              "0%" // 100% of the height of reference bar.
            ],
            label: {
              show: false,
              rotate: -90,
              formatter: "{b}",
              position: "inside",
              position: ["50%", "9.45%"],
              color: "green",
              z: 101
            }
          }
        ]
      }
    ]
  });

  function getOption() {
    const option_ = cloneDeep(option);
    if ("value" in props) {
      option_.series[0].data[0].value = props.value[1];
      option_.series[0].data[0].symbolSize[1] =
        ((props.value[1] - props.value[0]) * 100) / props.value[1] + "%";
    }
    return option_;
  }

  return (
    <div className="row">
      <div className="col-7">
        <div>
          <ReactEcharts
            option={getOption()}
            style={{
              height: "212px"
            }}
          />
        </div>
      </div>
      <div className="col-5">
        <div className="row">
          <div
            className="col-12"
            style={{
              fontSize: 11,
              color: "#b9baba",
              fontWeight: "bold",
              paddingLeft: "0",
              paddingRight: "5"
            }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />8 HOURS CHANGE
          </div>
          <div
            className="col-12"
            style={{
              paddingLeft: "0px",
              paddingRight: "5px"
            }}
          >
            <div className="row" style={{ paddingTop: "12px" }}>
              <div className="col-5" style={{ paddingRight: "0px" }}>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: "#fff"
                  }}
                >
                  {props.percentageSupply}
                </span>
                <span
                  style={{
                    fontSize: 11,
                    color: "#8a8c8e"
                  }}
                >
                  &nbsp;%
                </span>
              </div>
              <div
                className="col-7"
                style={{ paddingLeft: "8px", paddingRight: "0px" }}
              >
                {
                  <SlaMiniGraph
                    color={"#eb0963"}
                    value={props.miniGraphSupplyValue}
                  />
                }
              </div>
            </div>

            <div className="row" style={{ paddingTop: "12px" }}>
              <div className="col-5" style={{ paddingRight: "0px" }}>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: "#fff"
                  }}
                >
                  {props.percentageReturn}
                </span>
                <span
                  style={{
                    fontSize: 11,
                    color: "#8a8c8e"
                  }}
                >
                  &nbsp;%
                </span>
              </div>
              <div
                className="col-7"
                style={{ paddingLeft: "8px", paddingRight: "0px" }}
              >
                {
                  <SlaMiniGraph
                    color={"#18daf7"}
                    value={props.miniGraphReturnValue}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlaGraph;
