import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { dailyWater } from "../../api/water";

const days = ["S", "M", "T", "W", "T", "F", "S"];
const refreshInterval = 60000 * 5;

function WaterGraphHourly(props) {
  var echarts = require("echarts");
  const dataAxis = ["4 M", "5 T", "6 W", "7 T", "8 F", "9 S", "10 S"];
  const data = [0, 0, 0, 0, 0, 0, 0];

  function getBarColor(type) {
    if (type === "kwh") {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#92d70d" },
        { offset: 1, color: "#347b09" }
      ]);
    } else {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#e90b5f" },
        { offset: 1, color: "#830839" }
      ]);
    }
  }

  const [option, setOption] = useState({
    grid: {
      left: "1%",
      right: "10%",
      bottom: "0%",
      containLabel: true
    },
    legend: {
      show: false,
      top: "92%",
      left: "70%",
      icon: "roundRect",
      textStyle: {
        color: "#999",
        fontSize: 9
      },
      data: [
        {
          name: "15 DAYS REFERENCE"
        }
      ]
    },
    xAxis: {
      nameLocation: "middle",
      nameGap: 50,
      name: "",
      nameTextStyle: {
        color: "#b283ec",
        align: "center",
        position: "bottom",
        fontSize: 13
      },
      data: dataAxis,
      axisLabel: {
        interval: 0,
        formatter: function(value) {
          if (value == 0) {
            return "12AM";
          } else if (value > 12) {
            var t = value - 12;
            return t + "PM";
          } else if (value == 12) {
            return value + "PM";
          } else {
            return value + "AM";
          }
          return value;
        },
        color: "#ffffff",
        fontSize: "9"
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    },
    yAxis: {
      position: "left",
      nameLocation: "end",
      name: "VOLUME(m3)",
      nameTextStyle: {
        color: "#ffffff",
        align: "right",
        padding: [0, -60, 0, 0]
      },

      axisLine: {
        show: true
      },
      axisTick: {
        show: false
      },
      splitLine: { lineStyle: { color: "#323436", width: 1 } },
      axisLabel: {
        textStyle: {
          color: "#ffffff"
        }
      }
    },
    series: [
      {
        type: "bar",
        name: "Total",
        data: data,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "#3283a8"
          },
          {
            offset: 1,
            color: "#072533"
          }
        ])
      }
    ]
  });

  function dailyWaterCB(ret) {
    console.log(ret);
    if (ret.return === 100) {
      if (ret.info.length === 1) {
        var dataAxis_ = [];
        var total = [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ];

        var d = new Date();
        var h = d.getHours();
        for (var i = 0; i < 24; i++) {
          if (h < 0) {
            h = 23;
          }
          dataAxis_.push(h);
          h = h - 1;
        }

        dataAxis_.reverse();

        var index = 0;
        var start = dataAxis_[index];

        var data_ = ret.info[0];
        while (index < 24) {
          var str = "H" + start;
          total[index] = data_.perHour[str];
          index++;
          start = dataAxis_[index];
        }

        const option_ = cloneDeep(option);
        option_.xAxis.data = dataAxis_;
        //option_.xAxis.name = startStr + "a - b" + endStr;
        option_.series[0].data = total;
        setOption(option_);
      }
    }
  }

  var d = new Date();
  d.setDate(d.getDate());
  var curr_date = d.getDate();
  var curr_month = d.getMonth() + 1;
  var curr_year = d.getFullYear();
  var dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var timeStamp = new Date(dateString).getTime();

  d.setDate(d.getDate() - 14);
  curr_date = d.getDate();
  curr_month = d.getMonth() + 1;
  curr_year = d.getFullYear();
  dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var last14DaysTs = new Date(dateString).getTime();

  useEffect(() => {
    console.log("xxxxxxxxxxxxxxxxxxxx")
    dailyWater(dailyWaterCB, { ts: { $gte: timeStamp } });
  }, []);

  useInterval(() => {
    console.log("yyyyyyyyyyyyyyyyyyyyyyyy")
    dailyWater(dailyWaterCB, { ts: { $gte: timeStamp } });
  }, refreshInterval);

  return (
    <ReactEcharts
      option={option}
      style={{
        top: "100",
        width: 780,
        height: 400
      }}
    />
  );
}

export default WaterGraphHourly;
