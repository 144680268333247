import React, { useState, useParams } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./Navigation/PrivateRoute";
import QueryString from "query-string";

import Home from "./views/Home";
import Alert from "./views/Alert";
import Event from "./views/Event";
import Operation from "./views/Operation";
import Fault from "./views/Fault";
import Vav from "./views/Vav";
import Energy from "./views/Energy";
import Chiller from "./views/Chiller";
import Water from "./views/Water";
import Sla from "./views/Sla";
import BuildingInfo from "./views/BuildingInfo";
import BuildingInfo2 from "./views/BuildingInfo2";
import LeftWall from "./views/LeftWall";
import RightWall from "./views/RightWall";
import ReportChiller from "./views/ReportChiller";

import {
  AuthContext,
  getStoreAuthToken,
  setStoreAuthToken
} from "./context/auth";

function App() {
  let url = window.location.search;
  let { token } = QueryString.parse(url);
  console.log(token);
  //console.log(token);
  //const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const existingTokens = token; //getStoreAuthToken();
  const [authTokens, setAuthTokens] = useState(existingTokens);
  setStoreAuthToken(existingTokens);

  const setTokens = data => {
    setStoreAuthToken(data);
    setAuthTokens(data);
  };

  const getTokens = () => {
    let token = getStoreAuthToken();
    return token === "undefined" ? null : token;
  };

  const buildings = [
    {
      indexCounter: 0,
      index: "orq",
      buildingName: "ORQ"
    },
    {
      indexCounter: 1,
      index: "bfc",
      buildingName: "BFC"
    },
    {
      indexCounter: 2,
      index: "cbd",
      buildingName: "CBD"
    }
  ];

  const towers = [
    {
      towerIndex: "orq_nt",
      towerName: "NORTH TOWER",
      buildingIndex: "orq",
      buildingName: "ORQ"
    },
    {
      towerIndex: "orq_st",
      towerName: "SOUTH TOWER",
      buildingIndex: "orq",
      buildingName: "ORQ"
    },
    {
      towerIndex: "bfc_1",
      towerName: "TOWER 1",
      buildingIndex: "bfc",
      buildingName: "BFC"
    },
    {
      towerIndex: "bfc_2",
      towerName: "TOWER 2",
      buildingIndex: "bfc",
      buildingName: "BFC"
    },
    {
      towerIndex: "cbd_3",
      towerName: "TOWER 3",
      buildingIndex: "cbd",
      buildingName: "CBD"
    }
  ];

  return (
    <AuthContext.Provider
      value={{
        authTokens,
        setAuthTokens: setTokens,
        getAuthTokens: getTokens
      }}
    >
      <Router>
        <div>
          <Switch>
            <Route
              exact
              path="/alert"
              component={props => <Alert {...props} buildings={buildings} />}
            />
            <Route
              exact
              path="/fault"
              component={props => <Fault {...props} buildings={buildings} />}
            />
            <Route
              exact
              path="/operation"
              component={props => (
                <Operation {...props} buildings={buildings} />
              )}
            />
            <Route
              exact
              path="/energy"
              component={props => <Energy {...props} buildings={buildings} />}
            />
            <Route
              exact
              path="/chiller"
              component={props => <Chiller {...props} buildings={buildings} />}
            />
            <Route
              exact
              path="/reportChiller"
              component={props => (
                <ReportChiller {...props} buildings={buildings} />
              )}
            />
            <Route
              exact
              path="/water"
              component={props => <Water {...props} buildings={buildings} />}
            />
            <Route
              exact
              path="/sla"
              component={props => <Sla {...props} buildings={buildings} />}
            />
            <Route
              exact
              path="/vav"
              component={props => <Vav {...props} towers={towers} />}
            />
            <Route
              exact
              path="/event"
              component={props => <Event {...props} buildings={buildings} />}
            />
            <Route
              exact
              path="/buildingInfo2"
              component={props => (
                <BuildingInfo2 {...props} buildings={buildings} />
              )}
            />
            <Route
              exact
              path="/buildingInfo"
              component={props => (
                <BuildingInfo {...props} buildings={buildings} />
              )}
            />
            <Route
              exact
              path="/leftWall"
              component={props => (
                <LeftWall {...props} buildings={buildings} towers={towers} />
              )}
            />
            <Route
              exact
              path="/rightWall"
              component={props => (
                <RightWall {...props} buildings={buildings} towers={towers} />
              )}
            />

            <Route exact path="/" component={Home} />
            <Route path="*" render={() => <p>Error</p>} />
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
