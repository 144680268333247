import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { reportHeatBalance } from "../../api/chiller";

function HeatBalanceReport(props) {
  const [val, setVal] = useState(0);
  const [data, setData] = useState(null);
  const [option, setOption] = useState(null);

  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  function reportHeatBalanceCB(ret) {
    if (ret.return === 100) {
      console.log(ret.info);
      var data_ = {};
      let arr = [];

      data_.totalCount = ret.info.heatbalance.length;
      data_.above = 0;
      data_.below = 0;
      data_.within = 0;
      data_.perc = 0;
      data_.eff = ret.info.chillerEff.toFixed(2);
      data_.kwh = ret.info.chillerEnergy.toFixed(2);

      for (var i = 0; i < ret.info.heatbalance.length; i++) {
        arr[i] = [];
        if (ret.info.heatbalance[i].value > 100) {
          ret.info.heatbalance[i].value = 100;
        } else if (ret.info.heatbalance[i].value < -100) {
          ret.info.heatbalance[i].value = -100;
        }
        arr[i][1] = parseFloat(ret.info.heatbalance[i].value.toFixed(2));
        arr[i][0] = ret.info.heatbalance[i].ts * 1000;

        if (ret.info.heatbalance[i].value > 5) {
          data_.above++;
        } else if (ret.info.heatbalance[i].value < -5) {
          data_.below++;
        } else {
          data_.within++;
        }
      }

      data_.perc = ((data_.within * 100) / data_.totalCount).toFixed(2);

      setData(data_);
      //console.log(arr);
      setOption({
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text: "",
          subtextStyle: {
            color: "#42b3f5"
          },
          left: "center",
          top: "auto",
          textStyle: {
            fontSize: 30
          },
          subtextStyle: {
            fontSize: 20
          }
        },
        legend: { bottom: 0 },
        grid: {
          top: "15%",
          left: "8%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: [
          {
            type: "time",
            name: "time",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [15, 0, 0, 0],
              color: "#42b3f5"
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "%",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [0, 0, 20, 0],
              color: "#42b3f5"
            }
          }
        ],
        series: [
          {
            color: "#348feb",
            symbolSize: 2,
            type: "line",
            data: arr,
            markLine: {
              lineStyle: {
                color: "red"
              },
              data: [
                {
                  yAxis: 5
                },
                {
                  yAxis: -5
                }
              ]
            }
          }
        ]
      });
    }
  }

  useEffect(() => {
    reportHeatBalance(reportHeatBalanceCB, {
      start: props.start,
      end: props.end
    });
  }, []);

  if (data !== null && option !== null) {
    return (
      <div>
        <div className="row" style={{}}>
          <div
            className="col-12"
            style={{
              paddingTop: 50,
              color: "black"
            }}
          >
            <h2 style={{ color: "black" }}>Summary of Heat Balance</h2>
          </div>
        </div>
        <ReactEcharts
          option={option}
          style={{
            height: "500px",
            width: "100%"
          }}
        />

        <table
          className="table table-bordered"
          style={{
            color: "black",
            fontSize: 20,
            width: "100%"
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Quantity</th>
              <th>Unit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Sum of total electrical energy used</th>
              <td>{data.kwh}</td>
              <td>kwh</td>
            </tr>
            <tr>
              <th>Chiller Plant Efficiency</th>
              <td>{data.eff}</td>
              <td>kw/RT</td>
            </tr>
            <tr>
              <th>Total Heat Balance Data Count</th>
              <td>{data.totalCount}</td>
              <td>-</td>
            </tr>
            <tr>
              <th>{"Data Count > +5% error"}</th>
              <td>{data.above}</td>
              <td>-</td>
            </tr>
            <tr>
              <th>{"Data Count < -5% error"}</th>
              <td>{data.below}</td>
              <td>-</td>
            </tr>
            <tr>
              <th>{"Data Count within +-5% error"}</th>
              <td>{data.within}</td>
              <td>-</td>
            </tr>
            <tr>
              <th>{"% Heat Balance within +-5% error"}</th>
              <td>{data.perc}</td>
              <td>%</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>aaaaaaaaaa</div>;
  }
}

export default HeatBalanceReport;
