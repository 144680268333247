import React, { useState, useEffect } from 'react'
import ReactEcharts from 'echarts-for-react'
import { useAuth } from '../../context/auth'
import { Redirect } from 'react-router-dom'
import {
  groupBy,
  useInterval,
  numberWithCommas,
  getCurrentSemiMonthYearName,
  getSemiMonthFromMonth
} from '../../Utils'
import cloneDeep from 'lodash.clonedeep'
import {
  energyLastHour,
  energyLastLastHour,
  todayConsumptionInfo,
  dailyEnergy
} from '../../api/energy'
import {
  currentTempHumidity,
  dailyEnergyConsumption,
  currentHeaderData,
  efficiency
} from '../../api/chiller'
import { dailyWater } from '../../api/water'
import WaterGraphSegment from './waterGraphSegment'
import WaterGraphHourly from './waterGraphHourly'

function Water (props) {
  const refreshInterval = 1000 * 60 * 5

  const [waterAvg, setWaterAvg] = useState(0)
  const [waterTotal, setWaterTotal] = useState(0)
  const [coldTotal, setColdTotal] = useState(0)
  const [rainTotal, setRainTotal] = useState(0)
  const [flushTotal, setFlushTotal] = useState(0)
  const [CTTotal, setCTTotal] = useState(0)

  const [waterSize, setWaterSize] = useState(0)
  const [coldSize, setColdSize] = useState(0)
  const [rainSize, setRainSize] = useState(0)
  const [flushSize, setFlushSize] = useState(0)
  const [CTSize, setCTSize] = useState(0)

  const [peakWaterValue, setPeakWaterValue] = useState(0)
  const [peakWaterTime, setPeakWaterTime] = useState(0)
  const [peakWaterTimeAMPM, setPeakWaterTimeAMPM] = useState('AM')

  const [effTotal, setEffTotal] = useState(0)
  const [effCH, setEffCH] = useState(0)
  const [effCHWP, setEffCHWP] = useState(0)
  const [effCDWP, setEffCDWP] = useState(0)
  const [effCT, setEffCT] = useState(0)

  const [chillerLastHour, setChillerLastHour] = useState(0)
  const [MVLastHour, setMVLastHour] = useState(0)
  const [liftLastHour, setLiftLastHour] = useState(0)
  const [PSLastHour, setPSLastHour] = useState(0)
  const [lightingLastHour, setLightingLastHour] = useState(0)
  const [tenantLastHour, setTenantLastHour] = useState(0)

  const [chillerLastHourpm2, setChillerLastHourpm2] = useState(0)
  const [MVLastHourpm2, setMVLastHourpm2] = useState(0)
  const [liftLastHourpm2, setLiftLastHourpm2] = useState(0)
  const [PSLastHourpm2, setPSLastHourpm2] = useState(0)
  const [lightingLastHourpm2, setLightingLastHourpm2] = useState(0)
  const [tenantLastHourpm2, setTenantLastHourpm2] = useState(0)

  const [grossFloorArea, setGrossFloorArea] = useState(58665.6)
  const [buildingIndex, setBuildingIndex] = useState(0)
  const [totalCarbonEmission, setTotalCarbonEmission] = useState(0)

  const [curr_date, set_curr_date] = useState('')
  const [curr_month, set_curr_month] = useState('')
  const [curr_year, set_curr_year] = useState('')
  const [last_7_curr_date, set_last_7_curr_date] = useState('')
  const [last_7_curr_month, set_last_7_curr_month] = useState('')
  const [last_7_curr_year, set_last_7_curr_year] = useState('')

  function getTotalEnergy (ret, mode) {
    console.log(ret)
    for (var i = 0; i < ret.info.length; i++) {
      var obj = ret.info[i]

      if (obj.type === 'MV') {
        var totalEnergy = 0
        var dataArr = obj.data
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j]
          var multiplier = 1000
          if (data.units === 'megawatt-hours') {
            multiplier = multiplier * 1000
          }
          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier
        }

        totalEnergy = Math.round(totalEnergy / 1000)

        if (mode === 0) {
          setMVLastHour(totalEnergy)
          setMVLastHourpm2(((totalEnergy * 1000) / grossFloorArea).toFixed(2))
        }
      }

      if (obj.type === 'chiller') {
        var totalEnergy = 0
        var dataArr = obj.data
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j]
          var multiplier = 1000
          if (data.units === 'megawatt-hours') {
            multiplier = multiplier * 1000
          }

          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier
        }
        totalEnergy = Math.round(totalEnergy / 1000)

        if (mode === 0) {
          setChillerLastHour(totalEnergy)
          setChillerLastHourpm2(
            ((totalEnergy * 1000) / grossFloorArea).toFixed(2)
          )
        }
      }

      if (obj.type === 'lift') {
        var totalEnergy = 0
        var dataArr = obj.data
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j]
          var multiplier = 1000
          if (data.units === 'megawatt-hours') {
            multiplier = multiplier * 1000
          }
          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier
        }
        totalEnergy = Math.round(totalEnergy / 1000)
        if (mode === 0) {
          setLiftLastHour(totalEnergy)
          setLiftLastHourpm2(
            ((totalEnergy * 1000) / grossFloorArea).toFixed(2)
          )
        }
      }

      if (obj.type === 'PS') {
        var totalEnergy = 0
        var dataArr = obj.data
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j]
          var multiplier = 1000
          if (data.units === 'megawatt-hours') {
            multiplier = multiplier * 1000
          }
          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier
        }
        totalEnergy = Math.round(totalEnergy / 1000)
        if (mode === 0) {
          setPSLastHour(totalEnergy)
          setPSLastHourpm2(((totalEnergy * 1000) / grossFloorArea).toFixed(2))
        }
      }

      if (obj.type === 'lighting') {
        var totalEnergy = 0
        var dataArr = obj.data
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j]
          var multiplier = 1000
          if (data.units === 'megawatt-hours') {
            multiplier = multiplier * 1000
          }
          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier
        }
        totalEnergy = Math.round(totalEnergy / 1000)
        if (mode === 0) {
          setLightingLastHour(totalEnergy)
          setLightingLastHourpm2(
            ((totalEnergy * 1000) / grossFloorArea).toFixed(2)
          )
        }
      }
    }
  }

  function energyLastHourCB (ret, index) {
    if (ret.return === 100) {
      getTotalEnergy(ret, 0)
    }
  }

  function efficiencyCB (ret) {
    if (ret.return === 100) {
      if (ret.info[4] && 'data' in ret.info[4]) setEffTotal(ret.info[4].data.value.toFixed(2))
      if (ret.info[0] && 'data' in ret.info[0]) setEffCH(ret.info[0].data.value.toFixed(2))
      if (ret.info[1] && 'data' in ret.info[1]) setEffCHWP(ret.info[1].data.value.toFixed(2))
      if (ret.info[2] && 'data' in ret.info[2]) setEffCDWP(ret.info[2].data.value.toFixed(2))
      if (ret.info[3] && 'data' in ret.info[3]) setEffCT(ret.info[3].data.value.toFixed(2))
    }
  }

  function dailyWaterCB (ret) {
    if (ret.return === 100) {
      setWaterTotal(ret.info[0].total)
      setWaterAvg(ret.info[0].avg)
      var max = 0
      var maxHour = 0
      var i = 0
      for (var key in ret.info[0].perHour) {
        if (ret.info[0].perHour[key] > max) {
          max = ret.info[0].perHour[key]
          maxHour = i
          console.log(maxHour)
        }
        i = i + 1
      }
      setPeakWaterValue(max.toFixed(2))
      if (maxHour < 12) {
        setPeakWaterTime(maxHour + ':00')
        setPeakWaterTimeAMPM('AM')
      } else if (maxHour == 0) {
        setPeakWaterTime('12:00')
        setPeakWaterTimeAMPM('AM')
      } else {
        setPeakWaterTime(maxHour - 12 + ':00')
        setPeakWaterTimeAMPM('PM')
      }

      var temp = 0

      setColdTotal(ret.info[0].cold + ret.info[0].drinking)
      temp =
        (ret.info[0].cold + ret.info[0].drinking) / (ret.info[0].total / 4)
      if (temp > 1) temp = 1.0
      if (temp < 0.4) temp = 0.4
      setColdSize(parseFloat(temp.toFixed(2)))

      setRainTotal(ret.info[0].rain)
      temp = ret.info[0].rain / (ret.info[0].total / 4)
      if (temp > 1) temp = 1
      if (temp < 0.4) temp = 0.4
      setRainSize(parseFloat(temp.toFixed(2)))

      setFlushTotal(ret.info[0].flush)
      temp = ret.info[0].flush / (ret.info[0].total / 4)
      if (temp > 1) temp = 1
      if (temp < 0.4) temp = 0.4
      setFlushSize(parseFloat(temp.toFixed(2)))

      setCTTotal(ret.info[0].CT)
      temp = ret.info[0].CT / (ret.info[0].total / 4)
      if (temp > 1) temp = 1
      if (temp < 0.4) temp = 0.4
      setCTSize(parseFloat(temp.toFixed(2)))
    }
  }

  function dailyEnergyCB (ret, index) {
    if (ret.return === 100 && ret.info.length) {
      setTotalCarbonEmission(((ret.info[0].total/1000+10)*0.4977).toFixed(2));
    }
  }

  function todayConsumptionInfoCB (ret, index) {
    if (ret.return === 100) {
      console.log(ret.info)
      if ('currentHour' in ret.info) {
        setBuildingIndex(
          ((ret.info['currentHour'] * 1000) / grossFloorArea).toFixed(2)
        )
      }
    }
  }

  useInterval(() => {
    var d = new Date()
    d.setDate(d.getDate())
    var curr_date_ = d.getDate()
    var curr_month_ = d.getMonth() + 1
    var curr_year_ = d.getFullYear()
    var dateString =
      curr_month_ + '/' + curr_date_ + '/' + curr_year_ + ', 00:00:00 AM UTC+7'
    var timeStamp = Math.floor(new Date(dateString).getTime() / 1000)

    var d = new Date()
    d.setDate(d.getDate() - 6)
    var last_7_curr_date_ = d.getDate()
    var last_7_curr_month_ = d.getMonth()
    var last_7_curr_year_ = d.getFullYear()

    set_curr_date(curr_date_)
    set_curr_month(curr_month_)
    set_curr_year(curr_year_)
    set_last_7_curr_date(last_7_curr_date_)
    set_last_7_curr_month(last_7_curr_month_)
    set_last_7_curr_year(last_7_curr_year_)

    todayConsumptionInfo(todayConsumptionInfoCB, {})
    dailyWater(dailyWaterCB, { ts: { $gte: timeStamp } })
    dailyEnergy(dailyEnergyCB, { ts: { $gte: timeStamp } })
    energyLastHour(energyLastHourCB, {})
    efficiency(efficiencyCB, {})
  }, refreshInterval)

  useEffect(() => {
    var d = new Date()
    d.setDate(d.getDate())
    var curr_date_ = d.getDate()
    var curr_month_ = d.getMonth() + 1
    var curr_year_ = d.getFullYear()
    var dateString =
      curr_month_ + '/' + curr_date_ + '/' + curr_year_ + ', 00:00:00 AM UTC+7'
    var timeStamp = Math.floor(new Date(dateString).getTime() / 1000)

    var d = new Date()
    d.setDate(d.getDate() - 6)
    var last_7_curr_date_ = d.getDate()
    var last_7_curr_month_ = d.getMonth()
    var last_7_curr_year_ = d.getFullYear()

    set_curr_date(curr_date_)
    set_curr_month(curr_month_)
    set_curr_year(curr_year_)
    set_last_7_curr_date(last_7_curr_date_)
    set_last_7_curr_month(last_7_curr_month_)
    set_last_7_curr_year(last_7_curr_year_)

    todayConsumptionInfo(todayConsumptionInfoCB, {})
    dailyWater(dailyWaterCB, { ts: { $gte: timeStamp } })
    dailyEnergy(dailyEnergyCB, { ts: { $gte: timeStamp } })
    energyLastHour(energyLastHourCB, {})
    efficiency(efficiencyCB, {})
  }, [])

  return (
    <div className='water'>
      <div className='main-holder'>
        <div className='row no-gutters top-pane building-info'>
          <div className='col-12 col-lg-auto'>
            <div className='row no-gutters building-gp'>
              <div className='col-6'>
                <img src='images/top-icon-building.png' />
              </div>
              <div className='col-6 title'>Building Info</div>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-2 d-block d-md-none d-lg-block itm-1 itm extra-height'>
            <div className='box-reflect'>
              <div className='row no-gutters box-gp'>
                <div className='col-12 align-self-center'>
                  <div className='label'>Building Energy Index</div>
                  <div className='value value-m'>
                    {numberWithCommas(buildingIndex)}
                  </div>
                  <div className='txt-desc'>
                    kWh/m<sup>2</sup>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12  col-lg'>
            <div className='row itm-gp no-gutters'>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>Lighting Energy Index</div>
                      <div className='value value-m'>{lightingLastHourpm2}</div>
                      <div className='txt-desc'>
                        W/m<sup>2</sup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>Total Carbon Emission</div>
                      <div className='value value-m'>{numberWithCommas(totalCarbonEmission)}</div>
                      <div className='txt-desc'>
                        kgCO<sup>2</sup>kWh
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>Max Demand-ACMV</div>
                      <div className='value value-m'>-</div>
                      <div className='txt-desc'>
                        kWh/m<sup>3</sup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>VAV</div>
                      <div className='value value-m'>-</div>
                      <div className='txt-desc'>kWh</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>MV Fan</div>
                      <div className='value value-m red'>-</div>
                      <div className='txt-desc'>kWh</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row itm-gp no-gutters'>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>Tenant</div>
                      <div className='value value-m'>
                        {numberWithCommas(tenantLastHour)}
                      </div>
                      <div className='txt-desc'>kWh</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>P &amp; S</div>
                      <div className='value value-m'>
                        {numberWithCommas(PSLastHour)}
                      </div>
                      <div className='txt-desc'>kWh</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>Lift &amp; Escalator</div>
                      <div className='value value-m'>
                        {numberWithCommas(liftLastHour)}
                      </div>
                      <div className='txt-desc'>kWh</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>Chiller</div>
                      <div className='value value-m'>
                        {numberWithCommas(effTotal)}
                      </div>
                      <div className='txt-desc'>iKw/Rt</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg itm'>
                <div className='box-reflect'>
                  <div className='row box-gp'>
                    <div className='col-12'>
                      <div className='label'>Lighting</div>
                      <div className='value value-m orange'>
                        {numberWithCommas(lightingLastHour)}
                      </div>
                      <div className='txt-desc'>kWh</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row bottom-pane no-gutters'>
          <div className='col-12 col-lg-3 water-consumption-byday-gp'>
            <div className='box'>
              <div className='row no-gutters'>
                <div className='col-12 box-title'>
                  Total Water Consumed By Day
                </div>
                <div className='col-12 value-gp'>
                  <span className='value-xl'>
                    {numberWithCommas(waterTotal)}
                  </span>
                  <span className='unit unit-l'>
                    m<sup>3</sup>
                  </span>
                </div>
                <div className='col-12 col-md-6'>
                  <div
                    className='circle water'
                    style={{ transform: 'scale(' + coldSize + ')' }}
                  >
                    <div className='label'>
                      <span>Cold</span> <span>water</span>
                    </div>
                    <div className='value value-l'>
                      {numberWithCommas(coldTotal)}
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div
                    className='circle rain'
                    style={{ transform: 'scale(' + rainSize + ')' }}
                  >
                    <div className='label'>
                      <span>Rain</span>
                      <span>Tower</span>{' '}
                    </div>
                    <div className='value value-l'>
                      {numberWithCommas(rainTotal)}
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div
                    className='circle flushing'
                    style={{ transform: 'scale(' + flushSize + ')' }}
                  >
                    <div className='label'>
                      <span>Flushing</span>
                    </div>
                    <div className='value value-l'>
                      {numberWithCommas(flushTotal)}
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div
                    className='circle cooling'
                    style={{ transform: 'scale(' + CTSize + ')' }}
                  >
                    <div className='label'>
                      <span>Cooling</span>
                      <span>Tower</span>{' '}
                    </div>
                    <div className='value value-l'>
                      {numberWithCommas(CTTotal)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-3 seven-day-water-consumption-gp'>
            <div className='box'>
              <div className='row'>
                <div className='col-12 box-title'>
                  {' '}
                  Water consumption for last <span className='roboto'>
                    7
                  </span>{' '}
                  days
                </div>
                <div className='col-12 graph-holder' style={{ paddingTop: 50 }}>
                  <WaterGraphSegment />
                </div>
                <div className='col-12 x-title'>
                  {last_7_curr_date}{' '}
                  {getSemiMonthFromMonth(0, last_7_curr_month)} - {curr_date}{' '}
                  {getSemiMonthFromMonth(0, curr_month - 1)}
                </div>
                <div className='col-12 legend-gp'>
                  <div className='row itm-gp mb-2'>
                    <div className='col-auto icon'>
                      <img src='images/legend-target-line.png' />
                    </div>
                    <div className='col-auto legent-txt'>
                      Target Value for electrical Consumption
                    </div>
                  </div>
                  <div className='row itm-gp'>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-pink.png' />
                        </div>
                        <div className='col-auto legent-txt'>Cold Water</div>
                      </div>
                    </div>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-orange.png' />
                        </div>
                        <div className='col-auto legent-txt'>Rain Tower</div>
                      </div>
                    </div>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-green.png' />
                        </div>
                        <div className='col-auto legent-txt'>Flushing</div>
                      </div>
                    </div>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-blue.png' />
                        </div>
                        <div className='col-auto legent-txt'>Cooling Tower</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg water-consumption-equ-gp'>
            <div className='box'>
              <div className='row'>
                <div className='col' />
                <div className='col-12 box-title'>
                  Daily Water consumption
                </div>
                <div className='col-12 graph-holder' style={{ paddingTop: 50 }}>
                  <WaterGraphHourly />
                </div>
                <div className='col-12'>
                  <div className='row hightlight-gp'>
                    <div className='col-4 col-lg itm'>
                      <div className='itm-gp'>
                        <div className='label'>Peak Value</div>
                        <div className='value-gp'>
                          <span className='value-m'>
                            {numberWithCommas(peakWaterValue)}
                          </span>
                          <span className='unit'>
                            m<sup>3</sup>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='col-4 col-lg itm'>
                      <div className='itm-gp'>
                        <div className='label'>Peak Time</div>
                        <div className='value-gp'>
                          <span className='value-m'>{peakWaterTime}</span>
                          <span className='unit'>{peakWaterTimeAMPM}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-4 col-lg itm'>
                      <div className='itm-gp'>
                        <div className='label'>Day Average</div>
                        <div className='value-gp'>
                          <span className='value-m'>
                            {numberWithCommas(waterAvg.toFixed(2))}
                          </span>
                          <span className='unit'>
                            m<sup>3</sup>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 legend-gp' style={{display:"none"}}>
                  <div className='row itm-gp'>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-pink.png' />
                        </div>
                        <div className='col-auto legent-txt'>Cold Water</div>
                      </div>
                    </div>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-orange.png' />
                        </div>
                        <div className='col-auto legent-txt'>Rain Tower</div>
                      </div>
                    </div>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-green.png' />
                        </div>
                        <div className='col-auto legent-txt'>Flushing</div>
                      </div>
                    </div>
                    <div className='col-auto itm '>
                      <div className='row'>
                        <div className='col-auto icon'>
                          <img src='images/legend-dot-blue.png' />
                        </div>
                        <div className='col-auto legent-txt'>Cooling Tower</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12' />
      </div>
    </div>
  )
}

export default Water
