import React, { useState, useEffect } from "react";
import { useInterval } from "../../Utils";
import { operationGetMaintainanceSchedule } from "../../api/operation";

function MaintenanceSchedule(props) {
  const refreshInterval = 60000;
  const [oqrData, setOqrData] = useState("/");
  const [bfcData, setBfcData] = useState("/");
  const [cbdData, setCbdData] = useState("/");

  useEffect(
    () => {
      for (var key in props.buildingData) {
        if (key === "ORQ") {
          setOqrData(props.buildingData[key][props.queryName]);
        } else if (key === "BFC") {
          setBfcData(props.buildingData[key][props.queryName]);
        } else if (key === "CBD") {
          setCbdData(props.buildingData[key][props.queryName]);
        }
      }
    },
    [props]
  );

  return (
    <div className="row no-gutters widget-table-row">
      <div className="col-3 side-header ">
        <div className="row no-gutters side-header-gp">
          <div className="col-4">
            <img src={props.type.iconPath} className="icon" />
          </div>
          <div className="col-1" />
          <div className="col-7">{props.type.name}</div>
        </div>
      </div>
      <div className="col-3">
        <div className="row no-gutters value-gp">
          <div className="col-12 value-l">{oqrData}</div>
        </div>
      </div>
      <div className="col-3">
        <div className="row no-gutters value-gp">
          <div className="col-12 value-l">{bfcData}</div>
        </div>
      </div>
      <div className="col-3">
        <div className="row no-gutters value-gp">
          <div className="col-12 value-l">{cbdData}</div>
        </div>
      </div>
    </div>
  );
}

export default MaintenanceSchedule;
