import React from "react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import FaultTrend from "./faultTrend";
import TopFault from "./topFault";
import CurrentFaultTypes from "./currentFaultTypes";
import ResolvedCase from "./resolvedCase";

function Fault(props) {
  const { getAuthTokens } = useAuth();

  const mainDiv = (
    <div className="fault has-subtitle ">
      <div className="main-container">
        <div className="subtitle-gp">
          <span className="line" />
          <span>
            <div>
              <img src="images/subtitle-fault-icon.png" />
            </div>
            <div style={{ position: "relative" }}>
              <div className="subtitle">Fault</div>
            </div>
          </span>
          <span className="line" />
        </div>
        <div className="pane1">
          <div className="pane-holder">
            <div className="row no-gutters top-fault">
              <div className="col-12">
                <div className="widget row no-gutters border1 long-box">
                  <div className="col-auto">
                    <div className="widget-header row no-gutters">
                      <div className="col-12">
                        <img src="images/icon-top-alert.png" className="icon" />
                      </div>
                      <div className="col-12 widget-subtitle ">
                        Top faults <br /> Last 30 days
                      </div>
                    </div>
                  </div>
                  {props.buildings.map(building => (
                    <TopFault
                      key={building.index}
                      index={building.index}
                      buildingName={building.buildingName}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="row no-gutters curr-fault">
              <div className="col-12">
                <div className="widget-tran row no-gutters">
                  <div className="col-12">
                    <div className="widget-header row no-gutters">
                      <div className="col-12">
                        <span className="widget-subtitle">Current Fault</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <CurrentFaultTypes buildings={props.buildings} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pane2">
          <div className="pane-holder">
            <div className="widget row no-gutters">
              <div className="col-12" />
              <div className="col-12">
                {props.buildings.map(building => (
                  <div key={building.index} className="row no-gutters itm-gp">
                    <div className="chart-gp ui-col">
                      {(() => {
                        if (building.index === "orq") {
                          return (
                            <div className="widget-header row no-gutters">
                              <div className="widget-subtitle col-12">
                                Fault Trend - Last 30 days
                              </div>
                            </div>
                          );
                        }
                      })()}
                      <div className="row no-gutters">
                        <div
                          className={
                            "widget-title col-12 building-" + building.index
                          }
                        >
                          {building.buildingName}
                        </div>
                        <div className="col-12">
                          <FaultTrend
                            index={building.index}
                            key={building.index}
                            buildingName={building.buildingName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="side-gp ui-col">
                      <div className="side-holder row">
                        <div className="col-12 align-self-center">
                          <div className="row no-gutters">
                            <div className="col-12 title-s">Resolved Case</div>
                          </div>
                          <ResolvedCase
                            key={building.index}
                            index={building.index}
                            buildingName={building.buildingName}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (getAuthTokens() == null) {
    const referer = "/404";
    return <Redirect to={referer} />;
  } else return <div> {mainDiv} </div>;
}

export default Fault;
