import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { energyDailyKwh } from "../../api/energy";
import { energyDailyRth } from "../../api/energy";
import { getSemiMonth } from "../../Utils";
import { dailyEnergy } from "../../api/energy";

const days = ["S", "M", "T", "W", "T", "F", "S"];
const refreshInterval = 60000 * 5;

function EnergyGraph(props) {
  var echarts = require("echarts");
  const dataAxis = [
    "26 S",
    "27 M",
    "28 T",
    "29 W",
    "30 T",
    "1 F",
    "2 S",
    "3 S",
    "4 M",
    "5 T",
    "6 W",
    "7 T",
    "8 F",
    "9 S",
    "10 S"
  ];
  const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const yMax = 500;

  function getShadowColor(type) {
    if (type === "kwh") {
      return "rgba(18, 141, 102, 0.53)";
    } else {
      return "rgba(132, 7, 57, 0.53)";
    }
  }

  function getBarColor(type) {
    if (type === "kwh") {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#92d70d" },
        { offset: 1, color: "#347b09" }
      ]);
    } else {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#e90b5f" },
        { offset: 1, color: "#830839" }
      ]);
    }
  }

  function getYAxisTitle(type) {
    if (type === "kwh") {
      return "kWh";
    } else {
      return "RTh('000)";
    }
  }

  function getTitle(type, buildingName) {
    if (buildingName === "ORQ") {
      if (type === "kwh") {
        return "DAILY ELECTRICAL CONSUMPTION";
      } else {
        return "DAILY RTh CONSUMPTION";
      }
    } else {
      return "";
    }
  }

  const [option, setOption] = useState({
    //color: [getLineColor(props.type)],
    /*title: {
      text: getTitle(props.type, props.buildingName),
      left: "center",
      textStyle: {
        color: "#999",
        fontSize: 15
      }
  },*/
    legend: {
      show: false,
      top: "92%",
      left: "70%",
      icon: "roundRect",
      textStyle: {
        color: "#999",
        fontSize: 9
      },
      data: [
        {
          name: "15 DAYS REFERENCE"
        }
      ]
    },
    xAxis: {
      nameLocation: "middle",
      nameGap: 50,
      name: "",
      nameTextStyle: {
        color: "#b283ec",
        align: "center",
        position: "bottom",
        fontSize: 13
      },
      data: dataAxis,
      axisLabel: {
        interval: 0,
        formatter: function(value) {
          var res = value.split(" ");
          if (res[1] === "S")
            return "{date|" + res[0] + "}\n" + "{weekend|" + res[1] + "}";
          else return "{date|" + res[0] + "}\n" + "{weekday|" + res[1] + "}";
        },
        rich: {
          date: {
            align: "center",
            color: "#ffffff"
          },
          weekday: {
            lineHeight: 15,
            align: "center",
            color: "#999999"
          },
          weekend: {
            lineHeight: 15,
            align: "center",
            color: "#00FF00"
          }
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    },
    yAxis: {
      position: "right",
      nameLocation: "end",
      name: "kWh",
      nameTextStyle: {
        color: "#ffffff",
        align: "right",
        padding: [0, -30, 0, 0]
      },

      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: { lineStyle: { color: "#323436", width: 1 } },
      axisLabel: {
        textStyle: {
          color: "#ffffff"
        }
      }
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          color: getBarColor(props.type)
        },
        data: data,
        markLine: {
          data: [
            {
              label: { position: "start" },
              lineStyle: {
                color: "#FFFF00"
              },
              yAxis: 1000
            },
            {
              label: { position: "start" },
              lineStyle: {
                color: "#FF00FF"
              },
              yAxis: 2000
            },
            {
              label: { position: "start" },
              lineStyle: {
                color: "#00FFFF"
              },
              yAxis: 3000
            }
          ]
        }
      }
    ]
  });

  function dailyEnergyArrayCB(ret) {
    console.log(ret)
    if (ret.return === 100) {
      var dataAxis_ = [];
      var data_ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var avgdata_ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var mindata_ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var maxdata_ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var min = -1;
      var max = -1;
      var total = 0;
      var counter = 0;
      var dayTsArr = [];

      for (var i = 13; i >= 0; i--) {
        var d = new Date();
        d.setDate(d.getDate() - i);
        var curr_date = d.getDate();
        var curr_day = d.getDay();
        var curr_day = days[curr_day];
        var curr_month = d.getMonth() + 1;
        var curr_year = d.getFullYear();
        var dateString =
          curr_month +
          "/" +
          curr_date +
          "/" +
          curr_year +
          ", 00:00:00 AM UTC+7";
        var ts = new Date(dateString).getTime();
        dayTsArr.push(Math.floor(ts/1000));
        dataAxis_.push(curr_date + " " + curr_day);
      }
      console.log(dayTsArr);
      for (var i = 0; i < ret.info.length; i++) {
        var dayData = ret.info[i];
        var index = dayTsArr.indexOf(dayData.ts);
        if (index != -1) {
          data_[index] = dayData.total;
          avgdata_[index] = dayData.avg;
          mindata_[index] = dayData.min;
          maxdata_[index] = dayData.max;

          if (min === -1 || dayData.totalKwh < min) {
            min = dayData.total;
          }
          if (max === -1 || dayData.totalKwh > max) {
            max = dayData.total;
          }

          total = total + dayData.total;
          counter = counter + 1;
        }
      }
      /*
            if ("current" in ret.info && "pass" in ret.info) {
                //get X axis title
                if (ret.info.current.length) {
                    var d = new Date(ret.info.current[0].timeStamp * 1000;
);
                    startStr =
                        d.getDate() +
                        " " +
                        getSemiMonth(0, ret.info.current[0].timeStamp * 1000);

                    d = new Date(
                        ret.info.current[ret.info.current.length - 1]
                            .timeStamp * 1000
                    );
                    endStr =
                        d.getDate() +
                        " " +
                        getSemiMonth(
                            0,
                            ret.info.current[ret.info.current.length - 1]
                                .timeStamp * 1000
                        );
                }

                for (var i = 13; i >= 0; i--) {
                    var d = new Date();
                    d.setDate(d.getDate() - i);
                    var curr_date = d.getDate();
                    var curr_day = d.getDay();
                    var curr_day = days[curr_day];
                    dataAxis_.push(curr_date + " " + curr_day);
                }

                //current data
                for (var i = 0; i < ret.info.current.length; i++) {
                    var d = new Date(ret.info.current[i].timeStamp * 1000);
                    var curr_date = d.getDate();
                    var curr_day = d.getDay();
                    var curr_day = days[curr_day];
                    var index = 0;

                    for (var j = 0; j < dataAxis_.length; j++) {
                        if (curr_date + " " + curr_day === dataAxis_[j]) {
                            index = j;
                            break;
                        }
                    }
                    if (props.type === "kwh")
                        data_[index] = ret.info.current[i].totalKwh;
                    else
                        data_[index] = Math.floor(
                            ret.info.current[i].totalRth / 1000
                        );
                }
            }

            //pass data
            for (var i = 0; i < ret.info.pass.length; i++) {
                d = new Date(ret.info.pass[i].timeStamp * 1000);
                d.setDate(d.getDate() + 15);
                curr_date = d.getDate();
                curr_day = d.getDay();
                curr_day = days[curr_day];
                index = 0;

                for (var j = 0; j < dataAxis_.length; j++) {
                    if (curr_date + " " + curr_day === dataAxis_[j]) {
                        index = j;
                        break;
                    }
                }
                if (props.type === "kwh")
                    passdata_[index] = ret.info.pass[i].totalKwh;
                else
                    passdata_[index] = Math.floor(
                        ret.info.pass[i].totalRth / 1000
                    );
            }
*/
console.log(data_)
      const option_ = cloneDeep(option);
      option_.xAxis.data = dataAxis_;
      //option_.xAxis.name = startStr + "a - b" + endStr;
      option_.series[0].data = data_;
      option_.series[0].markLine.data[0].yAxis = parseFloat(
        (total / counter).toFixed(1)
      );
      option_.series[0].markLine.data[1].yAxis = min;
      option_.series[0].markLine.data[2].yAxis = max;
      //option_.series[1].data = avgdata_;
      //option_.series[2].data = mindata_;
      //option_.series[3].data = maxdata_;
      //option_.series[2].data = passdata_;

      setOption(option_);
    }
  }

  var d = new Date();
  d.setDate(d.getDate());
  var curr_date = d.getDate();
  var curr_month = d.getMonth() + 1;
  var curr_year = d.getFullYear();
  var dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var timeStamp = new Date(dateString).getTime();

  d.setDate(d.getDate() - 14);
  curr_date = d.getDate();
  curr_month = d.getMonth() + 1;
  curr_year = d.getFullYear();
  dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var last14DaysTs = Math.floor(new Date(dateString).getTime()/1000);

  useEffect(() => {
    dailyEnergy(dailyEnergyArrayCB, {
      ts: { $gte: last14DaysTs }
    });
  }, []);

  useInterval(() => {
    dailyEnergy(dailyEnergyArrayCB, {
      ts: { $gte: last14DaysTs }
    });
  }, refreshInterval);

  return (
    <ReactEcharts
      option={option}
      style={{
        top: "0"
      }}
    />
  );
}

export default EnergyGraph;
