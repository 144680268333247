import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function vavGetVavAhuData(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/vav/vavGetVavAhuData", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function vavGetVavData(callback, params, index) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/vav/vavGetVavData", newPostObj);
  const json = await response.json();
  callback(json, index);
}

export async function ahuGetAhuData(callback, params, index) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/vav/ahuGetAhuData", newPostObj);
  const json = await response.json();
  callback(json, index);
}

export async function vavAhuGetVavAhuData(callback, params, index) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/vav/vavAhuGetVavAhuData", newPostObj);
  const json = await response.json();
  callback(json, index);
}
