import React, { useState, useEffect } from "react";
import MarqueeText from "react-marquee-text-component";
import { useInterval } from "../../Utils";
import { topFault } from "../../api/fault";

function TopFault(props) {
    const queryTimeMs = 20000;
    const [faultString, setFaultString] = useState("0/0");
    const [items, setItems] = useState([
        {
            count: " ",
            name: " "
        },
        {
            count: " ",
            name: " "
        },
        {
            count: " ",
            name: " "
        }
    ]);

    const [faultImage, setFaultImage] = useState(
        "row no-gutters itm ui-ml-0"
    );//"row no-gutters itm ui-ml-0 box-glow-red hl"

    function onTopFault(ret) {
        console.log(ret);
        if (ret.return === 100) {
            let faultString_ = ret.info.resolvedFaults + "/" + ret.info.total;
            setFaultString(faultString_);

            if(ret.info.resolvedFaults!==ret.info.total){
                setFaultImage("row no-gutters itm ui-ml-0 box-glow-red hl");
            }
            else{
                setFaultImage("row no-gutters itm ui-ml-0");
            }

            let items_ = [];
            for (var i = 0; i < 3; i++) {
                if (ret.info.data[i]) {
                    items_.push({
                        count: ret.info.data[i].count,
                        name: ret.info.data[i].name
                    });
                } else {
                    items_.push({
                        count: " ",
                        name: " "
                    });
                }
            }
            setItems(items_);
        }
    }

    useInterval(() => {
        topFault(onTopFault, { buildingName: props.buildingName });
    }, queryTimeMs);

    useEffect(() => {
        topFault(onTopFault, { buildingName: props.buildingName });
    }, []);

    return (
        <div className="col align-self-center">
            <div className={faultImage}>
                <div className="col-6 align-self-center">
                    <div
                        className={
                            "widget-title col-12 building-" + props.index
                        }
                    >
                        {props.buildingName}
                    </div>
                    <div className="text-center">{faultString}</div>
                </div>
                <div className="col align-self-center">
                    {items.map((item, index) => (
                        <div key={index} className="box row no-gutters">
                            <div className="col-12 value-gp ">
                                <div className="value-xl">
                                    <span className="count">{item.count}</span>
                                    {item.count > 0 ? (
                                        <img
                                            src="images/icon-alert-s.png"
                                            className="icon"
                                        />
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </div>
                            <div className="col-12 value-label-xl">
                                <div style={{ width: 130 }}>
                                    <MarqueeText
                                        text={
                                            item.name +
                                            "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TopFault;
