import { createContext, useContext } from "react";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function getStoreAuthToken() {
  return localStorage.getItem("tokens");
}
export function setStoreAuthToken(tokenObj) {
  localStorage.setItem("tokens", JSON.stringify(tokenObj));
}
