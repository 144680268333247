import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import TopAlert from "./topAlert";
import CurrentAlertTypes from "./currentAlertTypes";

function Alert(props) {
  const { getAuthTokens } = useAuth();

  const mainDiv = (
    <div className="main-container">
      <div className="subtitle-gp">
        <span className="line" />
        <span>
          <div>
            <img src="/images/subtitle-alert-icon.png" />
          </div>
          <div
            style={{
              position: "relative"
            }}
          >
            <div className="subtitle"> Alert </div>
          </div>
        </span>
        <span className="line" />
      </div>

      <div className="pane1">
        <div className="pane-holder">
          <div className="row no-gutters top-alert">
            <div className="col-12">
              <TopAlert wall={props.wall} />
            </div>
          </div>
          <div className="row no-gutters curr-alert">
            <div className="col-12">
              <div className={"widget row no-gutters"}>
                <div className="col-12">
                  {props.buildings.map(building => (
                    <CurrentAlertTypes
                      key={building.index}
                      index={building.index}
                      buildingName={building.buildingName}
                      wall={props.wall}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (getAuthTokens() == null) {
    const referer = "/404";
    return <Redirect to={referer} />;
  } else return <div className="d-alert has-subtitle single"> {mainDiv} </div>;
}

export default Alert;
