import React, { useState, useEffect } from "react";
import MarqueeText from "react-marquee-text-component";
import { useInterval } from "../../Utils";

function CurrentFaultTypeItems(props) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    let items_ = [];
    for (var i = 0; i < 3; i++) {
      if (props.data[i]) {
        let time = new Date(props.data[i].startFaultTimeStamp * 1000);
        var timeStr =
          String(time.getHours()).padStart(2, "0") +
          ":" +
          String(time.getMinutes()).padStart(2, "0");
        items_.push({ name: props.data[i].name, time: timeStr });
      } else {
        items_.push({ name: " ", time: " " });
      }
    }
    setItems(items_);
  }, [props]);

  return items.map((item, index) => (
    <div
      key={index + props.index + props.buildingIndex + item.name}
      className="row no-gutters"
    >
      <div className="col-7 error-item">
        <div style={{ width: 140, fontSize: 18, color:"white" }}>
          <MarqueeText
            text={item.name + "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
          />
        </div>
      </div>
      <div className="col-2 error-type" />
      <div className="col-3 time" style={{color:"white" }}>{item.time}</div>
    </div>
  ));
}

export default CurrentFaultTypeItems;
