import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function topAlarm(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/alarm/topAlarm",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function currentAlarm(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = { ...postObj, body: JSON.stringify(params) };
  const response = await fetch(
    "https://uobtsk.gpsolutions.sg/api/alarm/currentAlarm",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}
