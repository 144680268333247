import React, { useState, useEffect } from "react";
import { useInterval } from "../../Utils";
import { operationGetWorkStatus } from "../../api/operation";

function WorkStatus(props) {
  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12">
          <div className="widget-header row no-gutters">
            <div className={"widget-title col-12 building-" + props.index}>
              {props.buildingName}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="box">
            <div className="side-row-l row no-gutters">
              <div className="ui-col">
                <div className="row no-gutters">
                  <div className="col-4 value-l">
                    {props.buildingData["WORK STATUS UNRESOLVED CALLS"]}
                  </div>
                  <div className="col-8 value-label-l">Unresolved Calls</div>
                </div>
              </div>
              <div className="ui-col" />
              <div className="ui-col">
                <div className="row no-gutters">
                  <div className="col-4 value-l">
                    {props.buildingData["WORK STATUS OPEN WORK ORDERS"]}
                  </div>
                  <div className="col-8 value-label-l">Open Work Orders</div>
                </div>
              </div>
            </div>
            <div className="side-row-l row no-gutters">
              <div className="row no-gutters" style={{ paddingLeft: 60 }}>
                <div className="col-4 value-l">
                  {props.buildingData["WORK STATUS ROUTINE SHIFTS"]}
                </div>
                <div className="col-8 value-label-l">
                  Routine Shift Activities
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkStatus;
