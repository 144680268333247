import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function slaORQHeaderDailyAverage(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaORQHeaderDailyAverage", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaORQHeaderInterval(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaORQHeaderInt", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaBFCHeaderDailyAverage(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaBFCHeaderDailyAverage", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaBFCHeaderInterval(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaBFCHeaderInt", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaCBDHeaderDailyAverage(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaCBDHeaderDailyAverage", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaCBDHeaderInterval(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaCBDHeaderInt", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaORQco2(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaORQco2", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaBFCco2(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaBFCco2", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function slaCBDco2(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/sla/slaCBDco2", newPostObj);
  const json = await response.json();
  callback(json);
}
