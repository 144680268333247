import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function numberWithCommas(x, f) {
  if (x) {
    if(f){
      var a = parseFloat(x).toFixed(f);
      a = a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return a;
    }
    else
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
}

export function getCurrentFullYear(years) {
  if (years === undefined) years = 0;
  var d = new Date();
  d.setFullYear(d.getFullYear() + years);
  var n = d.getFullYear();
  return n;
}

export function getCurrentSemiMonthName(type, months) {
  var d = new Date();
  d.setMonth(d.getMonth() + months);
  var n = d.getMonth();

  if (type === undefined || type === 0) {
    if (n === 0) return "JAN";
    else if (n === 1) return "FEB";
    else if (n === 2) return "MAR";
    else if (n === 3) return "APR";
    else if (n === 4) return "MAY";
    else if (n === 5) return "JUN";
    else if (n === 6) return "JUL";
    else if (n === 7) return "AUG";
    else if (n === 8) return "SEP";
    else if (n === 9) return "OCT";
    else if (n === 10) return "NOV";
    else if (n === 11) return "DEC";
  } else if (type === 1) {
    if (n === 0) return "JAN";
    else if (n === 1) return "Feb";
    else if (n === 2) return "Mar";
    else if (n === 3) return "Apr";
    else if (n === 4) return "May";
    else if (n === 5) return "Jun";
    else if (n === 6) return "Jul";
    else if (n === 7) return "Aug";
    else if (n === 8) return "Sep";
    else if (n === 9) return "Oct";
    else if (n === 10) return "Nov";
    else if (n === 11) return "Dec";
  }
}

export function getCurrentSemiMonthYearName(type, months) {
  if (months === undefined) months = 0;
  var d = new Date();
  d.setMonth(d.getMonth() + months);
  var n = d.getMonth();
  var y = d.getFullYear();
  if (type === undefined || type === 0) {
    if (n === 0) return "JAN " + y;
    else if (n === 1) return "FEB " + y;
    else if (n === 2) return "MAR " + y;
    else if (n === 3) return "APR " + y;
    else if (n === 4) return "MAY " + y;
    else if (n === 5) return "JUN " + y;
    else if (n === 6) return "JUL " + y;
    else if (n === 7) return "AUG " + y;
    else if (n === 8) return "SEP " + y;
    else if (n === 9) return "OCT " + y;
    else if (n === 10) return "NOV " + y;
    else if (n === 11) return "DEC " + y;
  } else if (type === 1) {
    if (n === 0) return "Jan " + y;
    else if (n === 1) return "Feb " + y;
    else if (n === 2) return "Mar " + y;
    else if (n === 3) return "Apr " + y;
    else if (n === 4) return "May " + y;
    else if (n === 5) return "Jun " + y;
    else if (n === 6) return "Jul " + y;
    else if (n === 7) return "Aug " + y;
    else if (n === 8) return "Sep " + y;
    else if (n === 9) return "Oct " + y;
    else if (n === 10) return "Nov " + y;
    else if (n === 11) return "Dec " + y;
  }
}

export function getSemiMonth(type, epochTime) {
  var d = new Date(epochTime);
  var n = d.getMonth();
  var y = d.getFullYear();
  if (type === undefined || type === 0) {
    if (n === 0) return "JAN";
    else if (n === 1) return "FEB";
    else if (n === 2) return "MAR";
    else if (n === 3) return "APR";
    else if (n === 4) return "MAY";
    else if (n === 5) return "JUN";
    else if (n === 6) return "JUL";
    else if (n === 7) return "AUG";
    else if (n === 8) return "SEP";
    else if (n === 9) return "OCT";
    else if (n === 10) return "NOV";
    else if (n === 11) return "DEC";
  } else if (type === 1) {
    if (n === 0) return "Jan";
    else if (n === 1) return "Feb";
    else if (n === 2) return "Mar";
    else if (n === 3) return "Apr";
    else if (n === 4) return "May";
    else if (n === 5) return "Jun";
    else if (n === 6) return "Jul";
    else if (n === 7) return "Aug";
    else if (n === 8) return "Sep";
    else if (n === 9) return "Oct";
    else if (n === 10) return "Nov";
    else if (n === 11) return "Dec";
  }
}

export function getSemiMonthFromMonth(type, n) {
  if (type === undefined || type === 0) {
    if (n === 0) return "JAN";
    else if (n === 1) return "FEB";
    else if (n === 2) return "MAR";
    else if (n === 3) return "APR";
    else if (n === 4) return "MAY";
    else if (n === 5) return "JUN";
    else if (n === 6) return "JUL";
    else if (n === 7) return "AUG";
    else if (n === 8) return "SEP";
    else if (n === 9) return "OCT";
    else if (n === 10) return "NOV";
    else if (n === 11) return "DEC";
  } else if (type === 1) {
    if (n === 0) return "Jan";
    else if (n === 1) return "Feb";
    else if (n === 2) return "Mar";
    else if (n === 3) return "Apr";
    else if (n === 4) return "May";
    else if (n === 5) return "Jun";
    else if (n === 6) return "Jul";
    else if (n === 7) return "Aug";
    else if (n === 8) return "Sep";
    else if (n === 9) return "Oct";
    else if (n === 10) return "Nov";
    else if (n === 11) return "Dec";
  }
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => {
        console.log("unmount");
        clearInterval(id);
      };
    }
  }, [delay]);
}
