import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { efficiencyHourly } from "../../api/chiller";

function EffChart(props) {
  const refreshInterval = 60000 * 5;
  const staticOptions = {
    xAxis: {
      boundaryGap: false,
      type: "category",
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          color: "#aaa"
        }
      },
      axisLine: {
        lineStyle: {
          color: "rgba(178, 131, 236, 0.75)"
        }
      },
      data: []
    },
    yAxis: {
      name: "ikW/RT",
      type: "value",
      axisLabel: {
        formatter: function(value) {
          return (value + props.target).toFixed(2);
        },
        textStyle: {
          color: "#aaa"
        }
      },
      axisLine: {
        lineStyle: {
          color: "rgba(178, 131, 236, 0.75)"
        }
      },
      splitLine: {
        show: false
      }
    },
    series: [
      {
        symbol: "none",
        smooth: 0.5,
        itemStyle: {
          color: "#560f73"
        },
        name: "Lowest",
        type: "line",
        data: [1, -2, 2, 5, 3, 2, 0],
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(178, 131, 236, 0.75)"
            },
            {
              offset: 1,
              color: "#560f73"
            }
          ])
        }
      }
    ]
  };
  const [val, setVal] = useState(0);
  const [dataX, setDataX] = useState([]);
  const [option, setOption] = useState(null);

  function efficiencyHourlyCB(ret) {
    if (ret.return === 100) {
      console.log(ret.info);

      let d = new Date();
      let currTs = d.getTime();
      let lastTs = currTs - 11 * 60 * 60 * 1000;

      let xAxis = [];
      let data = [];

      for (var i = 0; i < 12; i++) {
        let dd = new Date(lastTs);
        let hours = dd.getHours();

        if (ret.info[i])
          data.push(parseFloat((ret.info[i].value - props.target).toFixed(2)));
        else data.push(0);

        if (hours == 0) {
          hours = 12;
          hours = hours + ":00 AM";
        } else if (hours > 12) {
          hours = hours - 12;
          hours = hours + ":00 PM";
        } else {
          hours = hours + ":00 AM";
        }

        xAxis.push(hours);
        lastTs = lastTs + 60 * 60 * 1000;
      }
      let option_ = cloneDeep(staticOptions);
      option_.xAxis.data = xAxis;
      option_.series[0].data = data;
      //console.log(xAxis);
      setOption(option_);
    }
  }

  useEffect(() => {
    efficiencyHourly(efficiencyHourlyCB, {});
  }, []);

  useInterval(() => {
    efficiencyHourly(efficiencyHourlyCB, {});
  }, refreshInterval);

  if (option !== null) {
    return <ReactEcharts option={option} />;
  } else {
    return <div />;
  }
}

export default EffChart;
