import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import {
  groupBy,
  useInterval,
  numberWithCommas,
  getCurrentSemiMonthYearName
} from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import {} from "../../api/chiller";
import RTChart from "./rt";
import ClChart from "./clChart";
//import CTChart from "./ctChart";
import ChillerEffChart from "./chillerEffChart";
import CHWPEffChart from "./chwpEffChart";
import CDWPEffChart from "./cdwpEffChart";
import CTEffChart from "./ctEffChart";
import ChillerPlantEffChart from "./chillerPlantEffChart";

import CHWPTempChart from "./chwpTempChart";
import CDWPTempChart from "./cdwpTempChart";

import EffVsLoadChart from "./effVsLoadChart";
import ChwpEffVsLoadChart from "./chwpEffVsLoadChart";
import CdwpEffVsLoadChart from "./cdwpEffVsLoadChart";
import CtEffVsLoadChart from "./ctEffVsLoadChart";

import ChillerReport from "./chillerReport";
import HeatBalanceReport from "./heatBalanceReport";

function ReportChiller(props) {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    console.log(props.location.search);
    var start_ = props.location.search.substring(
      props.location.search.indexOf("start") + 6,
      props.location.search.indexOf("start") + 6 + 10
    );
    var end_ = props.location.search.substring(
      props.location.search.indexOf("end") + 4,
      props.location.search.indexOf("end") + 4 + 10
    );

    setStart(start_);
    setEnd(end_);
    console.log(start_);
    console.log(end_);
  }, []);

  if (start !== "" && end !== "") {
    return (
      <div style={{ backgroundColor: "white" }}>
        <div style={{ height: "1580px", width: "1080px" }}>
          <h2 style={{ color: "black", textAlign: "center" }}>
            Chiller Plant Performance Analysis (1 week data)
          </h2>
          <RTChart start={start} end={end} />
          <ClChart start={start} end={end} />
        </div>
        <div style={{ height: "1580px", width: "1080px" }}>
          <CHWPTempChart start={start} end={end} />
          <CDWPTempChart start={start} end={end} />
        </div>
        <div style={{ height: "1580px", width: "1080px" }}>
          <ChillerEffChart start={start} end={end} />
          <CHWPEffChart start={start} end={end} />
          <CDWPEffChart start={start} end={end} />
        </div>
        <div style={{ height: "1580px", width: "1080px" }}>
          <CTEffChart start={start} end={end} />
          <ChillerPlantEffChart start={start} end={end} />
        </div>
        <div style={{ height: "1580px", width: "1080px" }}>
          <EffVsLoadChart start={start} end={end} />
          <ChwpEffVsLoadChart start={start} end={end} />
        </div>
        <div style={{ height: "1580px", width: "1080px" }}>
          <CdwpEffVsLoadChart start={start} end={end} />
          <CtEffVsLoadChart start={start} end={end} />
        </div>
        <div style={{ height: "1580px", width: "1080px", fontColor: "black" }}>
          <ChillerReport start={start} end={end} />
        </div>
        <div style={{ height: "1580px", width: "1080px", fontColor: "black" }}>
          <HeatBalanceReport start={start} end={end} />
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default ReportChiller;
