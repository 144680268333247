import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { reportCoolingLoadAll } from "../../api/chiller";

function ClChart(props) {
  const [val, setVal] = useState(0);
  const [dataX, setDataX] = useState([]);
  const [option, setOption] = useState(null);
  const [pieOption, setPieOption] = useState(null);
  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  function reportCoolingLoadAllCB(ret) {
    if (ret.return === 100) {
      //console.log(ret.info);
      let arr = [];
      let pieArr = [];
      for (var i = 0; i < 13; i++) {
        arr[i] = 0;
        pieArr[i] = { value: 0, name: "" };
      }

      pieArr[0].name = "<100";
      pieArr[1].name = "100 to 199";
      pieArr[2].name = "200 to 299";
      pieArr[3].name = "300 to 399";
      pieArr[4].name = "400 to 499";
      pieArr[5].name = "500 to 599";
      pieArr[6].name = "600 to 699";
      pieArr[7].name = "700 to 799";
      pieArr[8].name = "800 to 899";
      pieArr[9].name = "900 to 999";
      pieArr[10].name = "1000 to 1099";
      pieArr[11].name = "1100 to 1199";
      pieArr[12].name = ">=1200";

      for (var i = 0; i < ret.info.length; i++) {
        if (ret.info[i].value < 100) {
          arr[0] = arr[0] + 1;
          pieArr[0].value = arr[0];
        } else if (ret.info[i].value >= 100 && ret.info[i].value < 200) {
          arr[1] = arr[1] + 1;
          pieArr[1].value = arr[1];
        } else if (ret.info[i].value >= 200 && ret.info[i].value < 300) {
          arr[2] = arr[2] + 1;
          pieArr[2].value = arr[2];
        } else if (ret.info[i].value >= 300 && ret.info[i].value < 400) {
          arr[3] = arr[3] + 1;
          pieArr[3].value = arr[3];
        } else if (ret.info[i].value >= 400 && ret.info[i].value < 500) {
          arr[4] = arr[4] + 1;
          pieArr[4].value = arr[4];
        } else if (ret.info[i].value >= 500 && ret.info[i].value < 600) {
          arr[5] = arr[5] + 1;
          pieArr[5].value = arr[5];
        } else if (ret.info[i].value >= 600 && ret.info[i].value < 700) {
          arr[6] = arr[6] + 1;
          pieArr[6].value = arr[6];
        } else if (ret.info[i].value >= 700 && ret.info[i].value < 800) {
          arr[7] = arr[7] + 1;
          pieArr[7].value = arr[7];
        } else if (ret.info[i].value >= 800 && ret.info[i].value < 900) {
          arr[8] = arr[8] + 1;
          pieArr[8].value = arr[8];
        } else if (ret.info[i].value >= 900 && ret.info[i].value < 1000) {
          arr[9] = arr[9] + 1;
          pieArr[9].value = arr[9];
        } else if (ret.info[i].value >= 1000 && ret.info[i].value < 1100) {
          arr[10] = arr[10] + 1;
          pieArr[10].value = arr[10];
        } else if (ret.info[i].value >= 1100 && ret.info[i].value < 1200) {
          arr[11] = arr[11] + 1;
          pieArr[11].value = arr[11];
        } else if (ret.info[i].value >= 1200) {
          arr[12] = arr[12] + 1;
          pieArr[12].value = arr[12];
        }
      }

      setOption({
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text: "FREQUENCY",
          subtext: "Histogram of Cooling Load Occurrences",
          subtextStyle: {
            color: "#42b3f5"
          },
          left: "center",
          top: "auto",
          textStyle: {
            fontSize: 30
          },
          subtextStyle: {
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: { show: false },
        grid: {
          top: "15%",
          left: "15%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: [
          {
            name: "COOLING LOAD (RT)",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [45, 0, 0, 0],
              color: "#42b3f5"
            },
            type: "category",
            data: [
              "<100",
              "100 to 199",
              "200 to 299",
              "300 to 399",
              "400 to 499",
              "500 to 599",
              "600 to 699",
              "700 to 799",
              "800 to 899",
              "900 to 999",
              "1000 to 1099",
              "1100 to 1199",
              ">=1200"
            ],
            axisLabel: {
              interval: 0,
              rotate: 45
            }
          }
        ],
        yAxis: [
          {
            name: "OCCURANCE",
            type: "value",
            nameLocation: "center",
            nameRotate: 90,
            nameGap: 80,
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [0, 0, 0, 0],
              color: "#42b3f5"
            }
          }
        ],
        series: [
          {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" }
              ])
            },
            showBackground: true,
            name: "",
            type: "bar",
            emphasis: {
              focus: "series"
            },
            data: arr
          }
        ]
      });

      setPieOption({
        legend: {
          left: "left",
          padding: [40, 0, 0, 0]
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: pieArr,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      });
    }
  }

  useEffect(() => {
    reportCoolingLoadAll(reportCoolingLoadAllCB, {
      start: props.start,
      end: props.end
    });
  }, []);

  if (option !== null && pieOption !== null) {
    return (
      <div>
        <div className="row" style={{}}>
          <div
            className="col-8"
            style={{
              paddingTop: 50
            }}
          >
            <ReactEcharts
              option={option}
              style={{
                height: "700px",
                width: "100%"
              }}
            />
          </div>
          <div
            className="col-4"
            style={{
              paddingTop: 50
            }}
          >
            <ReactEcharts
              option={pieOption}
              style={{
                width: "100%",
                height: "700px"
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default ClChart;
