import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { groupBy, useInterval } from "../../Utils";
import { eventGetActivities } from "../../api/event";
import cloneDeep from "lodash/cloneDeep";
//import Activities, { onRet2 } from "./activities";

function Admin(props) {
  const { getAuthTokens } = useAuth();
  const queryTimeMs = 60000;
  const totalBuildingHeight = 610;
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const dataSample = [
    {
      buildingIndex: "orq",
      data: [
        {
          date: "1/1/2020",
          eventType: "fire",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "1/1/2020",
          eventType: "ac",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "1/1/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        }
      ]
    },
    {
      buildingIndex: "bfc",
      data: [
        {
          date: "5/7/2020",
          eventType: "fire",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "BFC"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "BFC"
        }
      ]
    },
    {
      buildingIndex: "cbd",
      data: [
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "CBD"
        },
        {
          date: "5/10/2020",
          eventType: "ac",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "CBD"
        }
      ]
    }
  ];

  const [data, setData] = useState([]);

  function onEventGetActivities(dataReal) {
    var data_ = [];
    var lackOfHeight = 0;

    for (var i = 0; i < props.buildings.length; i++) {
      if (dataReal.length <= 0) continue;
      let obj = dataReal.find(
        o =>
          o.buildingIndex.toLowerCase() ===
          props.buildings[i].index.toLowerCase()
      );
      let groupObj = groupBy(obj.data, v => v.date);
      var buildingData = {
        buildingIndex: props.buildings[i].index,
        buildingName: props.buildings[i].buildingName,
        eventData: []
      };

      var totalHeight = 0;
      for (var [key, value] of groupObj.entries()) {
        totalHeight = totalHeight + 51;
        //swap the month and date
        var dd = key.substring(0, 2);
        var mm = key.substring(3, 5);
        var yy = key.substring(6, 10);

        var date = new Date(mm + "/" + dd + "/" + yy);
        var eventData_ = {};
        eventData_.dateMonthYear = key;
        eventData_.date = ("0" + date.getDate()).slice(-2);
        eventData_.day = days[date.getDay()];
        eventData_.month = date.toLocaleString("default", { month: "short" });
        eventData_.month = eventData_.month.toUpperCase();
        eventData_.year = date.getFullYear();
        eventData_.events = [];
        for (var j = 0; j < value.length; j++) {
          totalHeight = totalHeight + 94;
          var event_ = value[j];
          eventData_.events.push(event_);
        }
        buildingData.totalHeight = totalHeight;
        buildingData.eventData.push(eventData_);
      }

      if (buildingData.totalHeight < totalBuildingHeight) {
        lackOfHeight = lackOfHeight + totalBuildingHeight - totalHeight;
      }
      data_.push(buildingData);
    }

    console.log("----------------" + lackOfHeight);

    var data__ = [];
    for (var i = 0; i < data_.length; i++) {
      var totalHeight = 0;
      var buildingData_ = cloneDeep(data_[i]);
      buildingData_.eventData = [];

      for (var j = 0; j < data_[i].eventData.length; j++) {
        if (totalHeight + 51 + 94 > totalBuildingHeight + lackOfHeight) {
          break;
        }
        totalHeight = totalHeight + 51;

        var eventData_ = cloneDeep(data_[i].eventData[j]);
        eventData_.events = [];

        for (var k = 0; k < data_[i].eventData[j].events.length; k++) {
          if (totalHeight + 94 > totalBuildingHeight + lackOfHeight) {
            break;
          }
          totalHeight = totalHeight + 94;
          eventData_.events.push(data_[i].eventData[j].events[k]);
        }

        buildingData_.eventData.push(eventData_);
      }

      if (totalHeight > totalBuildingHeight) {
        lackOfHeight = lackOfHeight - (totalHeight - totalBuildingHeight);
      }

      data__.push(buildingData_);
    }

    console.log(lackOfHeight);
    console.log(data_);

    setData(data__);
  }

  useEffect(() => {
    eventGetActivities(onEventGetActivities, {
      building: props.buildingName
    });
  }, []);

  useInterval(() => {
    eventGetActivities(onEventGetActivities, {
      building: props.buildingName
    });
  }, queryTimeMs);

  function getTypeImage(type) {
    if (type.toLowerCase() === "fire") {
      return "/images/icon-cat-fire.png";
    } else if (type.toLowerCase() === "lift") {
      return "images/icon-cat-lift.png";
    } else if (type.toLowerCase() === "ac") {
      return "images/icon-cat-ahu.png";
    } else {
      return "";
    }
  }

  function getTime(time) {
    return time.substring(0, time.length - 2);
  }

  function getTimeUnit(time) {
    return time.substring(time.length - 2, time.length);
  }

  const main = (
    <div className="main-container">
      <div className="subtitle-gp">
        <span className="line" />
        <span>
          <div>
            <img src="images/subtitle-event-icon.png" />
            <div style={{ position: "relative" }}>
              <div className="subtitle">Event &amp; Activities</div>
            </div>
          </div>
        </span>
        <span className="line" />
      </div>

      <div className="pane1">
        <div className="pane-holder widget-tran">
          {data.map(building => (
            <div
              key={building.buildingIndex}
              className={
                "row no-gutters building-gp building-" + building.buildingIndex
              }
            >
              {building.eventData.map((d, d_index) => (
                <div key={d_index} className="col-12 date-gp">
                  <div className="calendar-gp  row no-gutters">
                    <div className="col-12">
                      <span className="calendar-date">{d.date}</span>
                      <span className="calendar-day">{d.day}</span>
                    </div>
                    <div className="ui-col calendar-month">
                      {d.month + d.year}
                    </div>

                    <div className="ui-col calendar-line" />
                  </div>
                  {d.events.map((e, e_index) => (
                    <div
                      key={e_index}
                      className="row no-gutters widget-list-row"
                    >
                      <div className="col-2">
                        <img src={getTypeImage(e.eventType)} className="icon" />
                      </div>
                      <div className="ui-col event-gp">
                        <div className="time">
                          {getTime(e.startTime)}
                          <span className="value-unit-m">
                            {getTimeUnit(e.startTime)}
                          </span>
                          {"  "}
                          -{"  "}
                          {getTime(e.endTime)}
                          <span className="value-unit-m">
                            {getTimeUnit(e.endTime)}
                          </span>
                        </div>
                        <div className="event-details">{e.eventMsg}</div>
                      </div>
                      <div className="ui-col building-tag">
                        <div className="tag">{building.buildingName}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return <div class="event has-subtitle"> {main} </div>;
}

export default Admin;

/*
  const dataSample = [
    {
      buildingIndex: "orq",
      data: [
        {
          date: "5/9/2020",
          eventType: "fire",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "5/9/2020",
          eventType: "ac",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "5/8/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "5/8/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "5/8/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "ORQ ST"
        }
      ]
    },
    {
      buildingIndex: "bfc",
      data: [
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "BFC"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "BFC"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "BFC"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "BFC"
        }
      ]
    },
    {
      buildingIndex: "cbd",
      data: [
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "CBD"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "CBD"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "CBD"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "CBD"
        },
        {
          date: "5/7/2020",
          eventType: "lift",
          eventMsg: "Fire Certification Check",
          startTime: "10.30AM",
          endTime: "11.30AM",
          buildingTag: "CBD"
        }
      ]
    }
  ];
*/
