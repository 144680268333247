import React, { useState, useEffect } from "react";
import MarqueeText from "react-marquee-text-component";
import { useInterval } from "../../Utils";
import { topAlarm } from "../../api/alarm";

function TopAlert(props) {
    const queryTimeMs = 5000;

    const [status, setStatus] = useState(false);
    const [statusCount, setStatusCount] = useState(0);

    const [orqTopAlarm, setOrqTopAlarm] = useState({
        string: "0 / 0",
        items: [],
        perc: "0%"
    });
    const [bfcTopAlarm, setBfcTopAlarm] = useState({
        string: "0 / 0",
        items: [],
        perc: "0%"
    });
    const [cbdTopAlarm, setCbdTopAlarm] = useState({
        string: "0 / 0",
        items: [],
        perc: "0%"
    });

    const [orqLastAlarmImage, setOrqLastAlarmImage] = useState(
        <React.Fragment>
            <img src="/images/icon-alarm-b-dim.png" />
        </React.Fragment>
    );
    const [bfcLastAlarmImage, setBfcLastAlarmImage] = useState(
        <React.Fragment>
            <img src="/images/icon-alarm-b-dim.png" />
        </React.Fragment>
    );
    const [cbdLastAlarmImage, setCbdLastAlarmImage] = useState(
        <React.Fragment>
            <img src="/images/icon-alarm-b-dim.png" />
        </React.Fragment>
    );

    function onTopAlarm(ret) {
        if (ret.return === 100) {
            let string_ = ret.info.resolvedAlarms + " / " + ret.info.total;

            let items_ = [];
            for (var i = 0; i < 3; i++) {
                if (ret.info.data[i]) {
                    let perc =
                        (ret.info.data[i].count / ret.info.data[0].count) * 100;
                    perc = perc + "%";
                    items_.push({
                        count: ret.info.data[i].count,
                        name: ret.info.data[i].name,
                        perc: perc
                    });
                } else {
                    items_.push({
                        count: " ",
                        name: " "
                    });
                }
            }

            let data_ = {};
            data_.total = ret.info.total;
            data_.string = string_;
            data_.items = items_;

            let alarmOn = false;
            if (ret.info.resolvedAlarms !== ret.info.total) {
                alarmOn = true;
            }

            if (ret.info.buildingName === "ORQ") {
                if (alarmOn) {
                    setOrqLastAlarmImage(
                        <React.Fragment>
                            <div className="alarm-glow" />
                            <div className="alarm-rotate" />
                            <img src="/images/icon-alarm-b-animated.gif" />
                        </React.Fragment>
                    );
                } else {
                    setOrqLastAlarmImage(
                        <React.Fragment>
                            <img src="/images/icon-alarm-b-dim.png" />
                        </React.Fragment>
                    );
                }
                setOrqTopAlarm(data_);
            } else if (ret.info.buildingName === "BFC") {
                if (alarmOn) {
                    setBfcLastAlarmImage(
                        <React.Fragment>
                            <div className="alarm-glow" />
                            <div className="alarm-rotate" />
                            <img src="/images/icon-alarm-b-animated.gif" />
                        </React.Fragment>
                    );
                } else {
                    setBfcLastAlarmImage(
                        <React.Fragment>
                            <img src="/images/icon-alarm-b-dim.png" />
                        </React.Fragment>
                    );
                }
                setBfcTopAlarm(data_);
            } else if (ret.info.buildingName === "CBD") {
                if (alarmOn) {
                    setCbdLastAlarmImage(
                        <React.Fragment>
                            <div className="alarm-glow" />
                            <div className="alarm-rotate" />
                            <img src="/images/icon-alarm-b-animated.gif" />
                        </React.Fragment>
                    );
                } else {
                    setCbdLastAlarmImage(
                        <React.Fragment>
                            <img src="/images/icon-alarm-b-dim.png" />
                        </React.Fragment>
                    );
                }
                setCbdTopAlarm(data_);
            }
        }
    }

    useInterval(() => {
        topAlarm(onTopAlarm, { buildingName: "ORQ" });
        topAlarm(onTopAlarm, { buildingName: "BFC" });
        topAlarm(onTopAlarm, { buildingName: "CBD" });
    }, queryTimeMs);

    useEffect(() => {
        topAlarm(onTopAlarm, { buildingName: "ORQ" });
        topAlarm(onTopAlarm, { buildingName: "BFC" });
        topAlarm(onTopAlarm, { buildingName: "CBD" });
    }, []);

    function getDisplay() {
        return (
            <div className="row widget no-gutters ">
                <div className="col-1 col-title">
                    <div className="row no-gutters">
                        <div className="widget-subtitle col-12">
                            Top Alerts - Last 30 Days
                        </div>
                    </div>
                </div>

                <div className="col-3 col-ui">
                    <div className="row no-gutters ">
                        <div className="col-4 ">
                            <div className="widget-header row no-gutters">
                                <div className="widget-title col-12 building-orq">
                                    ORQ
                                </div>
                            </div>
                            <div className="row no-gutters icon-holder">
                                <div className="col-12">
                                    {orqLastAlarmImage}
                                </div>
                                <div className="col-12 total">
                                    {orqTopAlarm.string}
                                </div>
                            </div>
                        </div>
                        <div className="col-8 data-display">
                            {orqTopAlarm.items.map((item, index) => (
                                <div key={index} className="row no-gutters">
                                    <div className="col-7">
                                        <div className="h-bar-label">
                                            <div style={{ width: 200 }}>
                                                <MarqueeText
                                                    text={
                                                        item.name +
                                                        "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="h-bar-base">
                                            <div
                                                className="h-bar-fill"
                                                style={{ width: item.perc }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1" />
                                    <div className="col-3 value-xl">
                                        <span className="count">
                                            {item.count}
                                        </span>
                                        <span className="note-m">alerts</span>
                                    </div>
                                    <div className="col-1" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="col-3 col-ui">
                    <div className="row no-gutters ">
                        <div className="col-4 ">
                            <div className="widget-header row no-gutters">
                                <div className="widget-title col-12 building-bfc">
                                    BFC
                                </div>
                            </div>
                            <div className="row no-gutters icon-holder">
                                <div className="col-12">
                                    {bfcLastAlarmImage}
                                </div>
                                <div className="col-12 total">
                                    {bfcTopAlarm.string}
                                </div>
                            </div>
                        </div>
                        <div className="col-8 data-display">
                            {bfcTopAlarm.items.map((item, index) => (
                                <div key={index} className="row no-gutters">
                                    <div className="col-7">
                                        <div className="h-bar-label">
                                            <div className={{ width: 200 }}>
                                                <MarqueeText
                                                    text={
                                                        item.name +
                                                        "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="h-bar-base">
                                            <div
                                                className="h-bar-fill"
                                                style={{ width: item.perc }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1" />
                                    <div className="col-3 value-xl">
                                        <span className="count">
                                            {item.count}
                                        </span>
                                        <span className="note-m">alerts</span>
                                    </div>
                                    <div className="col-1" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="col-3 col-ui">
                    <div className="row no-gutters ">
                        <div className="col-4 ">
                            <div className="widget-header row no-gutters">
                                <div className="widget-title col-12 building-cbd">
                                    CBD
                                </div>
                            </div>
                            <div className="row no-gutters icon-holder">
                                <div className="col-12">
                                    {cbdLastAlarmImage}
                                </div>
                                <div className="col-12 total">
                                    {cbdTopAlarm.string}
                                </div>
                            </div>
                        </div>
                        <div className="col-8 data-display">
                            {cbdTopAlarm.items.map((item, index) => (
                                <div key={index} className="row no-gutters">
                                    <div className="col-7">
                                        <div className="h-bar-label">
                                            <div style={{ width: 200 }}>
                                                <MarqueeText
                                                    text={
                                                        item.name +
                                                        "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="h-bar-base">
                                            <div
                                                className="h-bar-fill"
                                                style={{ width: item.perc }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1" />
                                    <div className="col-3 value-xl">
                                        <span className="count">
                                            {item.count}
                                        </span>
                                        <span className="note-m">alerts</span>
                                    </div>
                                    <div className="col-1" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return getDisplay();
}

export default TopAlert;
