import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { reportChillerPlantEff } from "../../api/chiller";

function ChillerPlantEffChart(props) {
  const [val, setVal] = useState(0);
  const [dataX, setDataX] = useState([]);
  const [option, setOption] = useState(null);

  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  function reportChillerPlantEffCB(ret) {
    if (ret.return === 100) {
      console.log(ret.info);
      let arr = [];

      arr["SUN"] = [];
      arr["MON"] = [];
      arr["TUE"] = [];
      arr["WED"] = [];
      arr["THU"] = [];
      arr["FRI"] = [];
      arr["SAT"] = [];
      for (var i = 0; i < 24; i++) {
        arr["SUN"][i] = 0;
        arr["MON"][i] = 0;
        arr["TUE"][i] = 0;
        arr["WED"][i] = 0;
        arr["THU"][i] = 0;
        arr["FRI"][i] = 0;
        arr["SAT"][i] = 0;
      }

      for (var i = 0; i < ret.info.length; i++) {
        var d = new Date(ret.info[i].ts * 1000);
        var hh = d.getHours();
        let day = weekday[d.getDay()];

        arr[day][hh] = ret.info[i].value;
      }
      console.log(arr);

      setOption({
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text:
            "CHILLER PLANT EFFICIENCY [" +
            props.start +
            " - " +
            props.end +
            "]",
          subtext:
            "Super-imposed plot of daily chiller plant system efficiency kW/RT",
          subtextStyle: {
            color: "#42b3f5"
          },
          left: "center",
          top: "auto",
          textStyle: {
            fontSize: 30
          },
          subtextStyle: {
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: { bottom: 0 },
        grid: {
          top: "15%",
          left: "8%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: [
          {
            name: "TIME (24 HRS FORMAT)",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [15, 0, 0, 0],
              color: "#42b3f5"
            },
            type: "category",
            data: [
              "00",
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23"
            ]
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "kW/RT",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [0, 0, 20, 0],
              color: "#42b3f5"
            }
          }
        ],
        series: [
          {
            color: "#fcba03",
            name: "MON",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series"
            },
            data: arr["MON"]
          },
          {
            color: "#ff5500",
            name: "TUE",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series"
            },
            data: arr["TUE"]
          },
          {
            color: "#ff0088",
            name: "WED",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series"
            },
            data: arr["WED"]
          },
          {
            color: "#8000ff",
            name: "THU",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series"
            },
            data: arr["THU"]
          },
          {
            color: "#0040ff",

            name: "FRI",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series"
            },
            data: arr["FRI"]
          },
          {
            color: "#00c9c3",
            name: "SAT",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series"
            },
            data: arr["SAT"]
          },
          {
            color: "#9c9c9c",
            name: "SUN",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series"
            },
            data: arr["SUN"]
          }
        ]
      });
    }
  }

  useEffect(() => {
    reportChillerPlantEff(reportChillerPlantEffCB, {
      start: props.start,
      end: props.end
    });
  }, []);

  if (option !== null) {
    return (
      <div>
        <div className="row" style={{}}>
          <div
            className="col-12"
            style={{
              paddingTop: 50
            }}
          >
            <ReactEcharts
              option={option}
              style={{
                height: "500px",
                width: "100%"
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default ChillerPlantEffChart;
