import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import MiniChart from "react-mini-chart";
import Trend from 'react-trend';

function SlaMiniGraph(props) {
  function getDataSet() {
    if (props.value){
        var tmp = [];
        //console.log(props.value)
        for(var i=0; i<props.value.length; i++){
            if(props.value[i]===null){
                props.value[i] = 0;
            }
            else{
                tmp.push(props.value[i]);
            }
        }
        return tmp;
    }
    return [0, 0, 0, 0, 0, 0];
  }

  function getChart() {
    if (props.value) {
      var valid = false;
      props.value.forEach((item, i) => {
        if (item != 0) {
          valid = true;
        }
      });
      if (valid) {
        /*return (
          <MiniChart
            strokeColor={props.color}
            activePointColor={props.color}
            activePointRadius={0}
            strokeWidth={1}
            labelFontSize={0}
            width={35}
            height={30}
            dataSet={getDataSet()}
          />
      );*/



        return (
          <Trend gradient={[props.color]} width={35} height={30} data={getDataSet()} />
        );
      }
    }
  }

  return <div>{getChart()}</div>;
}

export default SlaMiniGraph;
