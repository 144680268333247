import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { getStoreAuthToken } from "../../context/auth";

const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  },
  body: {}
};

export async function operationGetEquipmentStatus(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/operation/operationGetEquipmentStatus", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function operationGetEquipmentFault(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/operation/operationGetEquipmentFault", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function operationGetData(callback, params) {
  params.token = getStoreAuthToken();
  const newPostObj = {...postObj, body: JSON.stringify(params)}
  const response = await fetch("https://uobtsk.gpsolutions.sg/api/operation/operationGetData", newPostObj);
  const json = await response.json();
  callback(json);
}
