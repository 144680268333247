import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { reportChwpVsLoad } from "../../api/chiller";

function ChwpEffVsLoadChart(props) {
  const [val, setVal] = useState(0);
  const [dataX, setDataX] = useState([]);
  const [option, setOption] = useState(null);

  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  function reportChwpVsLoadCB(ret) {
    if (ret.return === 100) {
      //console.log(ret.info);
      let arr = [];

      for (var i = 0; i < ret.info.coolingLoad.length; i++) {
        arr[i] = [];
        arr[i][0] = ret.info.coolingLoad[i].value;
        arr[i][1] = ret.info.plantEff[i].value;
      }

      setOption({
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text:
            "CHWP EFFICIENCY VS LOAD[" + props.start + " - " + props.end + "]",
          subtext:
            "Scatter plot of chilled water pump efficiency over cooling load",
          subtextStyle: {
            color: "#42b3f5"
          },
          left: "center",
          top: "auto",
          textStyle: {
            fontSize: 30
          },
          subtextStyle: {
            fontSize: 20
          }
        },
        legend: { bottom: 0 },
        grid: {
          top: "15%",
          left: "8%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: [
          {
            name: "COOLING LOAD (RT)",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [15, 0, 0, 0],
              color: "#42b3f5"
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "kW/RT",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [0, 0, 20, 0],
              color: "#42b3f5"
            }
          }
        ],
        series: [
          {
            color: "#348feb",
            symbolSize: 20,
            type: "scatter",
            data: arr
          }
        ]
      });
    }
  }

  useEffect(() => {
    reportChwpVsLoad(reportChwpVsLoadCB, {
      start: props.start,
      end: props.end
    });
  }, []);

  if (option !== null) {
    return (
      <div>
        <div className="row" style={{}}>
          <div
            className="col-12"
            style={{
              paddingTop: 50
            }}
          >
            <ReactEcharts
              option={option}
              style={{
                height: "500px",
                width: "100%"
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default ChwpEffVsLoadChart;
