import React, { useState, useEffect } from "react";
import { useInterval } from "../../Utils";
import { statisticFault } from "../../api/fault";
import cloneDeep from "lodash.clonedeep";

function ResolvedCase(props) {
  const queryTimeMs = 50000;
  const [items, setItems] = useState([
    { title: "> 7 Days", count: 0, color:"red" },
    { title: "3 - 6 Days", count: 0, color:"yellow" },
    { title: "< 3 Days", count: 0, color:"green" }
  ]);

  function onStatisticFault(ret) {
    if (ret.return === 100) {
      const items_ = cloneDeep(items);
      items_[0].count = ret.info.data[2];
      items_[1].count = ret.info.data[1];
      items_[2].count = ret.info.data[0];

      setItems(items_);
    }
  }

  useInterval(() => {
    statisticFault(onStatisticFault, {
      buildingName: props.buildingName
    });
  }, queryTimeMs);

  useEffect(() => {
    statisticFault(onStatisticFault, {
      buildingName: props.buildingName
    });
  }, []);

  return items.map(item => (
    <div key={item.title} className="row no-gutters side-row-l">
      <div className="col-12">{item.title}</div>
      <div className="col-12 value-l color-danger" style={{color:item.color}}>{item.count}</div>
    </div>
  ));
}

export default ResolvedCase;
