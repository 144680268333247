import React, { useState, useEffect } from "react";
import { useInterval } from "../../Utils";
import { currentFault } from "../../api/fault";
import cloneDeep from "lodash.clonedeep";
import CurrentFaultTypeItems from "./currentFaultTypeItems";

function CurrentFaultTypes(props) {
    const queryTimeMs = 20000;
    const [buildingCount, setBuildingCount] = useState([]);
    const [types, setTypes] = useState([
        {
            name: "HX",
            typeIconPath: "/images/icon-cat-hx.png",
            count: [0, 0, 0],
            status: false
        },
        {
            name: "FAN",
            typeIconPath: "/images/icon-cat-pumps.png",
            count: [0, 0, 0],
            status: false
        },
        {
            name: "FCU",
            typeIconPath: "/images/icon-cat-fcu.png",
            count: [0, 0, 0],
            status: false
        },
        {
            name: "AHU",
            typeIconPath: "/images/icon-cat-ahu.png",
            count: [0, 0, 0],
            status: false
        },
        {
            name: "VAV",
            typeIconPath: "/images/icon-cat-vav.png",
            count: [0, 0, 0],
            status: false
        },
        {
            name: "P & S",
            typeIconPath: "/images/icon-cat-pns.png",
            count: [0, 0, 0],
            status: false
        }
    ]);

    const [orqData, setOrqData] = useState({});
    const [bfcData, setBfcData] = useState({});
    const [cbdData, setCbdData] = useState({});

    const [dataOrqReady, setDataOrqReady] = useState(false);
    const [dataBfcReady, setDataBfcReady] = useState(false);
    const [dataCbdReady, setDataCbdReady] = useState(false);

    function onCurrentFault(ret) {
        if (ret.info.building === "ORQ") setDataOrqReady(true);
        if (ret.info.building === "BFC") setDataBfcReady(true);
        if (ret.info.building === "CBD") setDataCbdReady(true);

        if (ret.return === 100) {
            if (ret.info.building === "ORQ") {
                setOrqData(ret.info);
            }
            if (ret.info.building === "BFC") {
                setBfcData(ret.info);
            }
            if (ret.info.building === "CBD") {
                setCbdData(ret.info);
            }
        }
    }

    useInterval(() => {
        for (var i = 0; i < props.buildings.length; i++) {
            currentFault(onCurrentFault, {
                buildingName: props.buildings[i].buildingName
            });
        }
    }, queryTimeMs);

    useEffect(() => {
        for (var i = 0; i < props.buildings.length; i++) {
            currentFault(onCurrentFault, {
                buildingName: props.buildings[i].buildingName
            });
        }
    }, []);

    function getCount(bName, tName) {
        let bi = 0;
        let total = 0;
        if (bName === "ORQ" && "data" in orqData) {
            for (var i = 0; i < orqData.data.length; i++) {
                if (orqData.data[i].type === tName) {
                    total = orqData.data[i].total;
                    break;
                }
            }
        }
        if (bName === "BFC" && "data" in bfcData) {
            for (var i = 0; i < bfcData.data.length; i++) {
                if (bfcData.data[i].type === tName) {
                    total = bfcData.data[i].total;
                    break;
                }
            }
        }
        if (bName === "CBD" && "data" in cbdData) {
            for (var i = 0; i < cbdData.data.length; i++) {
                if (cbdData.data[i].type === tName) {
                    total = cbdData.data[i].total;
                    break;
                }
            }
        }

        return total;
    }

    function getFaultTypeItems(bName, bIndex, tName) {
        if (bName === "ORQ" && "data" in orqData) {
            let data_ = [];
            for (var i = 0; i < orqData.data.length; i++) {
                if (orqData.data[i].type === tName) {
                    data_ = orqData.data[i].data;
                    break;
                }
            }
            return (
                <CurrentFaultTypeItems
                    index={tName}
                    buildingIndex={bIndex}
                    data={data_}
                />
            );
        }

        if (bName === "BFC" && "data" in bfcData) {
            let data_ = [];
            for (var i = 0; i < bfcData.data.length; i++) {
                if (bfcData.data[i].type === tName) {
                    data_ = bfcData.data[i].data;
                    break;
                }
            }
            return (
                <CurrentFaultTypeItems
                    index={tName}
                    buildingIndex={bIndex}
                    data={data_}
                />
            );
        }

        if (bName === "CBD" && "data" in cbdData) {
            let data_ = [];
            for (var i = 0; i < cbdData.data.length; i++) {
                if (cbdData.data[i].type === tName) {
                    data_ = cbdData.data[i].data;
                    break;
                }
            }
            return (
                <CurrentFaultTypeItems
                    index={tName}
                    buildingIndex={bIndex}
                    data={data_}
                />
            );
        }
    }

    function getFaultClass(building_, typeName) {
        let signal = false;
        let currentEpoch = new Date();
        let iData = {};
        currentEpoch = Math.round(currentEpoch.getTime() / 1000);

        if (building_.buildingName === "ORQ") {
            iData = orqData;
        }
        if (building_.buildingName === "BFC") {
            iData = bfcData;
        }
        if (building_.buildingName === "CBD") {
            iData = cbdData;
        }

        if ("data" in iData) {
            let i = 0;
            for (i = 0; i < iData.data.length; i++) {
                if (
                    iData.data[i].type === typeName &&
                    iData.data[i].total > 0
                ) {
                    let j = 0;
                    for (j = 0; j < iData.data[i].data.length; j++) {
                        if (
                            iData.data[i].data[j].startFaultTimeStamp + 600 >
                            currentEpoch
                        ) {
                            signal = true;
                            break;
                        }
                    }
                }
            }
        }

        if (signal) return "col itm box-glow-red hl";
        else {
            return "col itm";
        }
    }

    function getDisplay() {
        if (
            dataOrqReady === true &&
            dataBfcReady === true &&
            dataCbdReady === true
        ) {
            return (
                <div>
                    {types.map(type => (
                        <div
                            key={type.name}
                            className="row no-gutters widget-list-row"
                        >
                            <div className="col-2 label-gp">
                                <img src={type.typeIconPath} className="icon" />
                                {type.name}
                            </div>
                            {props.buildings.map((building, index) => (
                                <div
                                    key={index}
                                    className={getFaultClass(
                                        building,
                                        type.name
                                    )}
                                >
                                    <div className="row no-gutters">
                                        <div className="col-4 value-xl">
                                            <span className="count">
                                                {getCount(
                                                    building.buildingName,
                                                    type.name
                                                )}
                                            </span>
                                            <img
                                                src="images/icon-alert-s.png"
                                                className="icon"
                                            />
                                        </div>
                                        <div className="col-1 v-line" />
                                        <div className="col 7">
                                            {getFaultTypeItems(
                                                building.buildingName,
                                                building.index,
                                                type.name
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div />;
        }
    }

    return getDisplay();
}

export default CurrentFaultTypes;
