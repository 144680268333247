import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";

function CurrentDistributionAlert(props) {
  const [option, setOption] = useState({
    title: {
      text: props.buildingName,
      left: "center",
      top: "middle",
      //left: getTitleLeft(),
      top: "33%",
      textStyle: { color: getTitleColor(), fontSize: 30 }
    },
    /*legend: {
        orient: "vertical",
        right: "27%",
        top: "10%",
        data: types,
        icon: "circle",
        textStyle: { color: "#888" }
    },*/
    series: [
      {
        type: "pie",
        center: ["50%", "40%"],
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center"
        },
        data: []
      }
    ]
  });

  useEffect(
    () => {
      let types = [];
      var data = [];
      let valid = false;

      for (var i = 0; i < props.types.length; i++) {
        if (props.types[i].count > 0) {
          valid = true;
          break;
        }
      }

      if (valid) {
        for (var i = 0; i < props.types.length; i++) {
          var color;
          if (props.types[i].name === "HX") {
            color = "#d60956";
          } else if (props.types[i].name === "PUMPS") {
            color = "#f99c0b";
          } else if (props.types[i].name === "FCU") {
            color = "#6dd518";
          } else if (props.types[i].name === "AHU") {
            color = "#168603";
          } else if (props.types[i].name === "VAV") {
            color = "#0fc3ac";
          } else if (props.types[i].name === "P & S") {
            color = "#0aaecb";
          }
          //console.log(props.types[i].name);
          types.push(props.types[i].name);
          data.push({
            value: props.types[i].count,
            name: props.types[i].name,
            itemStyle: { color: color, shadowColor: color, shadowBlur: 20 }
          });
        }
      }

      let option_ = cloneDeep(option);
      option_.series[0].data = data;
      setOption(option_);
    },
    [props]
  );

  function getTitleLeft() {
    if (props.index === "orq") {
      return "15%";
    } else if (props.index === "bfc") {
      return "16%";
    } else if (props.index === "cbd") {
      return "16%";
    }
  }

  function getTitleColor() {
    if (props.index === "orq") {
      return "rgb(146,208,80)";
    } else if (props.index === "bfc") {
      return "#d9ec32";
    } else if (props.index === "cbd") {
      return "rgb(51,153,255)";
    }
  }

  return (
    <ReactEcharts
      option={option}
      style={{
        height: "250px"
      }}
      opts={{ renderer: "svg" }}
    />
  );
}

export default CurrentDistributionAlert;
