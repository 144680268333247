import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { reportCHWTemp } from "../../api/chiller";

function CHWPTempChart(props) {
  const [val, setVal] = useState(0);
  const [dataX, setDataX] = useState([]);
  const [option, setOption] = useState(null);

  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  function reportCHWTempCB(ret) {
    if (ret.return === 100) {
      console.log(ret.info);
      let arrS = [];
      let arrR = [];

      arrS["SUN"] = [];
      arrS["MON"] = [];
      arrS["TUE"] = [];
      arrS["WED"] = [];
      arrS["THU"] = [];
      arrS["FRI"] = [];
      arrS["SAT"] = [];

      arrR["SUN"] = [];
      arrR["MON"] = [];
      arrR["TUE"] = [];
      arrR["WED"] = [];
      arrR["THU"] = [];
      arrR["FRI"] = [];
      arrR["SAT"] = [];

      for (var i = 0; i < 24; i++) {
        arrS["SUN"][i] = 0;
        arrS["MON"][i] = 0;
        arrS["TUE"][i] = 0;
        arrS["WED"][i] = 0;
        arrS["THU"][i] = 0;
        arrS["FRI"][i] = 0;
        arrS["SAT"][i] = 0;

        arrR["SUN"][i] = 0;
        arrR["MON"][i] = 0;
        arrR["TUE"][i] = 0;
        arrR["WED"][i] = 0;
        arrR["THU"][i] = 0;
        arrR["FRI"][i] = 0;
        arrR["SAT"][i] = 0;
      }

      for (var i = 0; i < ret.info.chws.length; i++) {
        var d = new Date(ret.info.chws[i].ts * 1000);
        var hh = d.getHours();
        let day = weekday[d.getDay()];

        arrS[day][hh] = ((ret.info.chws[i].value - 32) * 5) / 9;
      }

      for (var i = 0; i < ret.info.chwr.length; i++) {
        var d = new Date(ret.info.chwr[i].ts * 1000);
        var hh = d.getHours();
        let day = weekday[d.getDay()];

        arrR[day][hh] = ((ret.info.chwr[i].value - 32) * 5) / 9 - arrS[day][hh];
      }
      console.log(arrR);

      setOption({
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text: "CHW TEMPERATURE [" + props.start + " - " + props.end + "]",
          subtext:
            "Super-imposed plot of daily chilled water supply/return temperature ℃",
          subtextStyle: {
            color: "#42b3f5"
          },
          left: "center",
          top: "auto",
          textStyle: {
            fontSize: 30
          },
          subtextStyle: {
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: { bottom: 0, data: weekday },
        grid: {
          top: "15%",
          left: "5%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: [
          {
            name: "TIME (24 HRS FORMAT)",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [15, 0, 0, 0],
              color: "#42b3f5"
            },
            type: "category",
            data: [
              "00",
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23"
            ]
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "TEMPERATURE(℃)",
            nameLocation: "middle",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 17,
              padding: [0, 0, 20, 0],
              color: "#42b3f5"
            }
          }
        ],
        series: [
          {
            showBackground: true,
            color: "#fcba03",
            name: "Placeholder",
            type: "bar",
            stack: "MON",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent"
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent"
              }
            },
            data: arrS["MON"],
            barGap: "0%"
          },
          {
            showBackground: true,
            color: "#fcba03",
            name: "MON",
            type: "bar",
            stack: "MON",
            emphasis: {
              focus: "series"
            },
            data: arrR["MON"]
          },
          {
            showBackground: true,
            color: "#ff5500",
            name: "Placeholder",
            type: "bar",
            stack: "TUE",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent"
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent"
              }
            },
            data: arrS["TUE"]
          },
          {
            showBackground: true,
            color: "#ff5500",
            name: "TUE",
            type: "bar",
            stack: "TUE",
            emphasis: {
              focus: "series"
            },
            data: arrR["TUE"]
          },
          {
            showBackground: true,
            color: "#ff0088",
            name: "Placeholder",
            type: "bar",
            stack: "WED",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent"
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent"
              }
            },
            data: arrS["WED"]
          },
          {
            showBackground: true,
            color: "#ff0088",
            name: "WED",
            type: "bar",
            stack: "WED",
            emphasis: {
              focus: "series"
            },
            data: arrR["WED"]
          },
          {
            showBackground: true,
            color: "#8000ff",
            name: "Placeholder",
            type: "bar",
            stack: "THU",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent"
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent"
              }
            },
            data: arrS["THU"]
          },
          {
            showBackground: true,
            color: "#8000ff",
            name: "THU",
            type: "bar",
            stack: "THU",
            emphasis: {
              focus: "series"
            },
            data: arrR["THU"]
          },

          {
            showBackground: true,
            color: "#0040ff",
            name: "Placeholder",
            type: "bar",
            stack: "FRI",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent"
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent"
              }
            },
            data: arrS["FRI"]
          },
          {
            showBackground: true,
            color: "#0040ff",
            name: "FRI",
            type: "bar",
            stack: "FRI",
            emphasis: {
              focus: "series"
            },
            data: arrR["FRI"]
          },

          {
            showBackground: true,
            color: "#00c9c3",
            name: "Placeholder",
            type: "bar",
            stack: "SAT",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent"
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent"
              }
            },
            data: arrS["SAT"]
          },
          {
            showBackground: true,
            color: "#00c9c3",
            name: "SAT",
            type: "bar",
            stack: "SAT",
            emphasis: {
              focus: "series"
            },
            data: arrR["SAT"]
          },

          {
            showBackground: true,
            color: "#9c9c9c",
            name: "Placeholder",
            type: "bar",
            stack: "SUN",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent"
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent"
              }
            },
            data: arrS["SUN"]
          },
          {
            showBackground: true,
            color: "#9c9c9c",
            name: "SUN",
            type: "bar",
            stack: "SUN",
            emphasis: {
              focus: "series"
            },
            data: arrR["SUN"]
          }
          /*



          {
            color: "#00c9c3",
            name: "SAT",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series"
            },
            data: arr["SAT"]
          },
          {
            color: "#9c9c9c",
            name: "SUN",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series"
            },
            data: arr["SUN"]
        }*/
        ]
      });
    }
  }

  useEffect(() => {
    reportCHWTemp(reportCHWTempCB, {
      start: props.start,
      end: props.end
    });
  }, []);

  if (option !== null) {
    return (
      <div>
        <div className="row" style={{}}>
          <div
            className="col-12"
            style={{
              paddingTop: 50
            }}
          >
            <ReactEcharts
              option={option}
              style={{
                height: "500px",
                width: "100%"
              }}
            />
          </div>
        </div>
        <div className="row" style={{ paddingTop: "20px", height: "200px" }}>
          <div
            className="col-6"
            style={{ textAlign: "center", color: "black" }}
          >
            <h4>RETURN AVERAGE</h4>
          </div>
          <div
            className="col-6"
            style={{ textAlign: "center", color: "black" }}
          >
            <h4>SUPPLY AVERAGE</h4>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default CHWPTempChart;
