import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import {
  useInterval,
  getCurrentFullYear,
  getCurrentSemiMonthName,
  numberWithCommas,
  getCurrentSemiMonthYearName
} from "../../Utils";
import { energyDailyKwh, energyDailyRth } from "../../api/energy";

function BuildingInfo(props) {
  const durationMs = 60000;
  const { getAuthTokens } = useAuth();
  const [dateString, setDateString] = useState("");
  const [timeString, setTimeString] = useState("");
  const [amPmString, setAmPmString] = useState("");

  const [buildingCounter, setBuildingCounter] = useState(0);
  const [currentName, setCurrentName] = useState("");

  const [dailyKwh, setDailyKwh] = useState(0);
  const [weeklyKwh, setWeeklyKwh] = useState(0);

  const [dailyRth, setDailyRth] = useState(0);
  const [weeklyRth, setWeeklyRth] = useState(0);

  const [dailyKwhPerM2, setDailyKwhPerM2] = useState("");
  const [dailyRthPerM2, setDailyRthPerM2] = useState("");

  const [weeklyKwhPerM2, setWeeklyKwhPerM2] = useState("");
  const [weeklyRthPerM2, setWeeklyRthPerM2] = useState("");

  const [currentUrl, setCurrentUrl] = useState("");

  function getTodayDateTime() {
    var today = new Date();
    var date =
      today.getDate() +
      " " +
      getCurrentSemiMonthName(0, 0) +
      " " +
      getCurrentFullYear();
    setDateString(date);

    var currentHours = today.getHours();
    currentHours = ("0" + currentHours).slice(-2);

    var time =
      currentHours +
      ":" +
      (today.getMinutes() < 10 ? "0" : "") +
      today.getMinutes();
    setTimeString(time);

    if (today.getHours() > 11) {
      setAmPmString("PM");
    } else {
      setAmPmString("AM");
    }
  }

  function onEnergyDailyKwh(ret) {
    let value = 0;
    if (
      ret.return === 100 &&
      "current" in ret.info &&
      ret.info.current.length !== 0
    ) {
      let dailyKwh_ = ret.info.current[ret.info.current.length - 1].totalKwh;
      setDailyKwh(dailyKwh_);

      if (ret.info.building === "ORQ") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = dailyKwh_ / 148485;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "BFC") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = dailyKwh_ / 188798;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "CBD") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = dailyKwh_ / 151777;
          value = value.toFixed(2);
        }
      }
      setDailyKwhPerM2(value);

      let weeklyKwh_ = 0;
      for (var i = ret.info.current.length - 1; i > 7; i--) {
        if (ret.info.current[i])
          weeklyKwh_ = weeklyKwh_ + ret.info.current[i].totalKwh;
      }
      setWeeklyKwh(weeklyKwh_);
      if (ret.info.building === "ORQ") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = weeklyKwh_ / 148485;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "BFC") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = weeklyKwh_ / 188798;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "CBD") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = weeklyKwh_ / 151777;
          value = value.toFixed(2);
        }
      }
      setWeeklyKwhPerM2(value);
    } else {
      setDailyKwh(0);
      setDailyKwhPerM2(0);
      setWeeklyKwh(0);
      setWeeklyKwhPerM2(0);
    }
  }

  function onEnergyDailyRth(ret) {
    let value = 0;
    if (
      ret.return === 100 &&
      "current" in ret.info &&
      ret.info.current.length !== 0
    ) {
      let dailyRth_ = ret.info.current[ret.info.current.length - 1].totalRth;
      setDailyRth(Math.round(dailyRth_));

      if (ret.info.building === "ORQ") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = dailyRth_ / 148485;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "BFC") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = dailyRth_ / 188798;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "CBD") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = dailyRth_ / 151777;
          value = value.toFixed(2);
        }
      }

      setDailyRthPerM2(value);

      let weeklyRth_ = 0;
      for (var i = ret.info.current.length - 1; i > 7; i--) {
        if (ret.info.current[i])
          weeklyRth_ = weeklyRth_ + ret.info.current[i].totalRth;
      }
      setWeeklyRth(Math.round(weeklyRth_));
      if (ret.info.building === "ORQ") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = weeklyRth_ / 148485;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "BFC") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = weeklyRth_ / 188798;
          value = value.toFixed(2);
        }
      } else if (ret.info.building === "CBD") {
        if ("current" in ret.info && ret.info.current.length !== 0) {
          value = weeklyRth_ / 151777;
          value = value.toFixed(2);
        }
      }
      setWeeklyRthPerM2(value);
    }
    else{
        setDailyRth(0);
        setDailyRthPerM2(0);
        setWeeklyRth(0);
        setWeeklyRthPerM2(0);
    }
  }

  useInterval(() => {
    getTodayDateTime();
  }, 1000);

  useInterval(() => {
    let buildingCounter_ = buildingCounter + 1;
    if (buildingCounter_ > 2) {
      buildingCounter_ = 0;
    }
    getTodayDateTime();
    setCurrentName(props.buildings[buildingCounter_].buildingName);
    setBuildingCounter(buildingCounter_);
    energyDailyKwh(onEnergyDailyKwh, {
      buildingName: props.buildings[buildingCounter_].buildingName
    });
    energyDailyRth(onEnergyDailyRth, {
      buildingName: props.buildings[buildingCounter_].buildingName
    });

    if (buildingCounter_ === 0) {
      setCurrentUrl("url(images/building-ORQ.jpg)");
    } else if (buildingCounter_ === 1) {
      setCurrentUrl("url(images/building-TOWER2.jpg)");
    } else if (buildingCounter_ === 2) {
      setCurrentUrl("url(images/building-TOWER3.jpg)");
    }
  }, durationMs);

  useEffect(() => {
    setCurrentUrl("url(images/building-ORQ.jpg)");
    setCurrentName(props.buildings[buildingCounter].buildingName);
    getTodayDateTime();

    energyDailyKwh(onEnergyDailyKwh, {
      buildingName: props.buildings[buildingCounter].buildingName
    });
    energyDailyRth(onEnergyDailyRth, {
      buildingName: props.buildings[buildingCounter].buildingName
    });
  }, []);

  function getName() {
    if (currentName === "ORQ") {
      return (
        <div className="building-name building-orq">
          <div>One&nbsp;</div>
          <div>
            <span> Raffles Quay</span>
          </div>
        </div>
      );
    } else if (currentName === "BFC") {
      return (
        <div className="building-name building-bfc">
          <div>BFC</div>
        </div>
      );
    } else if (currentName === "CBD") {
      return (
        <div className="building-name building-cbd">
          <div>CBD</div>
        </div>
      );
    }
  }

  return (
    <div className="building single">
      <div className="main-holder" style={{ backgroundImage: currentUrl }}>
        <div className="row no-gutters">
          <div className="col-auto">
            <div className="pane1">
              {getName()}
              <div className="row no-gutters">
                <div className="col-3 text-right icon">
                  <img src="images/icon-water.png" />
                </div>
                <div className="col-8">
                  <div className="today-usage-kwh">
                    <div className="value-label-xxl">Today's Cooling</div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {numberWithCommas(dailyRth)}
                      </span>
                      <span className="value-unit-l">RTh</span>
                    </div>
                  </div>

                  <div className="today-usage-kwh" style={{ marginTop: 70 }}>
                    <div className="value-label-xxl">Weekly Cooling</div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {numberWithCommas(weeklyRth)}
                      </span>
                      <span className="value-unit-l">RTh</span>
                    </div>
                  </div>

                  <div className="today-usage-kwh" style={{ marginTop: 70 }}>
                    <div className="value-label-xxl">Today's Cooling/GFA</div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {dailyRthPerM2}
                      </span>
                      <span className="value-unit-l">
                        RTh/m<sup>2</sup>
                      </span>
                    </div>
                  </div>

                  <div className="today-usage-kwh" style={{ marginTop: 70 }}>
                    <div className="value-label-xxl">Weekly Cooling/GFA</div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {weeklyRthPerM2}
                      </span>
                      <span className="value-unit-l">
                        RTh/m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col" />
          <div className="col-auto">
            <div className="pane2">
              <div className="text-center time">
                <div>
                  <span>{timeString}</span>
                  <span className="time-unit">{amPmString}</span>
                </div>
                <div className="date">{dateString}</div>
              </div>
              <div className="row no-gutters">
                <div className="col-3 text-right icon">
                  <img src="images/icon-energy.png" />
                </div>
                <div className="col-8">
                  <div className="today-usage-kwh">
                    <div className="value-label-xxl">Today's Consumption</div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {numberWithCommas(dailyKwh)}
                      </span>
                      <span className="value-unit-l">kWh</span>
                    </div>
                  </div>

                  <div className="today-usage-kwh" style={{ marginTop: 70 }}>
                    <div className="value-label-xxl">Weekly Consumption</div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {numberWithCommas(Math.round(weeklyKwh))}
                      </span>
                      <span className="value-unit-l">kWh</span>
                    </div>
                  </div>

                  <div className="today-usage-kwh" style={{ marginTop: 70 }}>
                    <div className="value-label-xxl">
                      Today's Consumption/GFA
                    </div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {dailyKwhPerM2}
                      </span>
                      <span className="value-unit-l">
                        kWh/m<sup>2</sup>
                      </span>
                    </div>
                  </div>

                  <div className="today-usage-kwh" style={{ marginTop: 70 }}>
                    <div className="value-label-xxl">
                      Weekly Consumption/GFA
                    </div>
                    <div>
                      <span className="value-xxl glow-orange">
                        {weeklyKwhPerM2}
                      </span>
                      <span className="value-unit-l">
                        kWh/m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildingInfo;
