import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import Operation from "../Operation";
import Fault from "../Fault";
import Event from "../Event";
import Alert from "../Alert";
import BuildingInfo from "../BuildingInfo";

function RightWall(props) {
  const { getAuthTokens } = useAuth();
  const mainDiv = (
    <div className="wall-right">
      <BuildingInfo {...props} />
      <div className="d-alert main-holder">
        <Alert {...props} wall="right" />
      </div>
      <div className="operation has-subtitle main-holder">
        <Operation {...props} />
      </div>
      <div className="fault has-subtitle main-holder">
        <Fault {...props} />
      </div>
      <div className="event has-subtitle main-holder">
        <Event {...props} />
      </div>
    </div>
  );

  if (getAuthTokens() == null) {
    const referer = "/404";
    return <Redirect to={referer} />;
  } else return <div>{mainDiv}</div>;
}

export default RightWall;
