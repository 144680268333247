import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import WorkStatus from "./workStatus";
import EquipmentStatus from "./equipmentStatus";
import MaintenanceSchedule from "./maintenanceSchedule";
import { operationGetData } from "../../api/operation";

function Operation(props) {
  const refreshInterval = 60000;
  const { getAuthTokens } = useAuth();
  const types = [
    {
      queryName: "MAINTAINANCE SCHEDULE HX",
      name: "HX",
      iconPath: "/images/icon-cat-hx.png"
    },
    {
      queryName: "MAINTAINANCE SCHEDULE PUMPS",
      name: "PUMPS",
      iconPath: "/images/icon-cat-pumps.png"
    },
    {
      queryName: "MAINTAINANCE SCHEDULE FCU",
      name: "FCU",
      iconPath: "/images/icon-cat-fcu.png"
    },
    {
      queryName: "MAINTAINANCE SCHEDULE AHU",
      name: "AHU",
      iconPath: "/images/icon-cat-ahu.png"
    },
    {
      queryName: "MAINTAINANCE SCHEDULE FAN",
      name: "FAN",
      iconPath: "/images/icon-cat-vav.png"
    },
    {
      queryName: "MAINTAINANCE SCHEDULE P&S",
      name: "P & S",
      iconPath: "/images/icon-cat-pns.png"
    },
    {
      queryName: "MAINTAINANCE SCHEDULE CHILLER",
      name: "CHILLER",
      iconPath: "/images/icon-cat-elec.png"
    }
  ];
  const [buildingData, setBuildingData] = useState([]);
  const [ready, setReady] = useState(false);
  function onOperationGetData(ret) {
    if (ret.return === 100) {
      console.log(ret.info);
      setBuildingData(ret.info);
      setReady(true);
    }
  }

  useEffect(() => {
    operationGetData(onOperationGetData, {});
  }, []);

  useInterval(() => {
    operationGetData(onOperationGetData, {});
  }, refreshInterval);

  const mainDiv = (
    <div className="main-container">
      <div className="subtitle-gp">
        <span className="line" />
        <span>
          <div>
            <img src="images/subtitle-operation-icon.png" />
          </div>
          <div style={{ position: "relative" }}>
            <div className="subtitle">Operation</div>
          </div>
        </span>
        <span className="line" />
      </div>
      <div className="pane1">
        <div className="pane-holder">
          <div className="widget work-status row no-gutters">
            <div className="col-12">
              <div className="widget-header row no-gutters">
                <div className="widget-subtitle col-12">Work Status</div>
              </div>
            </div>
            {props.buildings.map(building => (
              <WorkStatus
                key={building.index}
                index={building.index}
                buildingName={building.buildingName}
                buildingData={buildingData[building.buildingName]}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="widget-gap-w" />
      <div className="pane2">
        <div className="pane-holder">
          <div className="row no-gutters eq-status">
            <div className="col-12">
              <div className="widget row no-gutters">
                <div className="col-12">
                  <div className="widget-header row no-gutters">
                    <div className="widget-subtitle col-12">
                      Equipment Status
                    </div>
                  </div>
                </div>
                {props.buildings.map(building => (
                  <EquipmentStatus
                    key={building.index}
                    index={building.index}
                    buildingName={building.buildingName}
                    buildingData={buildingData[building.buildingName]}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="row no-gutters schedule">
            <div className="col-12">
              <div className="widget-tran row no-gutters">
                <div className="col-12">
                  <div className="widget-header row no-gutters">
                    <div className="widget-subtitle col-12">
                      Completion Of Maintenance Schedule
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row no-gutters widget-table-row">
                    <div className="col-3 side-header">
                      <div className="row no-gutters side-header-gp">
                        <div className="col-4">
                          <img
                            src="images/icon-cat-calendar.png"
                            className="icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3 header">
                      <div className="row no-gutters header-gp">
                        <div className="col-12 building-orq">ORQ</div>
                      </div>
                    </div>
                    <div className="col-3 header">
                      <div className="row no-gutters header-gp">
                        <div className="col-12 building-bfc">BFC</div>
                      </div>
                    </div>
                    <div className="col-3 header">
                      <div className="row no-gutters header-gp">
                        <div className="col-12 building-cbd">CBD</div>
                      </div>
                    </div>
                  </div>
                  {types.map(type => (
                    <MaintenanceSchedule
                      key={type.name}
                      queryName={type.queryName}
                      type={type}
                      buildings={props.buildings}
                      buildingData={buildingData}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (getAuthTokens() == null) {
    const referer = "/404";
    return <Redirect to={referer} />;
  } else {
    if (ready) {
      return <div className="operation has-subtitle"> {mainDiv} </div>;
    } else {
      return <div className="operation has-subtitle" />;
    }
  }
}

export default Operation;
