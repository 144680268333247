import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import echarts from "echarts";
import { energyDailyKwh } from "../../api/energy";
import { energyDailyRth } from "../../api/energy";
import { getSemiMonth } from "../../Utils";
import { dailySegmentEnergy } from "../../api/energy";

const days = ["S", "M", "T", "W", "T", "F", "S"];
const refreshInterval = 60000 * 5;

function EnergyGraphSegment(props) {
    var echarts = require("echarts");
    const dataAxis = ["4 M", "5 T", "6 W", "7 T", "8 F", "9 S", "10 S"];
    const data = [0, 0, 0, 0, 0, 0, 0];
    const yMax = 500;

    function getShadowColor(type) {
        if (type === "kwh") {
            return "rgba(18, 141, 102, 0.53)";
        } else {
            return "rgba(132, 7, 57, 0.53)";
        }
    }

    function getBarColor(type) {
        if (type === "kwh") {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#92d70d" },
                { offset: 1, color: "#347b09" }
            ]);
        } else {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#e90b5f" },
                { offset: 1, color: "#830839" }
            ]);
        }
    }

    function getYAxisTitle(type) {
        if (type === "kwh") {
            return "kWh";
        } else {
            return "RTh('000)";
        }
    }

    function getTitle(type, buildingName) {
        if (buildingName === "ORQ") {
            if (type === "kwh") {
                return "DAILY ELECTRICAL CONSUMPTION";
            } else {
                return "DAILY RTh CONSUMPTION";
            }
        } else {
            return "";
        }
    }

    const [option, setOption] = useState({
        //color: [getLineColor(props.type)],
        /*title: {
      text: getTitle(props.type, props.buildingName),
      left: "center",
      textStyle: {
        color: "#999",
        fontSize: 15
      }
  },*/
        grid: {
            left: "1%",
            right: "10%",
            bottom: "0%",
            containLabel: true
        },
        legend: {
            show: false,
            top: "92%",
            left: "70%",
            icon: "roundRect",
            textStyle: {
                color: "#999",
                fontSize: 9
            },
            data: [
                {
                    name: "15 DAYS REFERENCE"
                }
            ]
        },
        xAxis: {
            nameLocation: "middle",
            nameGap: 50,
            name: "",
            nameTextStyle: {
                color: "#b283ec",
                align: "center",
                position: "bottom",
                fontSize: 13
            },
            data: dataAxis,
            axisLabel: {
                interval: 0,
                formatter: function(value) {
                    var res = value.split(" ");
                    if (res[1] === "S")
                        return (
                            "{date|" +
                            res[0] +
                            "}\n" +
                            "{weekend|" +
                            res[1] +
                            "}"
                        );
                    else
                        return (
                            "{date|" +
                            res[0] +
                            "}\n" +
                            "{weekday|" +
                            res[1] +
                            "}"
                        );
                },
                rich: {
                    date: {
                        align: "center",
                        color: "#ffffff"
                    },
                    weekday: {
                        lineHeight: 15,
                        align: "center",
                        color: "#999999"
                    },
                    weekend: {
                        lineHeight: 15,
                        align: "center",
                        color: "#00FF00"
                    }
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            z: 10
        },
        yAxis: {
            position: "left",
            nameLocation: "end",
            name: "kWh",
            nameTextStyle: {
                color: "#ffffff",
                align: "right",
                padding: [0, -10, 0, 0]
            },

            axisLine: {
                show: true
            },
            axisTick: {
                show: false
            },
            splitLine: { lineStyle: { color: "#323436", width: 1 } },
            axisLabel: {
                textStyle: {
                    color: "#ffffff"
                }
            }
        },
        series: [
            {
                type: "line",
                name: "CHILLER",
                lineStyle: {
                    color: "#3377FF",
                    width: 2,
                    type: "solid"
                },
                symbol: "none",
                smooth: true,
                data: data
            },
            {
                type: "line",
                name: "MV",
                lineStyle: {
                    color: "#CE33FF",
                    width: 2,
                    type: "solid"
                },
                symbol: "none",
                smooth: true,
                data: data
            },
            {
                type: "line",
                name: "LIFT",
                lineStyle: {
                    color: "#FFFF33",
                    width: 2,
                    type: "solid"
                },
                symbol: "none",
                smooth: true,
                data: data
            },
            {
                type: "line",
                name: "P&S",
                lineStyle: {
                    color: "#FF33DD",
                    width: 2,
                    type: "solid"
                },
                symbol: "none",
                smooth: true,
                data: data
            },
            {
                type: "line",
                name: "LIGHTING",
                lineStyle: {
                    color: "#3CFF33",
                    width: 2,
                    type: "solid"
                },
                symbol: "none",
                smooth: true,
                data: data
            },
            {
                type: "line",
                name: "TENANT",
                lineStyle: {
                    color: "#FF3358",
                    width: 2,
                    type: "solid"
                },
                symbol: "none",
                smooth: true,
                data: data
            }
        ]
    });

    function dailyEnergyArrayCB(ret) {
        if (ret.return === 100) {
            var dataAxis_ = [];
            var chillerData = [0, 0, 0, 0, 0, 0, 0];
            var MVData = [0, 0, 0, 0, 0, 0, 0];
            var liftData = [0, 0, 0, 0, 0, 0, 0];
            var PSData = [0, 0, 0, 0, 0, 0, 0];
            var lightingData = [0, 0, 0, 0, 0, 0, 0];
            var tenantData = [0, 0, 0, 0, 0, 0, 0];
            var dayTsArr = [];

            for (var i = 6; i >= 0; i--) {
                var d = new Date();
                d.setDate(d.getDate() - i);
                var curr_date = d.getDate();
                var curr_day = d.getDay();
                var curr_day = days[curr_day];
                var curr_month = d.getMonth() + 1;
                var curr_year = d.getFullYear();
                var dateString =
                    curr_month +
                    "/" +
                    curr_date +
                    "/" +
                    curr_year +
                    ", 00:00:00 AM UTC+7";
                var ts = Math.floor(new Date(dateString).getTime() / 1000);
                dayTsArr.push(ts);
                dataAxis_.push(curr_date + " " + curr_day);
            }

            for (var i = 0; i < ret.info.length; i++) {
                var dayData = ret.info[i];
                var index = dayTsArr.indexOf(dayData.ts);
                if (index != -1) {
                    for (var k = 0; k < dayData.data.length; k++) {
                        if (dayData.data[k].type === "chiller") {
                            chillerData[index] = dayData.data[k].total;
                        } else if (dayData.data[k].type === "MV") {
                            MVData[index] = dayData.data[k].total;
                        } else if (dayData.data[k].type === "lift") {
                            liftData[index] = dayData.data[k].total;
                        } else if (dayData.data[k].type === "PS") {
                            PSData[index] = dayData.data[k].total;
                        } else if (dayData.data[k].type === "lighting") {
                            lightingData[index] = dayData.data[k].total;
                        } else if (dayData.data[k].type === "tenant") {
                            tenantData[index] = dayData.data[k].total;
                        }
                    }
                }
            }

            const option_ = cloneDeep(option);
            option_.xAxis.data = dataAxis_;
            //option_.xAxis.name = startStr + "a - b" + endStr;
            option_.series[0].data = chillerData;
            option_.series[1].data = MVData;
            option_.series[2].data = liftData;
            option_.series[3].data = PSData;
            option_.series[4].data = lightingData;
            option_.series[5].data = tenantData;
            console.log(option_)
            setOption(option_);
        }
    }

    var d = new Date();
    d.setDate(d.getDate());
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1;
    var curr_year = d.getFullYear();
    var dateString =
        curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
    var timeStamp = Math.floor(new Date(dateString).getTime() / 1000);

    d.setDate(d.getDate() - 7);
    curr_date = d.getDate();
    curr_month = d.getMonth() + 1;
    curr_year = d.getFullYear();
    dateString =
        curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
    var last7DaysTs = Math.floor(new Date(dateString).getTime() / 1000);

    useEffect(() => {
        dailySegmentEnergy(dailyEnergyArrayCB, {
            ts: { $gte: last7DaysTs }
        });
    }, []);

    useInterval(() => {
        dailySegmentEnergy(dailyEnergyArrayCB, {
            ts: { $gte: last7DaysTs }
        });
    }, refreshInterval);

    return (
        <ReactEcharts
            option={option}
            style={{
                top: "0"
            }}
        />
    );
}

export default EnergyGraphSegment;
