import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import { useInterval } from "../../Utils";
import { faultDaily, faultGetBuildingDailyTotalFaults } from "../../api/fault";
import cloneDeep from "lodash.clonedeep";

function FaultTrend(props) {
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];

  const queryTimeMs = 10000;

  const [option, setOption] = useState({
    title: {
      show: true,
      text: "",
      left: "center",
      top: "bottom",
      textStyle: { color: "#888", fontSize: 14 }
    },
    xAxis: {
      scale: true,
      axisLine: { show: false },
      splitLine: { show: false },
      axisTick: { show: false },
      axisLabel: { color: "#888" },
      type: "category",
      data: []
    },
    yAxis: {
      axisLine: { show: false },
      scale: true,
      splitLine: { lineStyle: { color: "#222", width: 2 } },
      axisTick: { show: false },
      axisLabel: { color: "#888" }
    },
    grid: {
      top: 18
    },
    series: [
      {
        type: "line",
        lineStyle: { type: "dotted", opacity: 0 },
        symbolSize: 6,
        showAllSymbol: true,
        markLine: {
          data: [],
          symbol: "none",
          lineStyle: { type: "solid", color: "#888" }
        },
        markPoint: {
          data: [
            {
              symbol: "circle",
              symbolSize: 12,
              name: "coordinate",
              coord: []
            }
          ]
        },
        data: []
      }
    ]
  });

  function callFaultTest() {
    faultGetBuildingDailyTotalFaults(
      function(ret) {
        const option_ = cloneDeep(option); // immutable

        option_.series[0].data = [];
        option_.xAxis.data = [];

        for (var i = 0; i < ret.data.length; i++) {
          option_.xAxis.data.push(ret.data[i][0]);
          option_.series[0].data.push(ret.data[i][1]);
        }

        option_.series[0].markPoint.data[0].coord = [
          option_.xAxis.data[option_.xAxis.data.length - 1],
          option_.series[0].data[option_.series[0].data.length - 1]
        ];

        option_.series[0].markLine.data = [
          { name: "", xAxis: option_.xAxis.data[option_.xAxis.data.length - 1] }
        ];

        var startDate = new Date(ret.dates[0]);
        var endDate = new Date(ret.dates[ret.dates.length - 1]);
        option_.title.text =
          startDate.getDate() +
          " " +
          monthNames[startDate.getMonth()] +
          " - " +
          endDate.getDate() +
          " " +
          monthNames[endDate.getMonth()];

        setOption(option_);
      },
      { buildingIndex: props.index }
    );
  }

  function onFaultDaily(ret) {
    if (ret.return === 100) {
      const option_ = cloneDeep(option); // immutable
      option_.series[0].data = [];
      option_.xAxis.data = [];

      if (ret.info && ret.info.length) {
        for (var i = 0; i < ret.info.length; i++) {
          let date_ = new Date(ret.info[i].timeStamp * 1000);
          date_ = date_.getDate();
          option_.xAxis.data.push(date_.toString());
          option_.series[0].data.push(ret.info[i].faults);
        }

        option_.series[0].markPoint.data[0].coord = [
          option_.xAxis.data[option_.xAxis.data.length - 1],
          option_.series[0].data[option_.series[0].data.length - 1]
        ];

        option_.series[0].markLine.data = [
          { name: "", xAxis: option_.xAxis.data[option_.xAxis.data.length - 1] }
        ];

        var startDate = new Date(ret.info[0].timeStamp * 1000);
        var endDate = new Date(ret.info[ret.info.length - 1].timeStamp * 1000);
        option_.title.text =
          startDate.getDate() +
          " " +
          monthNames[startDate.getMonth()] +
          " - " +
          endDate.getDate() +
          " " +
          monthNames[endDate.getMonth()];
      } else {
        for (var i = 0; i < 31; i++) {
          let date_ = new Date();
          date_.setDate(date_.getDate() - (30 - i));
          date_ = date_.getDate();
          option_.xAxis.data.push(date_.toString());
          option_.series[0].data.push(0);
        }

        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        var endDate = new Date();
        option_.title.text =
          startDate.getDate() +
          " " +
          monthNames[startDate.getMonth()] +
          " - " +
          endDate.getDate() +
          " " +
          monthNames[endDate.getMonth()];
      }
      setOption(option_);
    }
  }

  useEffect(() => {
    //callFaultTest();
    faultDaily(onFaultDaily, {
      buildingName: props.buildingName
    });
  }, []);

  useInterval(() => {
    faultDaily(onFaultDaily, {
      buildingName: props.buildingName
    });
  }, queryTimeMs);

  return (
    <ReactEcharts
      option={option}
      style={{
        height: "240px",
        top: "0"
      }}
      opts={{ renderer: "svg" }}
    />
  );
}

export default FaultTrend;
